import { useEffect, useState } from 'react';
import LyzImage from '@/components/LyzImage/LyzImage';
import '../css/AboutCard.css'
import rightIcon from '@/assets/home/rightIcon.png'
import { Carousel } from 'antd';
import imageListData from '@/assets/image';
import { getCarouselList } from '@/api/home';
import { useNavigate } from 'react-router-dom';
import { CarouselItem } from '@/typeScripeMode';
const AboutCard = () => {
	const [carouselList,setCarouselList] = useState<CarouselItem>()
	const list = [
		{
			icon: imageListData.xhjj,
			title: '协会简介',
			toPath: '/associationOfHome',
			active: 0
		}, {
			icon: imageListData.xhzc,
			title: '协会章程',
			toPath: '/associationOfHome',
			active: 1
		}, {
			icon: imageListData.zzjg,
			title: '组织架构',
			toPath: '/associationOfHome',
			active: 2
		}, {
			icon: imageListData.gltd,
			title: '管理团队',
			toPath: '/associationOfHome',
			active: 3
		}, {
			icon: imageListData.gzjg,
			title: '工作机构',
			toPath: '/associationOfHome',
			active: 4
		}, {
			icon: imageListData.hyzc,
			title: '会员之窗',
			toPath: '/associationOfHome',
			active: 5
		},
		{
			icon: imageListData.lxwm,
			title: '联系我们',
			toPath:'/contactUs',
			active: 2
		}
	]
	let navigate = useNavigate();
	const goDetails = (e: any) => {
		if (e.toPath){
			navigate(e.toPath, { state: { active: e.active } })
			window.scrollTo(0, 0);
		} 
	}
	const getCarouselLists = (data: { advType?: string | undefined; } | undefined)=>{
		getCarouselList(data).then(res=>{
			setCarouselList(res.data.data)
		})
	}
	const toLogin = (item:any) => {
		if(item.advUrl) {
			window.open(item.advUrl)
		}
	}
	useEffect(() => {
		getCarouselLists({
			advType:'4'
		})
	}, [])
	return (
		<>
			<div className='flex page_body'>
				<ul style={{
					marginRight: '89px',
					width: '315px',
					marginBottom:'0'
				}}>
					{
						list.map((value, index) => {
							return <li key={index} className={`flexr-jsb flex-aic boxs_bb pointer aboutLi`} onClick={() => { goDetails(value) }}>
								<div className='flex-aic' style={{ display: 'flex' }}>
									<img src={value.icon} alt="" width={40} height={40} style={{ background: '#ccc' }} />
									<div className='font_21 color_333' style={{
										marginLeft: '14px',
									}}>{value.title}</div>
								</div>
								<img style={{
									width: '31px',
									height: '20px',
									backgroundColor: '#979797'
								}} src={rightIcon} alt="" />
							</li>
						})
					}
				</ul>
				<div style={{
					width: '856px',
					height: '530px'
				}}>
				<Carousel autoplay>
					{
						carouselList && carouselList.length > 0 && carouselList.map((item) => {
							return <div>
								<LyzImage style={{cursor:'pointer'}} height='530px' onClick={()=>{toLogin(item)}} url={item.fileList[0].fileUrl} />
							</div>
						})
					}
				</Carousel>
				</div>
			</div>
		</>);
}
export default AboutCard