import { ReactElement, useEffect, useState } from 'react';
import PageItem from './PageItem';
import LyzPagination from '../LyzPagination/LyzPagination';
import PageInfo from './PageInfo';
const PageList = (props: { request: any, pageSize: number }): ReactElement => {
	const [list, setList] = useState<Array<any>>([])
	const [total, setTotal] = useState<number>(0)
	const [showInfo, setShowInfo] = useState<boolean>(false)
	useEffect(() => {
		props.request({ pageSize: props.pageSize, current: 1 }).then((res: any) => {
			if (res.success) {
				setList(res.data)
				setTotal(res.total)
			} else {
				setList([])
				setTotal(0)
			}
		})
	}, [props, props.request])
	const pageChange = (page: any) => {
		props.request(page).then((res: any) => {
			if (res.success) {
				setList(res.data)
				setTotal(res.total)
			} else {
				setList([])
				setTotal(0)
			}
		})
	}
	const toInfo = (data: any) => {
		setShowInfo(true)
	}
	return (
		<>
			<div style={{
				display: showInfo ? 'none' : 'block'
			}}>
				<div style={{
					paddingBottom: '70px'
				}}>
					{
						list.map((value, index) => {
							return <PageItem onClick={toInfo} data={value} title='江苏省广告行业调查问卷（2022版）开始填报啦' time='2022年10月25日' content='2022年2月20日举行的第六届世界互联网大会上，国投创业投资企业科技凭借新一代云端AI芯片荣获“世界互联网**科技成果奖”。这是继1A终端智能处理器入选2016年世界互联网大会的**科技成果大奖后，其云端芯片再次获得世界互联网大会的认可。这些成果通常被认为代表了当时互联' src='https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F1115%2F092621094420%2F210926094420-2-1200.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1663918442&t=ea9163b7e16e499562bae75dc0653608' btnText='MORE' key={index}></PageItem>
						})
					}
					<div style={{
						paddingTop: '70px'
					}}>
						<LyzPagination onChange={pageChange} total={total} pageSize={props.pageSize}></LyzPagination>
					</div>
				</div>
			</div>
			{
				showInfo ? <>
					<PageInfo onBack={() => setShowInfo(false)}></PageInfo>
				</> : <></>
			}
		</>);
}
export default PageList