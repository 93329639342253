
/**
 *@description: 个人中心
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-09-27 15:39:04
 *@email: 1982338226@qq.com
*/
import { getMyInfo } from "@/api/login"
import imageListData from "@/assets/image"
import LyzButton from "@/components/LyzButton/LyzButton"
import LyzImage from "@/components/LyzImage/LyzImage"
import { removeCookie } from "@/utils/cookie"
import React from "react"
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AccountInformation from "./module/accountInformation"
import PersonneInformation from "./module/personneInformation"
import PostDemand from "./module/postDemand"
import PostJob from "./module/postJob"
import PostMessage from "./module/postMessage"
import ReleaseResources from "./module/releaseResources"
import './myInfo.css'

const MyInfo: FC = () => {
    const navigate = useNavigate();
    const [active,setActive] = useState(0)
    const ReleaseResourcesRef:any = React.createRef();
    const PostDemandRef:any = React.createRef();
    const PostJobRef:any = React.createRef();
    const PersonneInformationRef:any = React.createRef();
    const PostMessageRef:any = React.createRef();
    const goToPath = (active:any,item:any) => {
        if(active === 0){
            setActive(1)
            ReleaseResourcesRef.current.func(item);
        }else if(active === 1){
            setActive(2)
            PostDemandRef.current.func(item);
        }else if (active === 2){
            setActive(3)
            PostJobRef.current.func(item);
        }else if(active === 3){
            setActive(4)
            PersonneInformationRef.current.func(item);
        }
    }
    const goToBack = (i:any) => {
        setActive(5)
        PostMessageRef.current.func(i);
    }
    const list = [
        {
            title:'账号信息',
            element: <div style={{display: active=== 0 ? 'block' : 'none'}}>
                <AccountInformation/>
            </div>
        },{
            title: '发布资源',
            element: <div style={{display: active=== 1 ? 'block' : 'none'}}>
                <ReleaseResources onRef={ReleaseResourcesRef} setActive={goToBack}/>
            </div>
        },{
            title: '发布需求',
            element: <div style={{display: active=== 2 ? 'block' : 'none'}}>
                <PostDemand onRef={PostDemandRef} setActive={goToBack}/>
            </div>
        },{
            title: '发布招聘信息',
            element: <div style={{display: active=== 3 ? 'block' : 'none'}}>
                <PostJob onRef={PostJobRef} setActive={goToBack}/>
            </div>
        },{
            title: '人才信息发布',
            element: <div style={{display: active=== 4 ? 'block' : 'none'}}>
                <PersonneInformation onRef={PersonneInformationRef} setActive={goToBack}/>
            </div> 
        },{
            title: '已发布消息',
            element: <div style={{display: active=== 5 ? 'block' : 'none'}}>
                <PostMessage onRef={PostMessageRef} goTo={(active:any,item:any)=>{goToPath(active,item)}}/>
            </div>  
        }
    ]
    
    const [data,setData] = useState<any>()
    const getUserInfo = async () => {
        const res = await getMyInfo()
        setData(res.data.data)
    }
    const quit = () => {
        removeCookie('userPhone');
        removeCookie('loginPassword');
        removeCookie('token');
        navigate('/')
        window.scrollTo(0, 0);
    }
    useEffect(()=>{
        getUserInfo()
    },[])
    return <div>
        <LyzImage url={imageListData.myInfoBack}></LyzImage>
        <div className="my-info-body page_body">
            <div className="my-info-left-body">
                <div className="my-info-left-item">
                    <img className="my-info-buddha-img" src={data?.headPortrait && data.headPortrait.length>0 ? data.headPortrait[0].fileUrl : imageListData.companyLogin} alt="" />
                </div>
                <div className="my-info-left-item font_18">{data?.companyName}</div>
                <div className="my-info-left-item">
                    <LyzButton borderColor="#3C7FFF" color="#3C7FFF" text="退出登陆" onClick={()=>{quit()}}></LyzButton>
                </div>
                <div className="my-info-left-bottom">
                    <div className="my-info-left-bottom-title font_18">公司名片</div>
                    <div className="my-info-left-bottom-conter text_nowrap_5">{data?.introduce}</div>
                </div>
            </div>
            <div className="my-info-right-body">
                <div className="my-info-tabs">
                    {
                        list.map((item,index)=>{
                            return <div className="my-info-tabs-item" onClick={()=>{index === 5 ? goToBack(0) : setActive(index)}}>
                                <div style={{paddingBottom:'5px'}}>{item.title}</div>
                                <div style={{height:'4px'}} className={active === index ? "my-info-tabs-item-active" : ''}></div>
                            </div>
                        })
                    }
                </div>
                <div className="my-info-right-content-bofy">
                    {
                        list.map((item,index)=>{
                            return  item.element
                        })
                    }
                </div>
            </div>
        </div> 
    </div>
}
export default MyInfo