/**
 *@description: 热门
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-09-15 14:47:50
 *@email: 1982338226@qq.com
*/
import imageListData from '@/assets/image';
import LyzImage from '@/components/LyzImage/LyzImage';
import { FC } from 'react';
import './rightList.css'
interface props {
    type?:string
}
const RightList: FC<props> = (props) => {
    return (
        <>
        <div className='right-list-body'>
            <div className='right-list-title'>企业入口</div>
            <div className='entrance-body'>
                <img src={imageListData.fb} alt="" />
                <div className='entrance-title'>发布职位·传播内容</div>
            </div>
            {
                props?.type === '1' ? <div style={{marginTop:'20px'}}>
                    <LyzImage width={'100%'} url={imageListData.zpxx1}></LyzImage>
                </div> : <div style={{marginTop:'20px'}}>
                    <LyzImage width={'100%'} url={imageListData.zpxx2}></LyzImage>
                </div>
            }
            
            
        </div>
        </>
    )
}
export default RightList