import { forwardRef } from 'react';
import catIcon from '@/assets/icon/catIcon.png'
import timeIcon from '@/assets/icon/timeIcon.png'
import LyzImage from '@/components/LyzImage/LyzImage'
import { NewsItem } from '@/typeScripeMode';
import { useNavigate } from 'react-router-dom';
interface props {
    data: NewsItem;
	active: number;
}
const HotItem = forwardRef((props:props) => {
	let navigate = useNavigate();
	return (
		<>
			<div className='hot-tabs-item-body' onClick={() => navigate(`/News?id=${props.data.newsId}&active=${props.active}`)}>
				<div className='hot-tabs-item-type'>{props.data.newsCategoryName}</div>
				<div style={{cursor:'pointer'}}>
					<div className='hot-tabs-item-title text_nowrap'>{props.data.newsTitle}</div>
					<span className='flex flex-aic color_999 font_14'>
						<LyzImage width='20px' height='14px' url={catIcon}></LyzImage>
						<span style={{margin: '0 26px 0 9px'}}>{props.data.clickNum}</span>
						<LyzImage width='14px' height='14px' url={timeIcon}></LyzImage>
						<span style={{marginLeft: '9px'}}>{props.data.createTime}</span>
					</span>
				</div>
			</div>
		</>);
})
export default HotItem