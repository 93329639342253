import LyzButton from '@/components/LyzButton/LyzButton';
import LyzPagination from '@/components/LyzPagination/LyzPagination';
import { forwardRef, ReactNode } from 'react';
import './excel.css'
interface Excelprops {
    children?: ReactNode;
    cardType?: string;
    onBack?: any;
    data?: any;
    pageChange?:any;
    setQueryExcelData?: any;
    queryExcelData?: any;
}
const MyExcel = forwardRef((props: Excelprops, ref: any) => {
    const tapBack = () => {
		props.onBack()
	}
    const pageChange = (page: any) =>{
        const data = Object.assign(props.queryExcelData,page)
        props.setQueryExcelData(data)
        props.pageChange(data)
    }
    return (
        <>
            <div className='excel-title'>获奖名单</div>
            <div className='excel-body'>
                <div className='excel-top-body'>
                    <div className='excel-top-title'>奖项</div>
                    <div className='excel-top-title'>作品名称</div>
                    <div className='excel-top-title'>获奖单位</div>
                    <div className='excel-top-title'>获奖时间</div>
                </div>
                <div className='excel-list-body'>
                    {
                        props?.data?.list && props?.data?.list.length> 0 && props.data.list.map((item:any)=>{
                            return <div className='excel-list-li'>
                                <div className='excel-list-li-item'>{item.awardGrade}</div>
                                <div className='excel-list-li-item'>{item.worksName}</div>
                                <div className='excel-list-li-item'>{item.awardUserName}</div>
                                <div className='excel-list-li-item'>{item.awardTime}</div>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className='tab-list-pag'>
                <LyzPagination onChange={pageChange} total={props.data?.total || 10} pageSize={10}></LyzPagination>
            </div>
            <div style={{ padding: '40px 0' }} className={`flexr-jsb flex-aic`}>
                <span></span>
                <LyzButton onClick={tapBack} borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
                <span></span>
            </div>
        </>
    );
})
export default MyExcel