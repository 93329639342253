/**
 *@description: 从业资质
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { FC, useState } from 'react';
import Tab from '@/components/Tab';
import { getQualifiedInfo, getQualifiedList } from '@/api/membershipService';
import Inquiry from './module/inQuiry';
import imageListData from '@/assets/image';
import LyzImage from '@/components/LyzImage/LyzImage';
const ProfessionalQualifications: FC = () => {
    const professionalQualificationsTypeList = [
        {
            title: '广告设计师',
            type: '1',
        }, {
            title: '广告审查员',
            type: '2'
        }, {
            title: '证书查询',
            type: '3',
            element: <>
                <Inquiry />
            </>
        }, {
            title: '报名入口',
            type: '4'
        }
    ]
    const queryData = {
        current: '1',
        pageSize: '10',
        releaseStatus: '1',
        qualifiedColumn: '1'
    }
    const [active, setActive] = useState('1')
    const [show, setShow] = useState(false)
    const getList = async (e: any) => {
        if (e?.qualifiedColumn && e?.qualifiedColumn === '4') {
            setShow(true)
            setActive('4')
        } else {
            setShow(false)
        }

        const requestData = { ...queryData, ...e }
        const res = await getQualifiedList(requestData)
        return {
            list: res.data.data.list,
            total: res.data.data.total || 0
        }
    }
    const getDetails = async (e: any) => {
        const data = {
            qualifiedId: e
        }
        const res = await getQualifiedInfo(data)
        return res.data.data
    }
    const goActive = (item:any) => {
        setActive(item.type);
        if(item.type !== '4'){
            setShow(false)
        }
    }
    return (
        <>
            {
                !show ? <>
                    <Tab request={getList}
                        haederProps={{ typeList: professionalQualificationsTypeList }}
                        requestDetails={getDetails}
                        queryProps={{ queryData: queryData, queryTageName: 'qualifiedColumn', active: active }}
                        detailsContentProps={{ showDatya: { title: 'qualifiedTitle', content: 'qualifiedContent', time: 'createTime' }, toPath: '/MembershiMpService' }}
                        contentProps={{ isShowImage: true, showDatya: { cardTitle: 'qualifiedTitle', cardTime: 'createTime', cardId: 'qualifiedId', cardFileList: 'coverFileList' }, cardType: '3' }}></Tab>
                </> : <>
                    <div className="box-type-body" style={{ marginTop: '9px' }}>
                        {
                            professionalQualificationsTypeList.map(item => {
                                return <div onClick={() => { goActive(item)}} className={`box-type-item-body ${active === item.type ? 'box-type-item-active-body' : undefined}`}>{item.title}</div>
                            })
                        }
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '50px' }}>
                        <div>
                            <LyzImage width='300px' height='300px' url={imageListData.wxxcx}></LyzImage>
                            <div style={{ marginTop: '20px', textAlign: 'center', fontSize: '18px' }}>请扫码报名</div>
                        </div>
                    </div>

                </>
            }

        </>
    );
}
export default ProfessionalQualifications