/**
 *@description: 党的建设
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-29 15:51:37
 *@email: 1982338226@qq.com
*/
import imageListData from "@/assets/image";
import HeaderTabs from "@/components/HeaderTabs/HeaderTabs";
import { locatioData } from "@/typeScripeMode";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BrandJianSu from "./module/brandJianSu";
import HumanResource from "./module/humanResource";
import IndustrialParks from "./module/industrialParks";
import IndustryThink from "./module/industryThink";
// import PublicInterest from "./module/publicInterest";
// import PublicInterestTemp from "./module/publicInterestTemp";
import BrandJianSuTemp from "./module/brandJianSuTemp";
import Resources from "./module/resources";

const PartyBuilding: FC = () => {
    const location = useLocation();    
    const {state} = location as locatioData
    const columns = [{
		title: '人力资源',
		icon: imageListData.rlzy,
		element:  <div className='pad-tb-20'>
            <HumanResource/>
        </div>
	},{
		title: '广告资源',
		icon: imageListData.ggzy,
		element:  <div className='pad-tb-20'>
			<Resources/>
        </div>
	},{
		title: '产业园区',
		icon: imageListData.cyyq,
		element:  <div className='pad-tb-20'>
			<IndustrialParks/>
        </div>
	},{
		title: '行业资讯',
		icon: imageListData.gygg,
		element:  <div className='pad-tb-20'>
			{/* <PublicInterestTemp/> */}
			<BrandJianSuTemp/>
			
        </div>
	},{
		title: '品牌江苏',
		icon: imageListData.ppjs,
		element:  <div className='pad-tb-20'>
			<BrandJianSu/>
        </div>
	},{
		title: '产业智库',
		icon: imageListData.cyzk,
		element:  <div className='pad-tb-20'>
			<IndustryThink/>
        </div>
	}]
	useEffect(() => {
	})
	return (
		<>
            <HeaderTabs columns={columns} breadcrumb={['产业服务','人力资源']} bgSrc={imageListData.cyfwback} active={state?.active? state.active : undefined}></HeaderTabs>
		</>
    );
}
export default PartyBuilding