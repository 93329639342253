import { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import LyzImage from '@/components/LyzImage/LyzImage'
import HomeTabs from './components/HomeTabs'
import AssociationCard from './components/AssociationCard'
import HomeTitle from './components/HomeTitle'
import MemberCard from './components/MemberCard'
import AboutCard from './components/AboutCard'
import MemberSwiper from './components/MemberSwiper'
import { getCarouselList } from '@/api/home';
import '@/typeScripeMode'
import { CarouselItem } from '@/typeScripeMode';
const Home = (props: any, ref: any) => {
	const [carouselList,setCarouselList] = useState<CarouselItem[]>()
	const getCarouselLists = (data: { advType?: string | undefined; } | undefined)=>{
		getCarouselList(data).then(res=>{
			setCarouselList(res.data.data)
		})
	}
	useEffect(() => {
		getCarouselLists({
			advType:'1'
		})
		// window.scrollTo(0, 1876);
	}, [])
	const toLogin = (item:any) => {
		if(item.advUrl) {
			window.open(item.advUrl)
		}
	}
	return (
		<>
			<div style={{margin:'0 auto',width:'1260px'}}>
				<Carousel autoplay>
					{
						carouselList && carouselList.length > 0 && carouselList.map((item)=>{
							return <div>
								<LyzImage style={{cursor:'pointer'}} onClick={()=>{toLogin(item)}} height='500px' url={item.fileList[0].fileUrl}></LyzImage>
							</div>
						})
					}
				</Carousel>
			</div>
			<div className='page_body'>
				<HomeTabs></HomeTabs>
				<AssociationCard></AssociationCard>
				<HomeTitle tips='MEMBER' title='会员服务'></HomeTitle>
			</div>
			<MemberCard></MemberCard>
			<HomeTitle tips='ASSOCIATION' title='关于协会'></HomeTitle>
			<AboutCard></AboutCard>
			<HomeTitle tips='UNIT' title='会员单位'></HomeTitle>
			<MemberSwiper></MemberSwiper>
			{/* <ProposalForm></ProposalForm> */}
		</>);
};
export default Home