/**
 *@description: 企业发展
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { FC, useState } from 'react';
import Tab from '@/components/Tab';
import { getCookie } from "@/utils/cookie"
import { useNavigate  } from 'react-router-dom';

import { getNewsDetails, getNewsList } from '@/api/news';
import { getCourseClass, getCourseInfo, getCoursePageList } from '@/api/membershipService';
import { Modal } from 'antd';

const CorporateDevelopment: FC = () => {
	const navigate = useNavigate();
    const [queryList,setQueryList] =  useState<any>()
    const [active,setActive] = useState()
    const CorporateDevelopmentTypeList = [
        {
            title: '广告委托',
            type: '32',
        }, {
            title: '招标资讯',
            type: '33'
        }, {
            title: '融资服务',
            type: '34'
        }, {
            title: 'VIP培训',
            type: '3',
        }, {
            title: '政策补贴',
            type: '42'
        }
    ]
    const queryData={
        current: '1',
        pageSize: '10',
        parentId:'31',
        status:'1',
        newsCategory: '32'
    }
    const queryItemData={
        current: '1',
        pageSize: '10',
    }
    const getList = async (e:any) => {
        if(e?.newsCategory === undefined){
            getCourseClassList()
            const requestData = {...queryItemData,...e}
            const res = await getCoursePageList(requestData)
            return {
                list: res.data.data.list,
                total: res.data.data.total || 0
            }
        }else if(e?.newsCategory === '3'){
            setActive(e.newsCategory)
            getCourseClassList()
            const requestData = {...queryItemData,...e}
            const res = await getCoursePageList(requestData)
            return {
                list: res.data.data.list,
                total: res.data.data.total || 0
            }
        }else{
            setActive(e.newsCategory)
            const requestData = {...queryData,...e}
            const res = await getNewsList(requestData)
            return {
                list: res.data.data.list,
                total: res.data.data.total || 0
            }
        }
    }
    const getDetails = async (e:any) => {
        if(active === '3'){
            const data = {
                id:e
            }
            const res = await getCourseInfo(data)
            return res.data.data
        }else{
            const res = await getNewsDetails(e)
            return res.data.data
        }
    }
    const getCourseClassList = async () =>{
        const res = await getCourseClass()
        const list = [
            {
                title:'类型',
                valueType:'select',
                valueTypeList:[{
                    label:'全部',
                    value:'undefined'
                },...res.data.data],
                dataIndex:'courseClassification'
            }
        ]
        setQueryList(list)
    }
    const tapTabTo = (e: any,callback: (isTo: boolean,data?:any) => void) => {
        if(e.newsCategory === '33') {
            if(getCookie('token')) {
                let eTemp = JSON.parse(JSON.stringify(e))
                eTemp.vipPermissions = e.vipMessageVO && e.vipMessageVO.vipPermissions === 0 ? true :false
                eTemp.vipTipMessage = e.vipMessageVO && e.vipMessageVO.vipPermissions === 0 ? e.vipMessageVO.vipTipMessage : ''
                if(eTemp.vipPermissions) {
                    Modal.info({
                        width:'690px',
                        icon: '',
                        okText:'好的',
                        content: (
                            <>
                            <article style={{
                                whiteSpace: 'pre-wrap',
                                overflowY:'auto'
                            }} dangerouslySetInnerHTML={{
                                __html:eTemp.vipTipMessage|| ''
                            }}></article></>
                        ),
                        onOk() {},
                    });
                } else {
                    callback(true)
                }
                // callback(true,eTemp)
                
            } else {
                navigate('/login')
            }
        } else {
            callback(true)
        }
    }
    return (
        <>  
            <Tab request={getList} 
            requestDetails={getDetails} 
            tabTo={tapTabTo}
            queryProps={{
                queryData:queryData,
                active:'32',
                queryTageName:'newsCategory',
                queryList:active === '3'? queryList : undefined,
                isShow:active === '3'?true : false
            }}
            haederProps={{typeList:CorporateDevelopmentTypeList}}
            detailsContentProps={{
                showDatya: active === '3' ? {
                    title:'courseName',
                    content:'courseIntroduction',
                    courseMaterials:'courseMaterials',
                    time:'courseDuration',
                    fileList:'detailPageImgList',
                    lecturer: 'lecturerName',
                    startTime:'attendClassTime',
                    contact:'trainContactUser',
                    contactPhone:'trainContactPhone'
                }:{
                    title:'newsTitle',
                    content:'newsContent',
                    time:'createTime'
                },
                toPath:'/MembershiMpService',
                detailType: active === '3' ? '2' : undefined
            }} 
            contentProps={{
                isShowImage: true,
                isShowContent:true,
                showDatya:active === '3' ? {
                    cardTitle:'courseName', 
                    cardTime:'releaseTime',
                    cardId:'id',
                    cardFileList:'coursesCoverList',
                    lecturerName:'lecturerName',
                    paymentAmount:'paymentAmount',
                    applyNumber:'applyNumber',
                }:{
                    cardTitle:'newsTitle', 
                    cardTime:'updateTime', 
                    cardId:'newsId',
                    cardContent:'newsRemark',
                    cardFileList:'fileList'
                },
                cardType: active === '3' ? '2' : '3'
            }}></Tab>
        </>
    );
}
export default CorporateDevelopment