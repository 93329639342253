import { useEffect, forwardRef } from 'react';
interface Iprops {
	width?: string,
	height?: string,
	display?: string,
	onClick?: any,
	url: string,
	fit?: 'cover' | 'fill' | 'contain' | 'none' | 'scale-down'
	style?: any
}
const LyzImage = forwardRef((props: Iprops, ref: any) => {
	useEffect(() => {
	}, [])
	return (
		<>
			<img onClick={props.onClick} style={{
				width: props.width || '100%',
				height: props.height || '100%',
				display: props.display || 'inline-block',
				verticalAlign:'middle',
				objectFit: props.fit || 'cover',
				...props.style
			}} src={props.url} alt="" />
		</>);
})
export default LyzImage