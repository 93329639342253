import imageListData from '@/assets/image';
import { useEffect, ReactElement } from 'react';
import LyzButton from '../LyzButton/LyzButton';
import LyzImage from '../LyzImage/LyzImage';
import './JobsInfo.css'
const JobsInfo = (props: {
	onBack?: any; 
	data?: any;
	showDatya?: {
		content?: string;
		title?: string;
		id?:string;
		startTime?: string; 
        endTime?: string;
		time?: string;
		fileList?:any;
        minDegree?: string;
        positionType?: string;
        workYearLimit?: string;
        releaseUnit?:string;
        workWelfare?: string;
        salaryRange?: string;
        detailedAddress?: string;
        gender?:string;
        graduateSchool?: string;
        professional?: string;
        politicalLandscape?: string;
        educationExperience?: string;
        selfAssessment?: string;
        workExperience?: string;
	}}): ReactElement => {
	useEffect(() => {
	}, [])
	const tapBack = () => {
		props.onBack && props.onBack()
	}
	return (
		<>
			<div style={{paddingTop: '40px'}}>
                <div className='jobs-info-top-body'>
                    <div className='jobs-info-top-left-body'>
                        <img src={props.showDatya?.gender && props.data[props?.showDatya?.gender]=== '男' ? imageListData.man : imageListData.girl} alt="" />
                    </div>
                    <div className='jobs-info-top-right-body'>
                        <div className='info-top-top-body'>
                            <div className='info-top-top-title'>{props?.showDatya?.positionType ? props.data[props?.showDatya?.positionType] : undefined}</div>
                            <div className='info-top-top-pay'>{props?.showDatya?.salaryRange ? props.data[props?.showDatya?.salaryRange] : undefined}</div>
                        </div>
                        <div className='info-top-botton'>
                            <div className='info-top-botton-left'>
                                {props?.showDatya?.graduateSchool ? props.data[props?.showDatya?.graduateSchool] : undefined} 
                                {props?.showDatya?.professional && props.data[props?.showDatya?.professional] ? ' | ' + props.data[props?.showDatya?.professional] : undefined}
                                {props?.showDatya?.politicalLandscape && props.data[props?.showDatya?.politicalLandscape] ? ' | ' + props.data[props?.showDatya?.politicalLandscape] : undefined} 
                            </div>
                        </div>
                        <div className='info-top-botton'>
                            <div className='info-top-botton-left'>
                                {props?.showDatya?.workYearLimit ? props.data[props?.showDatya?.workYearLimit] : undefined} 
                                {props?.showDatya?.minDegree && props.data[props?.showDatya?.minDegree] ? ' | ' +  props.data[props?.showDatya?.minDegree] : undefined}
                                {props?.showDatya?.gender && props.data[props?.showDatya?.gender] ? ' | ' +  props.data[props?.showDatya?.gender] : undefined} 
                            </div>
                        </div>
                    </div>
                </div>
                <div className='recruitment-hands-info-content-body'>
                    <div className='recruitment-hands-info-content-left-body'>
                        <div className='info-content-left-title'>个人优势</div>
                        <article style={{
                            whiteSpace: 'pre-wrap',
                            fontSize:'16px',
                            overflowY:'auto'
                        }} dangerouslySetInnerHTML={{
                            __html:props.showDatya?.selfAssessment ? props.data[props.showDatya.selfAssessment] : undefined
                        }}></article>
                        <div className='jobs-info-content-left-title'>工作经历</div>
                        <div>{props?.showDatya?.workExperience ? props.data[props?.showDatya?.workExperience] : undefined} </div>
                        <div className='info-content-left-title'>教育经历</div>
                        <article style={{
                            whiteSpace: 'pre-wrap',
                            fontSize:'16px',
                            overflowY:'auto'
                        }} dangerouslySetInnerHTML={{
                            __html:props.showDatya?.educationExperience ? props.data[props.showDatya.educationExperience] : undefined
                        }}></article>
                    </div>
                    <div className='recruitment-hands-info-content-right-body'>
                        <div className='info-content-right-title'>人员推荐</div>
                        <div style={{marginTop:'20px'}}>
                            <LyzImage width={'100%'} url={imageListData.zpxx2}></LyzImage>
                        </div>
                    </div>
                </div>
                <div style={{padding: '70px 0'}} className='flexr-jsb flex-aic'>
					<span></span>
					<LyzButton onClick={tapBack} borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
					<span></span>
				</div>
			</div>
		</>);
}
export default JobsInfo