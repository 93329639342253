import { Modal } from 'antd';
import { useEffect, ReactElement } from 'react';
import LyzButton from '../LyzButton/LyzButton';
// 在展示页面引入css样板文件
import 'braft-editor/dist/output.css'
import downIcon from '@/assets/icon/down-icon.png'
const PageInfo = (props: {
	onBack?: any; 
	goNext?: any; 
	data?: any;
	contentButtonType?: string;
	showDatya?: {
		content?: string;
		title?: string;
		id?:string;
		startTime?: string; 
        endTime?: string;
		time?: string;
		fileList?:any;
	}}): ReactElement => {
	const tapBack = () => {
		props.onBack && props.onBack()
	}
	const exportExcel = () => {
		window.location.href= props.data[props.showDatya?.fileList][0].fileUrl;
	}
	const goNext = () => {
		props.goNext && props.goNext(props.showDatya?.id? props.data[props.showDatya?.id] : undefined)
	}
	const showModal = () => {
		Modal.info({
			width:'690px',
			icon: '',
			okText:'好的',
			content: (
				<>
				<article style={{
					whiteSpace: 'pre-wrap',
					overflowY:'auto'
				}} dangerouslySetInnerHTML={{
					__html:props.data?.vipTipMessage|| ''
				}}></article></>
			),
			onOk() {},
		});
	}
	return (
		<>
			<div style={{
				paddingTop: '70px'
			}}>
				<h2 className='tac font_20' style={{fontWeight:'bold',lineHeight:'35px'}}>{props.showDatya?.title ? props.data[props.showDatya.title] : undefined}</h2>
				{
					props.showDatya?.time ? 
					<p className='tar font_16' style={{marginTop: '30px'}}>{props.showDatya?.time ? props.data[props.showDatya.time] : undefined}</p>
					: null
				}
				{
					props.showDatya?.startTime ? 
					<p className='tar font_16' style={{marginTop: '30px'}}>
						{props.showDatya?.startTime ? props.data[props.showDatya.startTime]: undefined} - 
						{props.showDatya?.endTime ? props.data[props.showDatya.endTime] : undefined}
					</p>
					: null
				}
				<div className="braft-output-content" style={{
					whiteSpace: 'pre',
					marginTop: '30px',
					fontSize:'16px',
					overflowY:'auto'
				}} dangerouslySetInnerHTML={{
					__html:props.showDatya?.content ? props.data[props.showDatya.content] : undefined
				}}>
					
				</div>
				{
					props.data?.vipPermissions ? <div style={{
						height:'70px',
						display:'flex',
						alignItems:'center',
						justifyContent:'center',
						fontSize:'18px',
						color:'#333',
						fontWeight:'600',
						cursor:'pointer',
						position:'relative'
					}} onClick={() => showModal()}>
						<div style={{
							top:'-50px',
							width:'1400px',
							height:'60px',
							position:'absolute',
							background: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%)',
							filter: 'blur(20px)'
						}}></div>
						<div>剩余90%未读，<span style={{color:'#3370FF'}}>展开全文</span><img style={{
							width:'23px',
							height:'23px'
						}} src={downIcon} alt=""></img></div>
					</div>: ''
				}
				
					{
						props.contentButtonType === '1' ? <div style={{padding: '70px 0',display:'flex',alignItems:'center',flexDirection:'row-reverse'}}>
							<LyzButton onClick={goNext} margin='0px 0px 0px 20px' borderColor="#3C7FFF" color="#3C7FFF" text="获奖名单"></LyzButton>
							{
								props.data[props?.showDatya?.fileList].length>0 ? <LyzButton onClick={exportExcel} margin='0px 0px 0px 20px' width='150px' borderColor="#3C7FFF" bgc='#3C7FFF' color="#FFF" text="资料下载(报名表)"></LyzButton>
								: null
							}							
							<LyzButton onClick={tapBack} margin='0px 0px 0px 20px' borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
						</div> : <div style={{padding: '70px 0'}} className={`flexr-jsb flex-aic`}>
							<span></span>
							<LyzButton onClick={tapBack} borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
							<span></span>
						</div>
					}
				
			</div>
		</>);
}
export default PageInfo