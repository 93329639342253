import { useEffect, FC, useState } from 'react';
import LyzImage from '@/components/LyzImage/LyzImage';
import { useNavigate } from 'react-router-dom';
import { getMembersUnitList } from '@/api/home';
import '../css/AboutCard.css'
import { Carousel } from 'antd';

import imageListData from '@/assets/image';
const MemberSwiper: FC = () => {
	let navigate = useNavigate();
	const goNext = () => {
		navigate('/associationOfHome', {state: { active: 5} })
		window.scrollTo(0, 0);
	}
	const [list,setList] = useState<any>()
	const getMembersUnitLists = async () =>{
		const data = {
			pageSize: 1000,
			current: 1
		}
		const res = await getMembersUnitList(data)
		const result = []
		if(res.data.code === 200){
			for (var i = 0, j = res.data.data.length; i < j; i += 10) {
				result.push(res.data.data.slice(i, i + 10));
			}
				setList(result)
		}
	}
	useEffect(() => {
		getMembersUnitLists()
	}, [])
	return (
		<>
			<div className='page_body'>
				<Carousel autoplay>
					{
						list && list.length > 0 && list.map((row: any) => {
							return <div>
							<div className='h-y-list-body' style={{background:`url(${imageListData.background1})`}}>
								{
									row && row.length > 0 && row.map((item:any)=>{
										return <div className='h-y-list-item-body'>
												<LyzImage width='220px' url={item.fileList && item.fileList.length > 0 ? item.fileList[0].fileUrl : ''}></LyzImage>
												{/* <div className='h-y-list-item-t'>{item.memberName}</div> */}
											</div>
									})
								}
							</div>
						</div>
						})
					}
				
				</Carousel>
				<div className='tac font_18 color_666 pointer' style={{
					width: '150px',
					height: '37px',
					lineHeight: '37px',
					margin:'28px auto 70px',
					border: '1px solid #666666',
				}} onClick={()=>{goNext()}}>查看全部</div>
			</div>
		</>
	);
}
export default MemberSwiper