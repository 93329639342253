/* eslint-disable jsx-a11y/iframe-has-title */
/**
 *@description: 品牌江苏
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-09-22 10:17:59
 *@email: 1982338226@qq.com
*/

import { getImgList } from "@/api/industrialService"
import { getNewsDetails, getNewsList } from "@/api/news"
import Card from "@/components/Card"
import List from "@/components/List"
import LyzButton from "@/components/LyzButton/LyzButton"
import LyzPagination from "@/components/LyzPagination/LyzPagination"
import { FileObject } from "@/typeScripeMode"
import { ChangeEvent, FC, useEffect, useState } from "react"
import Book from "./book"
import './brandJianSu.css'
const BrandJianSu: FC = () => {
    const [list, setList] = useState<any>()
    const [total, setTotal] = useState(0)
    const [show, setShow] = useState<boolean>(false)
    const [imageList, setImageList] = useState<FileObject[]>()
    const [showDetailData, setShowDetailData] = useState<any>()
    const [id, setId] = useState()
    const [queryData,setQueryData] = useState<any>({
        current: '1',
        pageSize: '10',
        parentId: '45',
        status: '1',
    })
    const getNewsLists = async (e: any) => {
        const res = await getNewsList({ ...queryData, ...e })
        setList(res.data.data.list)
        setTotal(res.data.data.total)
    }
    const getInputValue = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryData({
            ...queryData,
            newsCategory: e.target.value
        })
    }
    const getQueryList = () => {
        getNewsLists({})
    }
    const getNewsDetail = async (e: any) => {
        setId(e)
        const res = await getNewsDetails(e)
        const imageLists = await getImgList({ relationId: e })
        setImageList(imageLists.data.data)
        setShowDetailData(res.data.data)
        setShow(true)
    }
    useEffect(() => {
        getNewsLists({})
    }, [])
    return <>
        {
            !show ?

                <div >
                    <div className="box-query-body">
                        <div></div>
                        <div className='query-input'>
                            <input className='query-input-text' type="text" onChange={e => getInputValue(e)} />
                            <div className='query-input-button' onClick={getQueryList}>搜 索</div>
                        </div>
                    </div>
                    <List className='grid-4'>
                        {
                            list && list.length > 0 && list.map((item: any) => {
                                return <Card
                                    toInfo={getNewsDetail}
                                    cardType={"10"}
                                    cardTitle={item.lxp}
                                    id={item.newsId}
                                    cardContent={item.newsTitle}
                                    // time={item.createTime}
                                    fileList={item.fileList}
                                />
                            })
                        }
                    </List>
                    <div className='tab-list-pag'>
                        <LyzPagination onChange={getNewsLists} total={total} pageSize={8}></LyzPagination>
                    </div>
                </div>
                :
                <div>
                    <div className="book-top-body">
                        <iframe style={{width:'100%',height:'100%'}} src={`https://bookwb.jssggxh.com/?url=https://test.jssggxh.com/api/wechat/conversionImg/getImgList?relationId=${id}`}></iframe>
                        {/* {
                            imageList && imageList.length > 0 ? <>
                                <div className="book-item-body">
                                <img src={imageList[0].fileUrl} width={350} height={500} alt="" style={{ marginRight: '30px' }} />
                                </div>
                            </> : null
                        } */}
                        {/* <Book imageList={imageList}/> */}
                    </div>
                    <div className="book-title">{showDetailData.newsTitle}</div>
                    <div className="book-content-body">
                        {
                            imageList && imageList.length > 0 ? <>
                                <img src={imageList[0].fileUrl} width={200} height={280} alt="" style={{ marginRight: '30px' }} />
                            </> : null
                        }
                        <div>
                        <div className="industrial-parks-details-content-left-title">内容简介</div>
                        <div className="industrial-parks-details-content-left-subtitle">书刊名称:{showDetailData.newsTitle}</div>
                        {/* <div>发布作者:{showDetailData.afterTitle}</div> */}
                        <div className="industrial-parks-details-content-left-subtitle">发布时间:{showDetailData.createTime}</div>
                        <div className="industrial-parks-details-content-left-subtitle">阅读次数:{showDetailData.clickNum}</div>
                        </div>
                    </div>
                    <div style={{ padding: '40px 0' }} className={`flexr-jsb flex-aic`}>
                        <span></span>
                        <LyzButton onClick={() => { setShow(false); window.scrollTo(0, 0); }} borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
                        <span></span>
                    </div>
                </div>
        }
    </>
}
export default BrandJianSu