/**
 *@description: 发布资源
 *@author: 刘宇凡
 *@fileName: releaseResources.tsx 
 *@date: 2022-10-08 18:08:23
 *@email: 1982338226@qq.com
*/
import { getResourcesInfo } from "@/api/industrialService";
import { saveResources } from "@/api/myInfo";
import UploadImage from "@/components/Upload/uploadImage";
import { handleFile, handleFileList } from "@/utils/upload";
import { Form, Input, message, Row, Select } from "antd"
import TextArea from "antd/lib/input/TextArea";
import { FC, useImperativeHandle, useState } from "react"

const ReleaseResources: FC<any> = (props) => {
    const [form] = Form.useForm();
    const { Option } = Select;
    const [detailsData,setDetailsData] = useState<any>()
    useImperativeHandle(props.onRef, () => {
        return {
            func: getDetails,
        };
    });
    const getDetails = async (e: any) => {
        const res = await getResourcesInfo({ id: e.id })
        const data = {
            coverPhotoList: handleFileList(res.data.data.coverPhotoList),
            resourcesType: res.data.data.resourcesType,
            resourcesName: res.data.data.resourcesName,
            keywords: res.data.data.keywords,
            resourcesDescription: res.data.data.resourcesDescription,
            content: res.data.data.content
        }
        setDetailsData(res.data.data)
        form.setFieldsValue(data);
    }
    const onFinish = () => {
        form.validateFields().then(async validate => {
            const data = {
                coverPhotoList: handleFile(validate.coverPhotoList),
                resourcesType: validate.resourcesType,
                resourcesName: validate.resourcesName,
                keywords: validate.keywords,
                resourcesDescription: validate.resourcesDescription,
                content: validate.content,
                id: detailsData && detailsData?.id ? detailsData.id : undefined,
                updateVersion: detailsData && detailsData?.updateVersion ? detailsData.updateVersion : undefined,
            }
            const res = await saveResources(data)
            if (res.data.code === 200) {
                message.success('提交成功')
                form.resetFields()
                if(detailsData?.id){
                    props.setActive(0)
                }
            }
        })
    }
    return <>
        <div className="account-information-title">发布资源</div>
        <Form form={form} size='large' style={{ marginTop: '20px' }} onFinish={onFinish}>
            <Form.Item label='资源名称' name="resourcesName" rules={[{ required: true, message: '请输入资源名称!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='资源类型' name="resourcesType" rules={[{ required: true, message: '请选择资源类型!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Select>
                    <Option value="户外">户外</Option>
                    <Option value="电视">电视</Option>
                    <Option value="广播">广播</Option>
                    <Option value="报纸">报纸</Option>
                    <Option value="杂志">杂志</Option>
                    <Option value="LED">LED</Option>
                    <Option value="新媒体">新媒体</Option>
                    <Option value="网络广告">网络广告</Option>
                    <Option value="自媒体">自媒体</Option>
                    <Option value="策划">策划</Option>
                    <Option value="制作">制作</Option>
                    <Option value="设备">设备</Option>
                    <Option value="材料">材料</Option>
                </Select>
            </Form.Item>
            <Form.Item label='关键词' name="keywords" labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='资源描述' name="resourcesDescription" labelCol={{ span: 3 }} wrapperCol={{ span: 20 }} colon={false}>
                <TextArea />
            </Form.Item>
            <Form.Item label='内容' name="content" rules={[{ required: true, message: '请输入内容!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }} colon={false}>
                <TextArea />
            </Form.Item>
            <Form.Item label='封面图片' name="coverPhotoList" rules={[{ required: true, message: '请上传封面图片!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }} colon={false}>
                <UploadImage></UploadImage>
            </Form.Item>
            <Row justify="center">
                <Form.Item>
                    <div className="Join-Us-submit-botton" style={{ background: '#3370FF', color: '#fff' }} onClick={onFinish}>提交</div>
                </Form.Item>
            </Row>
        </Form>
    </>
}
export default ReleaseResources