/**
 *@description: 协会家园
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-29 15:51:37
 *@email: 1982338226@qq.com
*/
import imageListData from "@/assets/image";
import HeaderTabs from "@/components/HeaderTabs/HeaderTabs";
import { FC, useEffect } from "react";
import AssociationCharter from "./module/associationCharter";
import './css/index.css'
import AssociationProfile from "./module/associationProfile";
import OrganizationChart from "./module/organizationChart";
import ManagementTeam from "./module/managementTeam";
import Members from "./module/members";
import OperatingMechanism from "./module/operatingMechanism";
import LocalAssociation from "./module/localAssociation";
import SelfDisciplineRemorse from "./module/selfDisciplineRemorse";
import { useLocation } from "react-router-dom";
import { locatioData } from "@/typeScripeMode";

const AssociationOfHome: FC = () => {
	const location = useLocation();    
    const {state} = location as locatioData
    const columns = [{
		title: '协会简介',
		icon: imageListData.xhjj,
		element:  <div className='pad-tb-20'>
            <AssociationProfile/>
        </div>
	},{
		title: '协会章程',
		icon: imageListData.xhzc,
		element:  <div className='pad-tb-20'>
            <AssociationCharter/>
        </div>
	},{
		title: '组织架构',
		icon: imageListData.zzjg,
		element:  <div className='pad-tb-20'>
            <OrganizationChart/>
        </div>
	},{
		title: '管理团队',
		icon: imageListData.gltd,
		element:  <div className='pad-tb-20'>
            <ManagementTeam/>
        </div>
	},{
		title: '工作机构',
		icon: imageListData.gzjg,
		element:  <div className='pad-tb-20'>
            <OperatingMechanism/>
        </div>
	},{
		title: '会员之窗',
		icon: imageListData.hyzc,
		element:  <div className='pad-tb-20'>
            <Members/>
        </div>
	},{
		title: '地方协会',
		icon: imageListData.dfxh,
		element:  <div className='pad-tb-20'>
            <LocalAssociation/>
        </div>
	},{
		title: '自律规则',
		icon: imageListData.zlzz,
		element:  <div className='pad-tb-20'>
			<SelfDisciplineRemorse/>
        </div>
	}]
	const breadcrumbList = state?.active ? ['协会家园',columns[Number(state?.active)].title] : ['协会家园','协会简介']
	useEffect(() => {
	})
	return (
		<>
            <HeaderTabs columns={columns} breadcrumb={breadcrumbList} bgSrc={imageListData.xhjyback} active={state?.active? state.active : undefined}></HeaderTabs>
		</>
    );
}
export default AssociationOfHome