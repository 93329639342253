import { useEffect, FC, useState } from 'react';
import LyzImage from '@/components/LyzImage/LyzImage';
import logoImg from '@/assets/home/编组.png'
import codeIcon from '@/assets/temp/codeIcon.png'
import { getLinkList } from '@/api/home';
import { useNavigate } from 'react-router-dom';
const footerList = [
	{
		title: '热点资讯',
		url:'/News',
		children: [
			{
				title: '产业新规',
				url: '/News',
				active:'1'
			},
			{
				title: '要案预警',
				url: '/News',
				active:'2'
			},
			{
				title: '协会新闻',
				url: '/News',
				active:'3'
			},
			{
				title: '通知公告',
				url: '/News',
				active:'4'
			}
		]
	}, {
		title: '党的建设',
		url: '/PartyBuilding',
		children: [
			{
				title: '组织建设',
				url: '/PartyBuilding',
				active:'0'
			},
			{
				title: '党建动态',
				url: '/PartyBuilding',
				active:'1'
			},
			{
				title: '学习园地',
				url: '/PartyBuilding',
				active:'2'
			},{
				title: '时代先锋',
				url: '/PartyBuilding',
				active:'3'
			}
		]
	}, {
		title: '协会家园',
		url: '/associationOfHome',
		children: [
			{
				title: '协会简介',
				url: '/associationOfHome',
				active:'0'
			},{
				title: '协会章程',
				url: '/associationOfHome',
				active:'1'
			},{
				title: '组织架构',
				url: '/associationOfHome',
				active:'2'
			},{
				title: '管理团队',
				url: '/associationOfHome',
				active:'3'
			}
		]
	}
]
const FooterItem = (props: { footerData: { title: string,url?: string,active?:string,children: Array<{title: string, url: string,active?: string}> } }) => {
	const navigate = useNavigate();
	const goTo = (item:any) => {
		navigate(item.url)
		window.scrollTo(0, 0);
	}
	const goToPath = (item:any) => {
		if(item.url === '/News'){
			navigate(`/News?id=undefined&active=${item.active}`)
			window.scrollTo(0, 0);
		}else{
			navigate(item.url, { state: { active: item.active } })
			window.scrollTo(0, 0);
		}
	}
	return (<div className='tac flex flex-aic' style={{
		width: '150px'
	}}>
		<div className='flex-fitem' style={{
			height: '100%'
		}}>
			<h4 className='color_fff' style={{
				marginBottom: '33px'
			}} onClick={()=>{goTo(props.footerData)}}>{props.footerData.title}</h4>

			{props.footerData.children.map((value, index) => {
				return <div key={index} className='pointer' style={{
					marginBottom:'21px'
				}} onClick={()=>{goToPath(value)}}>{value.title}</div>
			})}
		</div>
		<i style={{
			height: '180px',
			borderRight: '1px dashed #979797',
		}}></i>
	</div>)
}
const Footer: FC = () => {
	const [list,setList] = useState<any>()
	const getLinkLists = async () => {
		const res = await getLinkList()
		setList(res.data.data)
	}
	const goLink = (e:any) => {
		window.location.replace(e.linkUrl)
	}
	useEffect(() => {
		getLinkLists()
	}, [])
	return (
		<>
			<footer className='color_fff' style={{
				width: "100%",
				paddingTop: '32px',
				backgroundColor: '#3b3b3b'
			}}>
				<div className='page_body'>
					<div className='flexr-jsb'>
						<div className='font_18'>
							<LyzImage width='306px' height='46px' url={logoImg}></LyzImage>
							<p className='flex' style={{
								width: '315px',
								margin: '42px 0 8px 0'
							}}><span style={{
								width: '78px'
							}}>地址:</span><span>南京市建邺区新城科技园南京国家广告产业园5栋15层</span></p>
							<p style={{
								marginBottom: '26px'
							}}>电话：025—58780025</p>
							<p>邮箱：2831514481@qq.com</p>
						</div>
						<div className='flex'>
							{
								footerList.map((value, index) => {
									return <FooterItem footerData={value} key={index}></FooterItem>
								})
							}
						</div>
						<div>
							<div className='font_21 tac' style={{
								marginBottom: '33px'
							}}>关注我们</div>
							<div className='tac' style={{
								display: 'inline-block',
								marginRight: '29px'
							}}>
								<LyzImage width='128px' height='128px' url={codeIcon}></LyzImage>
								<div style={{
									marginTop: '10px'
								}}>公众号</div>
							</div>
							<div className='tac' style={{
								display: 'inline-block'
							}}>
								<LyzImage width='128px' height='128px' url={codeIcon}></LyzImage>
								<div style={{
									marginTop: '10px'
								}}>联系客服</div>
							</div>
						</div>
					</div>
					<div style={{
						paddingTop: '45px',
						paddingBottom: '27px'
					}}>
						<span>
							友情链接：
						</span>
						{
							list && list.length>0 && list.map((item:any)=>{
								return <span style={{marginRight:'20px',cursor: 'pointer'}} onClick={()=>{goLink(item)}}>{item?.linkName}</span>
							})
						}
					</div>
				</div>
				<div style={{
					backgroundColor: '#2C2C2C'
				}}>
					<div className='page_body flex flex-aic pointer' style={{
						height: '65px'
					}}>
						<span>版权所有：江苏省广告协会</span>
						<span style={{
							margin: '0 42px'
						}}>Copyright 2022 sdsggxh.cn.All Rights Reserved</span>
						<a className='color_fff' href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">苏ICP备18009208号-2 </a>
					</div>
				</div>
			</footer>
		</>);
}
export default Footer