
import LyzImage from '@/components/LyzImage/LyzImage';
import { Carousel } from 'antd';
import { useEffect, FC, useState, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom'
import '../css/HomeTabs.css'
import HotItem from './HotItem';
import { CarouselItem } from '@/typeScripeMode';
import { getCarouselList, getHotNewsList } from '@/api/home';
const HomeTabs: FC = () => {
	let navigate = useNavigate();
	const [active, setActive] = useState(0)
	const [list, setList] = useState([])
	const [carouselList,setCarouselList] = useState<CarouselItem>()
	const titleList = [
		{
			title: '热点资讯',
			type: '1'
		}, {
			title: '协会新闻',
			type: '4'
		}, {
			title: '通知公告',
			type: '5'
		}, {
			title: '产业新规',
			type: '2'
		}, {
			title: '要案警示',
			type: '3'
		}
	]
	const getList = (data: { newsCategory?: string | undefined; } | undefined) => {
		getHotNewsList(data).then(res => {
			setList(res.data.data)
		})
	}
	const getActive = (e: { title: string; type: string; }, i: SetStateAction<number>) => {
		setActive(i)
		getList({
			newsCategory: e.type !== '1' ? e.type : undefined 
		})
	}
	const getCarouselLists = (data: { advType?: string | undefined; } | undefined)=>{
		getCarouselList(data).then(res=>{
			setCarouselList(res.data.data)
		})
	}
	const toLogin = (item:any) => {
		window.open(item.advUrl)
	}
	useEffect(() => {
		getList(
			{
				newsCategory: undefined
			}
		)
		getCarouselLists({
			advType:'2'
		})
	}, [])
	return (
		<>
			<div className='home-tabs-body'>
				<ul className='home-tabs-title-body'>
					{
						titleList.map((item, index) => {
							return <li onClick={() => { getActive(item, index) }} className={`home-tabs-title ${active === index ? 'home-tabs-title-active' : ''}`}>
								<span>
									<span>{item.title}</span>
									<i className='tabActiveLine'></i>
								</span>
							</li>
						})
					}
					<li onClick={() => navigate('/News', { replace: false })} className='home-tabs-more'>
						<div className='home-tabs-more-circle'></div>
						<div className='home-tabs-more-circle'></div>
						<div className='home-tabs-more-circle'></div>
					</li>
				</ul>
				<div className='home-tabs-bottom-body'>
					<div className='home-tabs-left-body'>
						<div>
							{
								list.map((item) => {
									return <HotItem data={item} active={Number(titleList[active].type)-1}></HotItem>
								})
							}
						</div>
					</div>
					<div className='home-tabs-right-body'>
						<Carousel autoplay >
							{
								carouselList && carouselList.length > 0 && carouselList.map((item)=>{
									return <div>
										<LyzImage style={{cursor:'pointer'}}  onClick={()=>{toLogin(item)}} width='618px' height='422px' url={item.fileList[0].fileUrl} />
									</div>
								})
							}
						</Carousel>
					</div>
				</div>
			</div>
		</>);
}
export default HomeTabs