/**
 *@description: 图片上传基础
 *@author: 刘宇凡
 *@fileName: AliyunFormUploadFile.tsx
 *@date: 2022-07-18 20:44:55
 *@email: 1982338226@qq.com
 */

import { getFileKeys, getOSSWebCredentials } from "@/api/uplode"
import { message, Upload, UploadFile } from "antd"
import { RcFile } from "antd/lib/upload"
import { UploadChangeParam, UploadListType } from "antd/lib/upload/interface"
import { ReactChild, ReactFragment, ReactPortal, useState } from "react"
import './upload.css'

//引入阿里云OSS SDK
const OSS = require('ali-oss')
interface uploadType{
    fileSize?: number
    label?: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined
    action?: string | ((file: RcFile) => string) | ((file: RcFile) => PromiseLike<string>) | undefined
    listType?: UploadListType
    accept?: string | undefined
    max?: number
    onChange?: any
    value?: any
    serviceCode?: string
    imgWidth?: number
    imgHeight?: number
    multiple?: boolean
}
interface OssToken {
      region: string;
      bucket: string;
      accessKeyId: string;
      accessKeySecret: string;
      stsToken: string;
      expires: number;
      timeStamp: number;
    };
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
    multiple = false,
    serviceCode = '',
    value = [],
    label = '',
    max = 1,
    fileSize = 2,
    action = '',
    listType = 'text',
    accept = '.pdf,.ppt,.xsxl,.xls,.docx,.word,.excel,.doc',
    onChange,
}: uploadType) => {
    const list = JSON.parse(JSON.stringify(value))
    const [ossToken, setOssToken] = useState<OssToken>()
    // const [fileList, setFileList] = useState(value);
    //从后台获取临时密钥
    const stsToken = async () => {
        const result = await getOSSWebCredentials()        
        if (result?.data.code === 200) {
            const data = result.data.data
            const expires = new Date().getTime()
            const token = {
                expires: expires,
                ...data,
            }
            setOssToken(token)
            return token
        } else {
            message.error('获取上传密钥失败')
        }
    }

    //上传之前的钩子函数
    const beforeUpload = async (file: any) => {
        const isLt2M = file.size / 1024 / 1024 < fileSize
        if (!isLt2M) {
            message.error(`文件需要小于${fileSize}MB!`)
        }
        return isLt2M
    }
    //文件变化时的钩子函数
    const ImageOnChange = (info: UploadChangeParam<UploadFile<any>>) => {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'removed') {            
            onChange(info.fileList)
        } else if (info.file.status === 'done') {
            message.success(`上传成功`)
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} 上传失败.`)
        }
    }
    //自定义请求OSS方法
    const customRequest = async (options: any) => {
        const { onSuccess, onError, file } = options
        const token = await initOssInfo()
        const ossClient = new OSS(token)
        const originName = file.name
        if (ossClient) {
            const resultKey = await getFileKeys({
                fileNameList: [originName],
                serviceCode: serviceCode,
            })
            if (resultKey?.data.code === 200) {
                try {
                    const fileKey = resultKey.data.data.fileNameMap[originName]
                    const result = await ossClient.put(fileKey, file)                    
                    onSuccess(
                        onChange([
                            {
                                uid: file.uid,
                                name: originName,
                                url: result.url,
                                fileName: result.name,
                                size: file.size,
                            },
                        ]),
                    )
                } catch (e) {
                    onError()
                }
            } else {
                message.error(resultKey?.data.msg)
            }
        }
    }
    //预览图片取消按钮
    //初始化OSS信息
    async function initOssInfo() {
        if (ossToken) {
            const expires = ossToken.timeStamp
            const currentTime = new Date().getTime()
            if (currentTime - expires > 0) {
                return await stsToken()
            }
            return ossToken
        } else {
            return await stsToken()
        }
    }
    const uploadButton = (
        <div>
            {/* <PlusOutlined /> */}
            <div className="upload-button">上传附件</div>
            <div style={{ marginTop: 8 }}>{label}</div>
        </div>
    )
    return (
        <>
            <Upload
                multiple={multiple}
                action={action}
                listType={listType}
                accept={accept}
                fileList={list}
                onChange={ImageOnChange}
                beforeUpload={beforeUpload}
                customRequest={customRequest}
                maxCount={max}>
                {list.length >= max ? null : uploadButton}
            </Upload>
        </>
    )
}

