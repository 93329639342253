const cyzz = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/cyzz.png";
const flfw = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/flfw.png";
const hyjs = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/hyjs.png";
const huiyuanfuwu = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/huiyuanfuwu.png";
const qyfz = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/qyfz.png";
const xydj = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/xydj.png";
const zlgl = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/zlgl.png";
const zmsb = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/zmsb.png";
const zxhd = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/zxhd.png";
const xhjj = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/xhjj.png";
const xhzc = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/xhzc.png";
const zzjg = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/zzjg.png";
const gltd = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/gltd.png";
const gzjg = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/gzjg.png";
const hyzc = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/hyzc.png";
const rdzx = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/rdzx.png";
const zlzz = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/zlzz.png";
const cyxg = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/cyxg.png";
const yajs = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/yajs.png";
const xhxw = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/xhxw.png";
const tzgg = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/tzgg.png";
const zzjs = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/zzjs.png";
const djdt = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/djdt.png";
const xxyd = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/xxyd.png";
const sdxf = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/sdxf.png";
const dfxh = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/dfxh.png";
const rlzy = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/rlzy.png";
const ggzy = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/ggzy.png";
const cyyq = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/cyyq.png";
const gygg = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/gygg.png";
const ppjs = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/ppjs.png";
const cyzk = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/cyzk.png";
const man = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/man.png";
const girl = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/girl.png";
const companyLogin = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/companyLogin.png";
const fb = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/fb.png";
const loginBack = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/loginBack.png";
const dangdejianshe = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/dangdejianshe.png";
const zy = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/zy.png"
const gs = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/gs.png"
const gd = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/gd.png"
const trq = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/trq.png"
const gn = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/gn.png"
const wl = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/wl.png"
const zx = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/zx.png"
const xf = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/xf.png"
const dt = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/dt.png"
const kt = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/kt.png"
const ss = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/ss.png"
const st = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/st.png"
const cw = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/cw.png"
const tjcg = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/tjcg.png"
const jrwmback = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/jrwmback.png"
const xhzcItem = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/xhzcItem.png"
const lxwm = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/lxwm.png"
const wxxcx = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/wxxcx.jpg"
const zpxx1 = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/zpxx1.png"
const zpxx2 = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/zpxx2.png"
const ggzy1 = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/ggzy1.png"
const cyyq1 = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/cyyq1.png"
const background1 = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/background1.png"
const myInfoBack = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/myInfoBack.png"
const dj = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/dj.png"
const syzjback = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/syzjback.png"
const syzjback1 = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/syzjback1.png"
const xhjyback = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/xhjyback.png"
const cyfwback = "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/cyfwback.png"
const imageListData = {
    syzjback,
    syzjback1,
    xhjyback,
    dj,
    cyfwback,
    myInfoBack,
    cyyq1,
    background1,
    ggzy1,
    zpxx1,
    zpxx2,
    lxwm,
    cw,
    st,
    kt,
    ss,
    dt,
    zx,
    xf,
    wl,
    gn,
    trq,
    cyzz,
    flfw,
    hyjs,
    huiyuanfuwu,
    qyfz,
    xydj,
    zlgl,
    zmsb,
    zxhd,
    dangdejianshe,
    xhjj,
    xhzc,
    zzjg,
    gltd,
    gzjg,
    hyzc,
    zlzz,
    rdzx,
    cyxg,
    yajs,
    xhxw,
    tzgg,
    zzjs,
    djdt,
    xxyd,
    sdxf,
    dfxh,
    loginBack,
    rlzy,
    ggzy,
    cyyq,
    gygg,
    ppjs,
    cyzk,
    man,
    girl,
    companyLogin,
    fb,
    zy,
    gs,
    gd,
    jrwmback,
    tjcg,
    xhzcItem,
    wxxcx
}
export default imageListData