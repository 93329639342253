/**
 *@description: 工作机构
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { getNewsInfo } from '@/api/associationOfHome';
import { FC, useEffect, useState } from 'react';
const OperatingMechanism: FC = () => {
    const [data,setData] = useState({
        newsContent: ''
    })
    const getData = () => {
        const data = {
            parentId: '11',
            newsCategory:'16'
        }
        getNewsInfo(data).then(res=>{
            setData(res.data.data)
        })
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        <>
            <div className='association-charter-content' dangerouslySetInnerHTML={{
					__html:data['newsContent']
				}}></div>
        </>
    )
}
export default OperatingMechanism
