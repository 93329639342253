import { useEffect, FC } from 'react';
import imageListData from '@/assets/image';
import LyzImage from '@/components/LyzImage/LyzImage';
const AssociationCard: FC = () => {
	useEffect(() => {
	}, [])
	return (
		<>
		<div style={{marginTop:'53px',width: '100%',
				height: '228px',}}>
			<LyzImage url={imageListData.syzjback1}></LyzImage>
		</div>
		
			{/* <div className='boxs_bb flex flex-aic' style={{
				width: '100%',
				height: '228px',
				paddingLeft: '53px',
				backgroundImage: `url(${imageListData.syzjback})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: '100%',
				marginTop:'53px'
			}}>
				<div>
					<h4 className='font_32 color_fff' style={{
						fontWeight: '400',
						marginBottom: '19px'
					}}>江苏省广告协会是社团行业组织</h4>
					<p className='color_fff font_16' style={{
						marginBottom: '21px'
					}}>其会员是由江苏省范围内具备一定资质条件的广告主、广告经验者、广告发布者、与广告业有关的企、事业单位、社团法人等资源组成</p>
					<div className='flexr-jsb'>
						<div className='bgc_fff pointer color_333 font_18 tac' style={{
							width: '105px',
							height: '37px',
							lineHeight: '37px',
							borderRadius: '24px',
						}}>加入协会</div>
						<div className='pointer color_fff font_18 tac' style={{
							width: '81px',
							height: '37px',
							lineHeight: '37px',
							border: '1px solid #FFFFFF',
						}}>MORE+</div>
					</div>
				</div>
			</div> */}
		</>);
}
export default AssociationCard