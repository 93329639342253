import { useEffect, FC, useState, SetStateAction } from 'react';
import logoImg from '../assets/home/logo.png'
import styles from './css/header.module.css'
import { useNavigate,useLocation  } from 'react-router-dom';
import { getCookie } from '@/utils/cookie';
import { getMyInfo } from '@/api/login';
import imageListData from '@/assets/image';
import LyzImage from '@/components/LyzImage/LyzImage';
const Header: FC = () => {
	const navigate = useNavigate();
	const location = useLocation()
	const [active, setActive] = useState(0) 
	const menuList = [
		{
			title:'首页',
			toPath:'/index',
			index:0
		},{
			title:'会员服务',
			toPath:'/MembershiMpService',
			index:2
		},{
			title:'产业服务',
			toPath:'/industrialService',
			index:4
		},{
			title:'党的建设',
			toPath:'/PartyBuilding',
			index:1
		},{
			title:'协会家园',
			toPath:'/associationOfHome',
			index:3
		},{
			title:'加入我们',
			toPath:'/JoinUs',
			index:5
		}
	]
	const goPath = (e:any) =>{
		setActive(e.index)
		navigate(e.toPath)
		window.scrollTo(0, 0);
	}
	const [userDataInfo,setUserDataInfo] = useState<any>()
	const token = getCookie('token')
	const isLogin = async () => {
		if(token){
			const res = await getMyInfo()
			setUserDataInfo(res.data.data)
		}
	}
	const goMyInfo = () => {
		navigate('/myInfo')
	}
	useEffect(()=>{
		isLogin()
		if(location.pathname === '/News'){
			setActive(0)
		}else if(location.pathname !== '/contactUs' && location.pathname !== '/myInfo'){
			const activeIndex = menuList.filter(r=>r.toPath === location.pathname)[0].index
			setActive(activeIndex)
		}
	},[location.pathname])
	return (
		<>
			<header className='flexc-jcc flex-aic' style={{
				width: "100%",
				height: "70px",
				backgroundColor: '#fff'
			}}>
				<div className='flexr-jsb flex-aic' style={{
					width: "1260px",
					height: "70px",
					padding: '0',
				}}>
					<img style={{
						width: '218px',
						height: '42px'
					}} src={logoImg} alt="" />
					<div className={`flexc-jcc tac headerBody font_20 ${styles.headerBody} `}  style={{
						width: '787px',
						lineHeight: '70px'
					}}>
						{
							menuList.map((item)=>{
								return <div className={`flex-fitem ${active === item.index ? 'bgc_3370ff color_fff' : 'pos_r'}`} onClick={()=>{goPath(item)}}>
									<div className={styles.title_text}>{item.title}</div>
								</div>
							})
						}
					</div>
					{
						token === undefined || token === '' ? <div className='flexr-jfe flex-aic font_18' style={{
							width:'202px',cursor:'pointer'
						}} onClick={()=>{navigate('/login')}}
						>登录 | 注册</div> : <div style={{cursor:'pointer',display:'flex',alignItems:'center'}} 
						onClick={()=>{goMyInfo()}}
						>
							<img src={userDataInfo?.headPortrait && userDataInfo.headPortrait.length > 0 ? userDataInfo.headPortrait[0].fileUrl : imageListData.companyLogin} alt="" style={{
							width:'40px',height:'40px',borderRadius:'50%'
						}}/>
						<div style={{marginLeft:'10px',fontSize:18,}}>{userDataInfo?.userName}</div>
						</div>
					}
				</div>
			</header>
		</>);
}
export default Header