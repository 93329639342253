/**
 *@description:  管理团队
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { getMemberUnitInfo, getTeamComList, getTeamTypeList } from '@/api/associationOfHome';
import LyzButton from '@/components/LyzButton/LyzButton';
import LyzImage from '@/components/LyzImage/LyzImage';
import LyzPagination from '@/components/LyzPagination/LyzPagination';
import { teamComItem, teamTypeItem } from '@/typeScripeMode';
import { FC, useEffect, useState } from 'react';
const ManagementTeam: FC = () => {
    const [managementTeamTypeList, setManagementTeamTypeList] = useState<teamTypeItem[]>([])
    const [active, setActive] = useState(0)
    const [teamData, setTeamData] = useState<teamComItem>()
    const [showInfo, setShowInfo] = useState<boolean>(false)
    const [teamComList, setTeamComList] = useState<teamComItem[]>([])
    const [total, setTotal] = useState<number>(9)
    const [teamId, setTeamId] = useState<string>()
    const getTeamComLists = (e: any) => {
        getTeamComList(e).then(res => {
            setTeamComList(res.data.data.list)
            setTotal(Number(res.data.data.total))
        })
    }
    const getItem = (e: teamTypeItem, i: number) => {
        setActive(i)
        setTeamId(e.teamId)
        setShowInfo(false)
        const data = {
            current: '1',
            pageSize: '25',
            teamId: e?.teamId
        }
        getTeamComLists(data)
    }
    const getteamTypeLists = () => {
        getTeamTypeList().then((res) => {
            setManagementTeamTypeList(res.data.data)
            getItem(res.data.data[0],0)
        })
    }
    const goDetails = (e: teamComItem) => {
        setShowInfo(true)
        getMemberUnitInfo(e.memberId).then(res=>{
            setTeamData(res.data.data)
        })
    }
    const pageChange = (page: any) => {
        const data = {...page,
            teamId: teamId
        }
        getTeamComLists(data)
    }
    useEffect(() => {
        getteamTypeLists()
    }, [])
    return (
        <>
            <div className='management-team-body'>
                <div className='management-team-left-body'>
                    {
                        managementTeamTypeList.map((item, index) => {
                            return <div className={`management-team-left-item  ${index === active ? 'management-team-left-item-active' : null}`} onClick={() => getItem(item, index)}>
                                {item.teamName}
                            </div>
                        })
                    }
                </div>
                {
                    !showInfo ? <div className='management-team-right-body'>
                        {
                            <>
                                <div className='management-team-right-list'>
                                    {
                                        teamComList.map((item) => {
                                            return <div className='management-team-right-item' onClick={()=>{goDetails(item)}}>
                                                <div>
                                                    <div style={{ margin: '0px auto', width: '220px' }}>
                                                        <LyzImage width='220px' url={item?.fileList?.length > 0 ? item.fileList[0].fileUrl : ''}></LyzImage>
                                                    </div>
                                                    {/* <div className='management-team-right-item-title'>{item.memberName}</div> */}
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className='management-team-right-pagination'>
                                    <LyzPagination onChange={pageChange} total={total} pageSize={25}></LyzPagination>
                                </div>
                            </>
                        }
                    </div>
                        : <div style={{width:'100%'}}>
                            <div className='management-team-right-body' >
                                {
                                    teamData?.memberName ? <div style={{fontSize:'22px',textAlign:'center',fontWeight:'bold',paddingBottom:'20px'}}>{teamData?.memberName}</div> : null
                                }
                                <div dangerouslySetInnerHTML={{ __html:teamData?.memberContent || ''}}></div>
                                {
                                    teamData?.memberContact ? <div className='management-team-right-content'>联系人：{teamData?.memberContact}</div> : null
                                }
                                {
                                    teamData?.memberPhone ? <div className='management-team-right-content'>联系方式：{teamData?.memberPhone}</div> : null
                                }
                                
                                <div className='management-team-right-content-button'>
                                <LyzButton onClick={()=>{setShowInfo(false)}} borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
                                </div>
                                
                            </div>
                        </div>
                }
            </div>
        </>
    )
}
export default ManagementTeam