/**
 *@description: 党的建设
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-29 15:51:37
 *@email: 1982338226@qq.com
*/
import imageListData from "@/assets/image";
import HeaderTabs from "@/components/HeaderTabs/HeaderTabs";
import { locatioData } from "@/typeScripeMode";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import EraPioneer from "./modules/eraPioneer";
import LearningWorld from "./modules/learningWorld";
import OrganizationalBuilding from "./modules/organizationalBuilding";
import PartyDynamic from "./modules/partyDynamic";

const PartyBuilding: FC = () => {
	const location = useLocation();    
    const {state} = location as locatioData
    const columns = [{
		title: '组织建设',
		icon: imageListData.zzjs,
		element:  <div className='pad-tb-20'>
            <OrganizationalBuilding />
        </div>
	},{
		title: '党建动态',
		icon: imageListData.djdt,
		element:  <div className='pad-tb-20'>
            <PartyDynamic/>
        </div>
	},{
		title: '学习园地',
		icon: imageListData.xxyd,
		element:  <div className='pad-tb-20'>
            <LearningWorld/>
        </div>
	},{
		title: '时代先锋',
		icon: imageListData.sdxf,
		element:  <div className='pad-tb-20'>
            <EraPioneer/>
        </div>
	}]
	const breadcrumbList = state?.active ? ['党的建设',columns[Number(state?.active)].title] : ['党的建设','组织建设']
	useEffect(() => {
	})
	return (
		<>
            <HeaderTabs columns={columns} breadcrumb={breadcrumbList} bgSrc={imageListData.dj} active={state?.active? state.active : undefined}></HeaderTabs>
		</>
    );
}
export default PartyBuilding