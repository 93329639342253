/**
 *@description: 产业园区
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-09-19 16:05:56
 *@email: 1982338226@qq.com
*/

import { getIndustrialParkInfo, getIndustrialParkPageList } from "@/api/industrialService"
import imageListData from "@/assets/image"
import LyzButton from "@/components/LyzButton/LyzButton"
import LyzImage from "@/components/LyzImage/LyzImage"
import LyzPagination from "@/components/LyzPagination/LyzPagination"
import Tags from "@/components/Tags"
import { IndustrialParkInfoData, resourcesInfoRequestData } from "@/typeScripeMode"
import { Carousel } from "antd"
import { ChangeEvent, FC, useEffect, useState } from "react"
import './indyustrialParks.css'

const IndustrialParks: FC = () => {
    const [list, setList] = useState<IndustrialParkInfoData[]>([])
    const [total, setTotal] = useState(0)
    const [showDetails, setShowDetails] = useState(false)
    const [queryData, setQueryData] = useState<resourcesInfoRequestData>({
        current: '1',
        pageSize: '9',
    })
    const [typeList, serTypeList] = useState(['供水'])
    const getList = async (e: any) => {
        const requestData = { ...queryData, ...e }
        const res = await getIndustrialParkPageList(requestData)
        setList(res.data.data.list)
        setTotal(res.data.data.total)
    }
    const [showDetailsData, setDetailsData] = useState<IndustrialParkInfoData>()
    const getInputValue = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryData({
            ...queryData,
            queryText: e.target.value
        })
    }
    const getQueryList = () => {
        getList({})
    }
    const getDetails = async (e: any) => {
        const res = await getIndustrialParkInfo(e)
        setDetailsData(res.data.data)
        serTypeList(res.data.data.supportingFacilities.split(','))
    }
    const iconList = [
        {
            url: imageListData.gs,
            label: '供水'
        },
        {
            url: imageListData.gd,
            label: '供电'
        },
        {
            url: imageListData.trq,
            label: '天然气'
        },
        {
            url: imageListData.gn,
            label: '供暖'
        },
        {
            url: imageListData.wl,
            label: '网络'
        },
        {
            url: imageListData.zx,
            label: '装修'
        },
        {
            url: imageListData.xf,
            label: '消防'
        },
        {
            url: imageListData.dt,
            label: '电梯'
        },
        {
            url: imageListData.kt,
            label: '空调'
        },
        {
            url: imageListData.ss,
            label: '宿舍'
        },
        {
            url: imageListData.st,
            label: '食堂'
        },
        {
            url: imageListData.cw,
            label: '车位'
        }
    ]
    const goDetails = (e: any) => {
        getDetails({
            id: e.id
        })
        setShowDetails(true)
    }
    const pageChange = (page: any) => {
        getList(page)
    }
    const judgeType = (item: any) => {
        return typeList.indexOf(item.label) > -1
    }
    useEffect(() => {
        getList({})
    }, [])
    return <div className="box-body">
        {
            !showDetails ? <>
                <div className="box-query-body">
                    <div></div>
                    <div className='query-input'>
                        <input className='query-input-text' type="text" onChange={e => getInputValue(e)} />
                        <div className='query-input-button' onClick={getQueryList}>搜 索</div>
                    </div>
                </div>
                <div className="box-content-body">
                    <div className="box-content-left-body">
                        <div className="industrial-parks-content-left-list-body">
                            {
                                list && list.length > 0 && list.map((item) => {
                                    return <div className="industrial-parks-content-left-list-item-body" onClick={() => { goDetails(item) }}>
                                        <LyzImage width="306px" height="210px" url={item.coursesCoverList ? item.coursesCoverList[0].fileUrl : imageListData.companyLogin}></LyzImage>
                                        <div className="content-left-list-item-left-body">
                                            <div className="content-left-list-item-title">{item.parkName}</div>
                                            <div className="list-item-left-subtitle">
                                                <div className="list-item-left-subtitle-title">所在地址: </div>
                                                <div className="list-item-left-subtitle-content">{item?.cityName}-{item.detailedAddress}</div>
                                            </div>
                                            <div className="list-item-left-subtitle">
                                                <div className="list-item-left-subtitle-title">物业类型: </div>
                                                <div className="list-item-left-subtitle-content">{item.propertyType}</div>
                                            </div>
                                            <div className="list-item-left-subtitle">
                                                <div className="list-item-left-subtitle-title">招商热线: </div>
                                                <div className="list-item-left-subtitle-content">{item.merchantsHotline}</div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className='tab-list-pag'>
                            <LyzPagination onChange={pageChange} total={total} pageSize={9}></LyzPagination>
                        </div>
                    </div>
                    <div className="box-content-right-body">
                        <div className="box-content-right-title">产业项目排行榜</div>
                        <div style={{ marginTop: '20px' }}>
                            <LyzImage width="100%" url={imageListData.cyyq1}></LyzImage>
                        </div>
                    </div>
                </div>
            </> : <>
                <div className="industrial-parks-details-body">
                    <div className="industrial-parks-details-top-body">
                        <div className="industrial-parks-details-top-left-body">
                            <Carousel autoplay >
                                {
                                    showDetailsData?.slideshowImgList && showDetailsData.slideshowImgList.length > 0 ? showDetailsData.slideshowImgList.map((item) => {
                                        return <div>
                                            <LyzImage width='600px' height='400px' url={item.fileUrl} />
                                        </div>
                                    }) : <div>
                                        <LyzImage width='600px' height='400px' url={imageListData.companyLogin} />
                                    </div>
                                }
                            </Carousel>
                        </div>
                        <div className="industrial-parks-details-top-right-body">
                            <div className="industrial-parks-details-top-right-title">{showDetailsData?.parkName}</div>
                            <div className="industrial-parks-details-top-right-subtitle">
                                <div className="industrial-parks-details-top-right-subtitle-title">物业类型: </div>
                                <div className="industrial-parks-details-top-right-subtitle-content">
                                    {
                                        showDetailsData?.propertyType && showDetailsData?.propertyType.split(',').map((item) => {
                                            return <span style={{marginLeft:'10px'}}>
                                                <Tags color={'#E02020'}>{item}</Tags>
                                            </span>
                                        })
                                    }</div>
                            </div>
                            <div className="industrial-parks-details-top-right-subtitle">
                                <div className="industrial-parks-details-top-right-subtitle-title">项目地址: </div>
                                <div className="industrial-parks-details-top-right-subtitle-content">{showDetailsData?.detailedAddress}</div>
                            </div>
                            <div className="industrial-parks-details-top-right-subtitle" style={{ marginTop: '20px' }}>
                                <div className="industrial-parks-details-top-right-subtitle-title">招商热线: </div>
                                <div className="industrial-parks-details-top-right-subtitle-content" style={{ color: '#3370FF' }}>{showDetailsData?.merchantsHotline}</div>
                            </div>
                        </div>
                    </div>
                    <div className="industrial-parks-details-content-body">
                        <div className="industrial-parks-details-content-left-body">
                            <div className="industrial-parks-details-content-left-title">基本信息</div>
                            <div className="industrial-parks-details-content-left-subtitle">占地面积: 0000m²</div>
                            <div className="industrial-parks-details-content-left-subtitle">价格: 0000m²</div>
                            <div className="industrial-parks-details-content-left-title" style={{ marginTop: '20px' }}>配套设施</div>
                            <div className="industrial-parks-details-content-flex">
                                {
                                    iconList.map(item => {
                                        return <div className="industrial-parks-details-content-flex-item">
                                            <div className="flexc-jcc">
                                                <img style={{ backgroundColor: judgeType(item) ? '#3370FF' : '#DDDDDD' }} width="30px" height="30px" src={item.url} alt="" />
                                            </div>
                                            <div style={{ marginTop: '10px', textAlign: 'center' }}>{item.label}</div>
                                        </div>
                                    })
                                }
                            </div>
                            <div className="industrial-parks-details-content-left-title" style={{ marginTop: '10px' }}>基本信息</div>
                            <article style={{
                                whiteSpace: 'pre-wrap',
                                marginTop: '30px',
                                fontSize: '16px',
                                overflowY: 'auto'
                            }} dangerouslySetInnerHTML={{
                                __html: showDetailsData?.content || ''
                            }}></article>
                            <div style={{ padding: '40px 0' }} className={`flexr-jsb flex-aic`}>
                                <span></span>
                                <LyzButton onClick={() => { setShowDetails(false); window.scrollTo(0, 0); }} borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
                                <span></span>
                            </div>
                        </div>
                        <div className="industrial-parks-details-content-right-body">
                            <div className="industrial-parks-details-content-left-title">园区推荐</div>
                            <div style={{ marginTop: '20px' }}>
                                <LyzImage width="100%" url={imageListData.cyyq1}></LyzImage>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </div>
}
export default IndustrialParks