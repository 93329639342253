/**
 *@description: 加入我们
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-09-23 15:55:07
 *@email: 1982338226@qq.com
*/
import { joinMemberApply } from "@/api/uplode"
import imageListData from "@/assets/image"
import LyzButton from "@/components/LyzButton/LyzButton"
import LyzImage from "@/components/LyzImage/LyzImage"
import AliyunOSSUpload from "@/components/Upload"
import { handleFile } from "@/utils/upload"
import { Form, Input } from "antd"
import { FC, useState } from "react"
import './joinUs.css'

const JoinUs: FC = () => {
    const [form] = Form.useForm();
    const exportExcel = () => {
		window.location.href= 'https://qunka-test.oss-accelerate.aliyuncs.com/COLLECT/2022-09-23/64d0f162-86f5-487a-9b6d-cd3be79960b4.doc'
	}
    const [show,setShow] = useState(true)
    const onFinish = async () => {
        form.validateFields().then(async validate => {
            const res = await joinMemberApply({fileList:handleFile(validate.fileList)})
            if(res.data.code === 200) setShow(false)
        })
    }
    return <>
    <div>
        <LyzImage url={imageListData.jrwmback}></LyzImage>
        {
            show ? <div style={{padding:'30px 0'}} className={'page_body'}>
                <div className="Join-Us-top">
                    <div className="Join-Us-top-title">会员在线报名申请表</div>
                    <div className='Join-Us-button' onClick={()=>{exportExcel()}}>申请表下载</div>
                </div>
                
                <div className="Join-Us-top-title" style={{marginTop:'20px'}}>会员在线报名提交</div>
                <div style={{width:'500px'}}>
                    <Form form={form} size='large' style={{marginTop:'10px'}} onFinish={onFinish}>
                        <Form.Item label="姓名" name="username">
                            <Input />
                        </Form.Item>
                        <Form.Item label="手机号" name="phone">
                            <Input />
                        </Form.Item>
                        <Form.Item name="fileList">
                            <AliyunOSSUpload  />
                        </Form.Item>
                        <Form.Item> 
                            <div className="Join-Us-submit-botton" onClick={onFinish}>提交</div>
                        </Form.Item>
                    </Form>
                </div>
            </div> : <div className={'page_body'}> 
                <div style={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    marginTop:'30px'
                }}>
                    <LyzImage width="100px" url={imageListData.tjcg}></LyzImage>
                </div>
                <div style={{padding: '70px 0'}} className='flexr-jsb flex-aic'>
					<span></span>
					<LyzButton onClick={()=>{setShow(true)}} borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
					<span></span>
				</div>
            </div>
        }
    </div>
    </>
}
export default JoinUs