import { useLocation } from 'react-router-dom'
import React,{ useEffect, FC, useState } from 'react';
import logoBg from '@/assets/temp/logoBg.png'
import './css/login.css'
import LyzImage from '@/components/LyzImage/LyzImage';
import imageListData from '@/assets/image';
import Register from './modules/register';
import LoginUser from './modules/login';
import RetrievePassword from './modules/retrievePassword';

// import { Outlet } from 'react-router-dom';
const Login: FC = () => {
	const location = useLocation()
	const [loginType,setLoginType] = useState('1')
	useEffect(() => {
		console.log(location, 'props')
	})
	return (
		<>
			<div className='login-body' style={{backgroundImage: `url(${logoBg})`}}>
				<div className='login_box'>
					<div className='login_box-image'>
						<LyzImage url={imageListData.loginBack}/>
					</div>
					<div className='login-right'>
						<div className='login-title'>欢迎来到江苏省广告协会</div>
						{
							loginType === '1' ? 
								<LoginUser setLoginType={setLoginType}/>: loginType === '2' ?
								<Register setLoginType={setLoginType}/> : 
								<RetrievePassword setLoginType={setLoginType}/>
						}
					</div>
				</div>
			</div>
		</>
	);
}
export default Login