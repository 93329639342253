import axios from "axios"
import { Modal } from 'antd';
import { getCookie, removeCookie } from "./cookie"
const { confirm } = Modal;
const baseURL = ''
const version = '/api'
const service = axios.create({
  baseURL: baseURL + version,
  timeout: 30000,
  headers: {}
})

service.interceptors.request.use((config: any) => {
  config.headers.Authorization = getCookie('token')
  const headersTemp = { Authorization: '', "Content-Type": "application/json;chartset=utf-8", }
  headersTemp.Authorization = `${getCookie('token')}`
  config.headers = { ...config.headers,...headersTemp }
  return config
}, error => {
  console.log(error)
  return Promise.reject(error)
})

service.interceptors.response.use(response => {
  return response
}, error => {
  console.log('网络异常，请稍后重试', error.response.status)
  if (error.response.status === 401) {
    removeCookie('token')
    // confirm({
    //   title: '登陆已过期，请重新登陆。',
    //   onOk() {
    //     console.log('OK');
    //   },
    //   onCancel() {
    //     console.log('Cancel');
    //   },
    // })
    alert('登陆失效')
  }
  return Promise.reject(error)

})

export default service