import { useEffect } from 'react';
import styles from './LyzButton.module.css'
const LyzButton = (props: any) => {
	useEffect(() => {
	}, [])
	const toClick = () => {
		if(!props.disabled) props.onClick && props.onClick()
	}
	return (
	 <>
			<div onClick={toClick} style={{
				border: `1px solid ${props.borderColor || 'none'}`,
				backgroundColor:props.bgc || 'rgba(0,0,0,0)',
				width: props.width || '98px',
				height: props.height || '39px',
				lineHeight: props.height || '39px',
				fontSize: props.fontSize || '18px',
				margin: props.margin || '0 9px 0 0',
				color: props.color || '#999999',
				cursor: props.disabled ? 'not-allowed' : 'pointer',
				opacity: props.disabled ? 0.6 : 1,
				...props.style
			}} className={`tac ${styles.button_box}`}>{props.text}</div>
	 </>);
}
export default LyzButton