import { loginRequestData, registerUserRequestData, sendVerCodeRequestData, updateUserLoginPasswordRequestData } from "@/typeScripeMode";
import request from "@/utils/request";
import hasRequest from "@/utils/hasRequest";


// 发送验证码
export function getSendVerCode(data:sendVerCodeRequestData) {
    return request({
        url: '/sms/sendVerCode',
        method: 'post',
        data
    })
}

// 用户注册
export function registerUser(data:registerUserRequestData) {
    return request({
        url: '/homePage/registerUser',
        method: 'post',
        data
    })
}

// 用户登录
export function login(data:loginRequestData) {
    return request({
        url: '/homePage/login',
        method: 'post',
        data
    })
}

// 修改密码
export function updateUserLoginPassword(data:updateUserLoginPasswordRequestData) {
    return hasRequest({
        url: '/homePageAuth/updateUserLoginPassword',
        method: 'post',
        data
    })
}
// 重置密码
export function resetUserLoginPassword(data:updateUserLoginPasswordRequestData) {
    return hasRequest({
        url: '/homePage/resetUserLoginPassword',
        method: 'post',
        data
    })
}
// 获取当前登录人信息
export function getMyInfo() {
    return hasRequest({
        url: '/homePageAuth/getMyInfo',
        method: 'get'
    })
}

// 获取省市区
export function getTreeAreaVO() {
    return hasRequest({
        url: '/common/treeAreaVO',
        method: 'get'
    })
}
