/**
 *@description: 通知公告
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { FC } from 'react';
import Tab from '@/components/Tab';
import { getNewsDetails, getNewsList } from '@/api/news';
import { NewspropsData } from '@/typeScripeMode';

const Notice: FC<NewspropsData> = (props) => {
    const queryData={
        current: '1',
        pageSize: '10',
        parentId:'1',
        status:'1',
        newsCategory: '5'
    }
    const getList = async (e:any) => {
        const requestData = {...queryData,...e}
        const res = await getNewsList(requestData)
        return {
            list: res.data.data.list,
            total: res.data.data.total || 0
        }
    }
    const getDetails = async (e:any) => {
        const res = await getNewsDetails(e)
        return res.data.data
    }
    return (
        <>  
            <Tab request={getList} 
            requestDetails={getDetails} 
            queryProps={{queryData:queryData}} 
            detailsContentProps={{showDatya:{title:'newsTitle',content:'newsContent',time:'updateTime'},detailId:props.id,toPath:'/News'}} 
            contentProps={{isShowContent:true,isShowImage: true,showDatya:{cardTitle:'newsTitle', cardTime:'updateTime',cardContent:'newsRemark', cardId:'newsId',cardFileList:'fileList'},cardType:'3'}}></Tab>
        </>
    );
}
export default Notice