import { forwardRef } from 'react';
import "./Card.css";
import Tag from '../Tags'
import LyzImage from '@/components/LyzImage/LyzImage';
import imageListData from '@/assets/image';
interface Cardprops {
	className?: string;
	isTags?: boolean;	// 是否有标签
	isShowImage?: boolean;
	isShowContent?: boolean;
	cardTitle?: string; // 卡片标题
	cardContent?: string; // 卡片内容
	time?: string; // 时间
	startTime?: string;
	endTime?: string;
	id?: string;
	cardType?: string; // 卡片类型
	lecturerName?: string;
	paymentAmount?: string | number;
	cardSalaryRange?: string; // 薪资
	cardReleaseUnit?: string; // 单位名称	
	applyNumber?: string;
	cardCityName?: string;
	cardGender?:string;
	cardExperience?:string;
	cardEducation?:string;
	tagItem?: {
		type: string;  // 标签类型
		typeName: string; // 标签内容
		typeColor: string; // 标签颜色
	}
	fileList?:FileObject[];
	toInfo?: any;
}
interface FileObject {
    fileUrl: string;
}
const Card = forwardRef((props: Cardprops, ref: any) => {
	const goItemDetails = () => {
		props.toInfo(props.id)
	}
	const getTag = () => {
		return <Tag className='card-tag' color={props?.tagItem?.typeColor}>{props?.tagItem?.typeName}</Tag>
	}
	const getCard = () => {
		return props.cardType === '1' ?
			<>
				<div className='card-body'>
					<div className='card-top'>
						{
							props.isTags ? getTag() : null
						}
						<LyzImage width='295px' height='216px' url={props.fileList ? props?.fileList[0].fileUrl : ''}></LyzImage>
					</div>
					<div className='card-center'>
						{
							props.cardTitle ? <div className='card-title text_nowrap' style={{width:'260px'}}>{props.cardTitle}</div> : null
						}
						<div className='card-note'>
							<div className='card-note-item'>发布时间: {props.time}</div>
						</div>
						<div className='card-button' onClick={()=>{props.toInfo(props.id)}}>点击查看</div>
					</div>
				</div>
			</> : props.cardType === '2' ?
				<>
					<div className='card-body' style={{cursor:'pointer'}} onClick={()=>{props.toInfo(props.id)}}>
						<div className='card-top'>
							{
								props.isTags ? getTag() : null
							}
							<LyzImage width='295px' height='216px' url={props.fileList ? props?.fileList[0].fileUrl : ''}></LyzImage>
						</div>
						<div className='card-center'>
							{
								props.cardTitle ? <div className='card-title text_nowrap'>{props.cardTitle}</div> : null
							}
							<div className='card-conter m-t-5'>
								<div className='card-conter-remark'>火热报名中</div>
								<div className='card-conter-money'>￥{props?.paymentAmount}</div>
							</div>
							<div className='card-conter m-t-18'>
								<div className='card-conter-person'>{props?.lecturerName}老师</div>
								<div className='card-conter-remark'>{props?.applyNumber || 0}人已学</div>
							</div>
						</div>
					</div>
				</> : props.cardType === '3' ?
					<>
						<div className='card-details-body' style={{ width: '100%' }}>
							{
								props.isShowImage ? <div style={{ marginRight: '30px' }}>
									<LyzImage width='260px' height='134px' url={props.fileList ? props?.fileList[0].fileUrl : ''}></LyzImage>
								</div> : null
							}
							<div className='card-details-conter'>
								<div className='font_18 font_bold color_333 card-details-conter-title'>{props.cardTitle}</div>
								{
									props.isShowContent ? <div className='font_16 color_666 text_nowrap_3 card-details-conter-content'>{props.cardContent}</div> : <div>...</div>
								}
								<span className='font_14 color_999 card-details-conter-footer'>发布时间：{props.time}</span>
							</div>
							<div className='tar font_20 font_bold color_3c7fff card-details-button' onClick={goItemDetails}>查看详情</div>
						</div>
					</> : props.cardType === '4' ?
					<>
						<div className='management-team-right-item1' onClick={()=>{props.toInfo(props.id)}}>
							<div>
								<div style={{ margin: '0 auto', width: '220px' }}>
									<LyzImage width='220px' url={props.fileList ? props?.fileList[0].fileUrl : ''}></LyzImage>
								</div>
								{/* <div className='management-team-right-item-title'>{props.cardTitle}</div> */}
							</div>
						</div>
					</> : props.cardType === '5' ?
					<>
						<div className='card-body'>
							<div className='card-top'>
								{
									props.isTags ? getTag() : null
								}
								<LyzImage width='290px' height='216px' url={props.fileList ? props?.fileList[0].fileUrl : ''}></LyzImage>
							</div>
							<div className='card-center'>
								{
									props.cardTitle ? <div className='card-title text_nowrap' style={{width:'260px'}}>{props.cardTitle}</div> : null
								}
								<div className='card-note'>
									{
										props.startTime ? <div className='card-note-item'>开始时间: {props.startTime}</div> : null
									}
									{
										props.endTime ? <div className='card-note-item'>结束时间: {props.endTime}</div> : null
									}
									
									
								</div>
								<div className='card-button' onClick={()=>{props.toInfo(props.id)}}>点击查看</div>
							</div>
						</div>
					</>: props.cardType === '6' ?
					<>
						<div className='card-body-2' onClick={()=>{props.toInfo(props.id)}}>
							<div className='card-body-2-top-body'>
								<div className='card-body-2-top-left-body'>
									<div className='card-body-2-top-left-title text_nowrap'>{props.cardTitle} [{props.cardCityName}]</div>
									<div className='card-top-left-botton-body'>
										{
											props.cardSalaryRange ? <div className='card-top-2-pay'>{props.cardSalaryRange}</div> : null
										}
										{
											props.cardEducation ? <div className='card-tag-2'>{props.cardEducation}</div> : null
										}
										{
											props.cardExperience ? <div className='card-tag-2'>{props.cardExperience}</div> : null
										}
										
										
									</div>
								</div>
								<div className='card-body-2-top-right-body'>
										<LyzImage width='70px' height='70px' url={props.fileList ? props?.fileList[0].fileUrl : imageListData.companyLogin}></LyzImage>
									<div className='card-2-top-right-right-body'>
										<div className='card-body-2-top-left-title text_nowrap_2'>{props.cardReleaseUnit}</div>
									</div>
								</div>
							</div>
						</div>
					</> : props.cardType === '7' ?
					<>
						<div className='card-body-2' onClick={()=>{props.toInfo(props.id)}}>
							<div className='card-body-2-top-body'>
								<div className='card-body-2-top-left-body'>
									<div className='card-body-2-top-left-title text_nowrap'>{props.cardTitle} [{props.cardCityName}]</div>
									<div className='card-top-left-botton-body'>
										{
											props.cardSalaryRange ? <div className='card-top-2-pay'>{props.cardSalaryRange}</div> : null
										}
										{
											props.cardEducation ? <div className='card-tag-2'>{props.cardEducation}</div> : null
										}
										{
											props.cardExperience ? <div className='card-tag-2'>{props.cardExperience}</div> : null
										}
										
										
									</div>
								</div>
								<div className='card-body-2-top-right-body'>
										<LyzImage width='70px' height='70px' url={props.cardGender === '男' ? imageListData.man : imageListData.girl}></LyzImage>
									<div className='card-2-top-right-right-body'>
										<div className='card-body-2-top-left-title text_nowrap_2'>{props.cardReleaseUnit}</div>
									</div>
								</div>
							</div>
						</div>
					</> :props.cardType === '8' ? <>
						<div className='card-body' onClick={()=>{props.toInfo(props.id)}} style={{cursor:'pointer'}}>
							<div className='card-top'>
								<LyzImage width='290px' height='216px' url={props.fileList ? props?.fileList[0].fileUrl : ''}></LyzImage>
							</div>
							<div className='card-center'>
								{
									props.cardTitle ? <div className='card-title text_nowrap' style={{width:'260px'}}>{props.cardTitle}</div> : null
								}
								<div className='card-note'>
									<div className='card-note-item' style={{width:'260px'}}>创作单位: {props.startTime}</div>
								</div>
							</div>
							<div className='card-botton-8'>创作人: {props.cardContent}</div>
						</div>
					</> : props.cardType === '9' ?
					<>
						<div className='card-details-body' style={{ width: '100%' }}>
							{
								props.isShowImage ? <div style={{ marginRight: '30px' }}>
									<LyzImage width='260px' height='134px' url={imageListData.xhzcItem}></LyzImage>
								</div> : null
							}
							<div className='card-details-conter'>
								<div className='font_18 font_bold color_333 card-details-conter-title'>{props.cardTitle}</div>
								{
									props.isShowContent ? <div className='font_16 color_666 text_nowrap_3 card-details-conter-content'>{props.cardContent}</div> : <div>...</div>
								}
								<span className='font_14 color_999 card-details-conter-footer'>发布时间：{props.time}</span>
							</div>
							<div className='tar font_20 font_bold color_3c7fff card-details-button' onClick={goItemDetails}>查看详情</div>
						</div>
					</> : props.cardType === '10' ?
					<>
						<div className='card-body'>
							<div className='card-top'>
								{
									props.isTags ? getTag() : null
								}
								<LyzImage width='295px' height='216px' url={props.fileList ? props?.fileList[0].fileUrl : ''}></LyzImage>
							</div>
							<div className='card-center' style={{maxWidth:'295px'}}>
								{
									props.cardTitle ? <div className='card-title text_nowrap' style={{width:'260px'}}>{props.cardTitle}</div> : null
								}
								<div className='card-note'>
									<div className='card-note-item text_nowrap_2' >{props.cardContent}</div>
								</div>
								<div className='card-button' onClick={()=>{props.toInfo(props.id)}}>点击查看</div>
							</div>
						</div>
					</> : <>
						<div className='card-body'>
							<div className='card-top'>
								{
									props.isTags ? getTag() : null
								}
								<LyzImage width='295px' height='216px' url={props.fileList ? props?.fileList[0].fileUrl : ''}></LyzImage>
							</div>
							<div className='card-center'>
								{
									props.cardTitle ? <div className='card-title text_nowrap'>{props.cardTitle}</div> : null
								}
								<div className='card-note'>
									<div className='card-note-item'>
										<span>申报时间: {props.startTime} - </span>
										<div>{props.endTime}</div>
									</div>
								</div>
								<div className='card-button' onClick={()=>{props.toInfo(props.id)}}>点击查看</div>
							</div>
						</div>
					</>
	}
	return (
		<>
			{getCard()}
		</>
	);
})
export default Card