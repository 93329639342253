/**
 *@description: 信用等级
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { FC } from 'react';
import Tab from '@/components/Tab';
import Inquiry from './module/inQuiry';
import { getQualifiedInfo, getQualifiedList } from '@/api/membershipService';
const CreditRating: FC = () => {
    const professionalQualificationsTypeList = [
        {
            title: '信用等级',
            type: '3',
        }, {
            title: '证书查询',
            type: '4',
            element: <>
                <Inquiry/>
            </>
        }
    ]
    const industryCompetitionTagsList = [
        {
            type: '0',
            typeName:'未发布',
            typeColor:'#FA6400'
        },
        {
            type: '1',
            typeName:'已发布',
            typeColor:'#E02020'
        },
        {
            type: '2',
            typeName:'已结束',
            typeColor:'#666666'
        }
    ]
    const queryData = {
        current: '1',
        pageSize: '10',
        releaseStatus: '1',
        qualifiedColumn: '3'
    }
    const getList = async (e: any) => {
        const requestData = { ...queryData, ...e }
        const res = await getQualifiedList(requestData)
        return {
            list: res.data.data.list,
            total: res.data.data.total || 0
        }
    }
    const getDetails = async (e: any) => {
        const data = {
            qualifiedId:e
        }
        const res = await getQualifiedInfo(data)
        return res.data.data
    }
    return (
        <>
            <Tab request={getList}
                haederProps={{typeList:professionalQualificationsTypeList}}
                requestDetails={getDetails}
                queryProps={{ queryData: queryData,queryTageName:'qualifiedColumn',active:'3'}}
                detailsContentProps={{ 
                    showDatya: { 
                        title: 'qualifiedTitle', 
                        content: 'qualifiedContent', 
                        startTime:'startTime',
                        endTime:'endTime',
                    }, 
                    toPath: '/MembershiMpService' 
                }}
                contentProps={{ 
                    isShowImage: true,
                    showDatya: { 
                        cardTitle: 'qualifiedTitle', 
                        // cardStartTime: 'startTime', 
                        // cardEndTime:'endTime',
                        cardId: 'qualifiedId', 
                        cardFileList: 'coverFileList',
                        cardType:'releaseStatus',
                    }, 
                    isTags:true,cardType:'5',
                    tagsTypeList:industryCompetitionTagsList,
                    calssName:'grid-4'
                }}></Tab>
        </>
    );
}
export default CreditRating