import axios from "axios"
import { Modal } from 'antd';
import { getCookie } from "@/utils/cookie"
const baseURL = ''
const version = '/api'
const { confirm } = Modal;
const service = axios.create({
  baseURL: baseURL + version,
  timeout: 30000,
  headers: {}
})

service.interceptors.request.use((config: any) => {
  const token = getCookie('token')
  if(token) {
    config.headers.Authorization = token
  }
  // const headersTemp = { Authorization: '', "Content-Type": "application/json;chartset=utf-8", }
  // headersTemp.Authorization = 'bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiLmnY7lhYPlo64iLCJzY29wZSI6WyJhbGwiXSwicm9sZXMiOlt7InJvbGVOYW1lIjoi6LaF57qn566h55CG5ZGYIiwicm9sZUlkIjoxfV0sImV4cCI6MTY2MTIzNDQzNCwiYXV0aG9yaXRpZXMiOlsiUk9MRV9BRE1JTiJdLCJqdGkiOiJjYmQyYzU2MS05N2Q2LTQ3YjUtYWIwMC04ZTMxNTMzYTVjMTgiLCJjbGllbnRfaWQiOiJxdW5rYS1hZG1pbiIsImN1cnJlbnRfdXNlciI6eyJlbXBsb3llZU5vIjpudWxsLCJpZCI6NjU5NTczMDQyNzM0NjM3MDU2LCJ1c2VybmFtZSI6IuadjuWFg-WjriIsInBhc3N3b3JkIjoie2JjcnlwdH0kMmEkMTAkVTcvd1FNbEdaRVc4T2YxOHRIaEpGLkxTMDZOVDdtaHhTLkRRNEJBRVpZZWtmSVZ3S1FHY3UiLCJwaG9uZSI6IjE4MDM3NTg0NTAzIiwiZW5hYmxlZCI6dHJ1ZSwiYXBwSWQiOjY2ODMzNjU2ODg1Nzc1NTY0OCwicm9sZSI6eyJkZWxGbGFnIjoiMCIsInJvbGVJZCI6MSwicm9sZU5hbWUiOiLotoXnuqfnrqHnkIblkZgiLCJyb2xlS2V5IjoiUk9MRV9BRE1JTiIsInJvbGVTb3J0IjpudWxsLCJyb2xlVHlwZSI6bnVsbCwiZGF0YVNjb3BlIjoiMSIsInN0YXR1cyI6IjAiLCJyZW1hcmsiOm51bGwsInRlbmFudElkIjpudWxsfSwiZGVwdCI6eyJ2ZXJzaW9uIjo2MjYzNTMwOTk0MjQ2MTIzNTUsImRlbEZsYWciOiIwIiwiZGVwdElkIjo2MjYzNTMwOTkyMjMyODU3NjAsInBhcmVudElkIjo2MjYzNTMwOTkyMjMyODU3NjEsImFuY2VzdG9ycyI6bnVsbCwiZGVwdE5hbWUiOiLljZfkuqznvqTlkpbkv6Hmga_np5HmioAiLCJvcmRlck51bSI6bnVsbCwibGVhZGVyIjpudWxsLCJkZXB0VHlwZSI6IjEiLCJwaG9uZSI6bnVsbCwiZW1haWwiOm51bGwsImlzUGxhdGZvcm0iOm51bGwsInN0YXR1cyI6IjAiLCJwYXJlbnROYW1lIjoi5rGf6IuP5pm65o6o5L-h5oGv5oqA5pyv5pyJ6ZmQ5YWs5Y-4IiwidGVuYW50SWQiOm51bGwsImN1c3RvbUlkIjpudWxsfSwiY2xpZW50SWQiOiJxdW5rYS1hZG1pbiIsInRlbmFudElkIjo4ODg4ODg4OCwidXNlclR5cGUiOiIwIiwiYXV0aG9yaXRpZXMiOlt7ImF1dGhvcml0eSI6IlJPTEVfQURNSU4ifV0sImFjY291bnROb25Mb2NrZWQiOnRydWUsImFjY291bnROb25FeHBpcmVkIjp0cnVlLCJjcmVkZW50aWFsc05vbkV4cGlyZWQiOnRydWV9fQ.K2Oz-1kAgJct6JMSB0cfT4QhFoXBl_EqMSTOcEjvSC_95xavxqGHm4YrnRgx7yyydw6K3oMDtBzAgeopLCvGpwu_KgzrcbEMtsU74reeelk5w6ce2ALuJLG7WtqcIgEepLl8CZd2DlBMOd1ey_KV2Fj4WcbU6uN60N-l5sPnGze2Wjkxy8MRb-saC68loUyQiFw9r6l5I9ywks1W_XjnwuCz-Q2Ad5udCuV9Wlp__o36x6F4juNFnALMfXsq8U_wtJZNqTWHTnPfBaBUVB7wTq57iGSan9FjZV4T06Rd9_5O4STLgNCaZQTrVAzKChFMNjpxKAObYLam3wtnW4HT2Q'
  // config.headers = { ...config.headers,...headersTemp }
  return config
}, error => {
  console.log(error)
  return Promise.reject(error)
})

service.interceptors.response.use(response => {
  return response
}, error => {
  console.log('网络异常，请稍后重试', error.response.status)
  if (error.response.status === 401) {
    // alert('登陆失效')
    confirm({
      title: '登陆已过期，请重新登陆。',
      onOk() {
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    })
  }
  return Promise.reject(error)

})

export default service