import request from "@/utils/request";

// 首页热点资讯
export function getHotNewsList(data?:{newsCategory?: string}) {
    return request({
        url: '/homePage/hotNews',
        method: 'post',
        params:data
    })
}

// 获取轮播
export function getCarouselList(data?:{advType?: string}) {
    return request({
        url: '/homePage/advList',
        method: 'post',
        params:data
    })
}

// 首页-会员单位
export function getMembersUnitList(data:any) {
    return request({
        url: '/homePage/membersUnitList',
        method: 'get',
        params:data
    })
}

// 获取联系我们详细信息
export function getInfo() {
    return request({
        url: '/website/getInfo',
        method: 'get',
    })
}

// 首页-友情链接
export function getLinkList() {
    return request({
        url: '/homePage/linkList',
        method: 'get',
    })
}



