/**
 *@description: 已发布信息
 *@author: 刘宇凡
 *@fileName: postMessage.tsx 
 *@date: 2022-10-09 18:01:27
 *@email: 1982338226@qq.com
*/

import { applyJobInfoPageList, deleteData, needInfoPageList, recruitInfoPageList, resourcesInfoPageList, switchReleaseStatus } from "@/api/myInfo"
import LyzPagination from "@/components/LyzPagination/LyzPagination"
import { message } from "antd"
import { FC, useEffect, useImperativeHandle, useState } from "react"
import moment from "moment";

const PostMessage: FC<any> = (props) => {
    const [active,setActive] = useState(0)
    const [list,setList] = useState<any>()
    const [total,setTotal] = useState<any>()
    const [data,setData] = useState<any>({
        current:1,
        pageSize: 10
    })
    const pageChange = (page: any) =>{
        setData(page)
        getList(active)
    }
    const getList = async (i:any) => {
        if(i === 0){
            const res = await resourcesInfoPageList(data)
            setList(res.data.data.list)
            setTotal(res.data.data.total)
        }else if(i === 1){
            const res = await needInfoPageList(data)
            setList(res.data.data.list)
            setTotal(res.data.data.total)
        }else if(i === 2){
            const res = await recruitInfoPageList(data)
            setList(res.data.data.list)
            setTotal(res.data.data.total)
        }else if(i === 3){
            const res = await applyJobInfoPageList(data)
            setList(res.data.data.list)
            setTotal(res.data.data.total)
        }
    }
    const deleteDatas = async (item:any) => {
        const data = {
            id: item.id,
            serviceCode: item.serviceCode,
            updateVersion: item.updateVersion
        }
        const res = await deleteData(data) 
        if(res.data.code === 200){
            message.success('删除成功')
            getList(active)
        }
    }
    const getStatus = (item:any) => {
        if(item.releaseStatus === 0){
            return '发布中'
        }else if(item.releaseStatus === 1){
            return '已发布'
        }
    }
    const getTimeData = (item:any) => {
        return moment(item.createTime).format('YYYY-MM-DD')
    }
    const getActive = (i:any) => {
        setActive(i)
        getList(i)
    }
    const updataReleaseStatus = async (item:any) => {
        const data =  {
            id: item.id,
            serviceCode: item.serviceCode,
            updateVersion: item.updateVersion
        }
        const res = await switchReleaseStatus(data)
        if(res.data.code === 200){
            getList(active)
        }
    }
    const typeList = [ '资源','需求','招聘','人才']
    useImperativeHandle(props.onRef, () => {
        return {
            func: getActive,
        };
    });
    return <>
        <div className="post-message-type">
            {
                typeList.map((item,index)=>{
                    return <div className={active === index ? 'active' : ''}onClick={()=>{getActive(index)}}>{item}</div>
                })
            }
        </div>
        <div className='excel-body' style={{marginTop:'0'}}>
            <div className='excel-top-body'>
                <div className='excel-top-title'>主题</div>
                <div className='excel-top-title'>类别</div>
                <div className='excel-top-title'>添加时间</div>
                <div className='excel-top-title'>状态</div>
                <div className='excel-top-title1'>编辑</div>
            </div>
            <div className='excel-list-body'>
                {
                    list && list.length > 0 && list.map((item: any) => {
                        return <div className='excel-list-li'>
                            <div className='excel-list-li-item' style={{minHeight:'65px'}}>{active === 0 ? item.resourcesName : active === 1 ? item.title : active === 2 ? item.jobTitle : item.title}</div>
                            <div className='excel-list-li-item' style={{minHeight:'65px'}}>{active === 0 ? '资源' : active === 1 ? '需求' : active === 2 ? '招聘' : '人才'}</div>
                            <div className='excel-list-li-item' style={{minHeight:'65px'}}>{active != null ? getTimeData(item) : ''}</div>
                            <div className='excel-list-li-item' style={{minHeight:'65px', color: item.releaseStatus === 0 ? '#E02020' : '#23C09D'}}>{active === 0 ? getStatus(item) : active === 1 ? getStatus(item) : active === 2 ? getStatus(item) : getStatus(item)}</div>
                            <div className='excel-list-li-item1'>
                                <span className="excel-list-li-item-operation" onClick={()=>{deleteDatas(item)}}>删除</span>
                                <span className="excel-list-li-item-operation-1" onClick={()=>{props.goTo(active,item)}}>编辑</span>
                                <span className="excel-list-li-item-operation-1" onClick={()=>{updataReleaseStatus(item)}}>{item.releaseStatus === 0 ? '发布' : '下架'}</span>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
        <div className='tab-list-pag'>
            <LyzPagination onChange={pageChange} total={total || 0} pageSize={data.pageSize}></LyzPagination>
        </div>
    </>
}
export default PostMessage