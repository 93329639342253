/**
 *@description: 广告资源
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-09-16 16:27:54
 *@email: 1982338226@qq.com
*/
import { getNeedInfo, getNeedInfoPageList, getResourcesInfo, getResourcesInfoPageList,getResourceConfigList } from "@/api/industrialService"
import { getNewsDetails, getNewsList } from "@/api/news"
import imageListData from "@/assets/image"
import LyzButton from "@/components/LyzButton/LyzButton"
import LyzImage from "@/components/LyzImage/LyzImage"
import LyzPagination from "@/components/LyzPagination/LyzPagination"
import QueryItem from "@/components/queryItem"
import { needInfoData, needInfoRequestData } from "@/typeScripeMode"
import { ChangeEvent, FC, useEffect, useState } from "react"
import './resources.css'

const Resources: FC = () => {
    const typeList = [
        {
            value: '1',
            label: '广告资源'
        }, {
            value: '2',
            label: '广告需求'
        }
    ]
    const [queryData, setQueryData] = useState<needInfoRequestData>({
        current: '1',
        pageSize: '9',
    })
    const [active, setActive] = useState('1')
    const [list, setList] = useState<needInfoData[]>([])
    const [total, setTotal] = useState(0)
    const [show, setShow] = useState(false)
    const [showData, setShowData] = useState<needInfoData>()
    const getList = async (e: any) => {
        const requestData = { ...queryData, ...e }
        const res = e.active === '1' ? await getResourcesInfoPageList(requestData) : await getNeedInfoPageList(requestData)
        setList(res.data.data.list)
        setTotal(res.data.data.total)
    }
    const getDetails = async (e: any) => {
        const res = active === '1' ? await getResourcesInfo(e) : await getNeedInfo(e)
        setShowData(res.data.data)
    }
    const [queryList,setQueryList]: any[] = useState([
        {
            title:'资源类型',
            valueType:'select',
            valueTypeList:[{
                label:'全部',
                value:'undefined'
            }],
            dataIndex:'resourcesType'
        },
        {
            title:'热门城市',
            valueType:'select',
            valueTypeList:[
                {
                    label:'全部',
                    value:'undefined'
                },
                {
                    label:'南京',
                    value:'南京'
                },
                {
                    label:'徐州',
                    value:'徐州'
                },
                {
                    label:'连云港',
                    value:'连云港'
                },
                {
                    label:'宿迁',
                    value:'宿迁'
                },
                {
                    label:'淮安',
                    value:'淮安'
                },
                {
                    label:'扬州',
                    value:'扬州'
                },
                {
                    label:'盐城',
                    value:'盐城'
                },
                {
                    label:'南通',
                    value:'南通'
                },
                {
                    label:'泰州',
                    value:'泰州'
                },
                {
                    label:'苏州',
                    value:'苏州'
                },
                {
                    label:'常州',
                    value:'常州'
                },
                {
                    label:'无锡',
                    value:'无锡'
                },
                {
                    label:'镇江',
                    value:'镇江'
                },
            ],
            dataIndex:'releaseCityName'
        },
        
    ])
    const pageChange = (page: any) => {
        const data = {
            active: active,
            ...page
        }
        getList(data)
    }
    const goDetails = (e: any) => {
        const data = {
            id: e.id
        }
        getDetails(data)
        setShow(true)
    }
    const tapBack = () => {
        setShow(false)
        window.scrollTo(0, 0);
    }
    const getInputValue = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryData({
            ...queryData,
            queryText: e.target.value
        })
    }
    const getQueryList = () => {
        getList({ active: active, })
    }
    const getSelectValue = (item: { value: any; }, e: { dataIndex: string | number; }) => {
        const data:any = {active: active,}
        if (item.value !== 'undefined') {
            data[e.dataIndex] = item.value
        } else {
            delete data[e.dataIndex]
        }
        getList(data)
    }
    useEffect(() => {
        getList({ active: '1', })
        getResourceConfigList().then((res) => {
            let list = [{
                label:'全部',
                value:'undefined'
            }]
            let queryListTemp = JSON.parse(JSON.stringify(queryList))
            res.data.data.forEach((item: { resourceType: any; resourceId: any }) => {
                list.push({
                    label:item.resourceType,
                value:item.resourceId,
                })
            })
            queryListTemp[0].valueTypeList = list
            setQueryList(queryListTemp)
        })
    }, [])
    return <>
        <div className="box-body">
            {
                !show ? <>
                    <div className="box-type-body">
                        {
                            typeList.map(item => {
                                return <div onClick={() => { setActive(item.value); getList({ active: item.value }) }} className={`box-type-item-body ${active === item.value ? 'box-type-item-active-body' : undefined}`}>{item.label}</div>
                            })
                        }
                    </div>
                    {/* {
                        active === '1' ? */}
                        <div className='query-body' style={{marginTop:'20px'}}>
                            <div className="box-query-body">
                                <div></div>
                                <div className='query-input'>
                                    <input className='query-input-text' type="text" onChange={e => getInputValue(e)} />
                                    <div className='query-input-button' onClick={getQueryList}>搜 索</div>
                                </div>
                            </div>
                            {
                                queryList?.map((item:any, index: number) => {
                                    if (item.valueType === 'select') {
                                        return <div>
                                            <QueryItem data={item} setData={getSelectValue} className={queryList && index === queryList.length - 1 ? '' : 'query-list-item-active'} />
                                        </div>
                                    }else{
                                        return <></>
                                    }
                                })
                            }
                        </div> 
                        {/* : <div className="box-query-body">
                                <div></div>
                                <div className='query-input'>
                                    <input className='query-input-text' type="text" onChange={e => getInputValue(e)} />
                                    <div className='query-input-button' onClick={getQueryList}>搜 索</div>
                                </div>
                            </div>
                    } */}
                    <div className="box-content-body">
                        <div className="box-content-left-body">
                            <div className="box-content-left-list-body">
                                {
                                    list && list.length > 0 && list.map((item) => {
                                        return <div className="box-content-left-list-item" onClick={() => { goDetails(item) }}>

                                            {
                                                active === '1' ? <div className="box-content-left-list-item-body">
                                                    <LyzImage width="154px" height="138px" url={item.coverPhotoList ? item.coverPhotoList[0].fileUrl : imageListData.companyLogin}></LyzImage>
                                                   <div>
                                                   <div className="list-item-title">{item.resourcesName}</div>
                                                    <div className="list-item-tips">投放城市：{item.releaseCityName}</div>
                                                    <div className="list-item-tips">联系电话：{item.contactPhone}</div>
                                                    <div className="list-item-contnent text_nowrap_content">媒体概况：{item.resourcesDescription}</div>
                                                   </div>
                                                </div> : <div style={{maxWidth:"280px"}}>
                                                    <LyzImage width="280px" height="210px" url={item.thumbnailList ? item.thumbnailList[0].fileUrl : imageListData.companyLogin}></LyzImage>
                                                    <div className="list-item-title">{item.releaseUnit}</div>
                                                    <div>
                                                        <span className="list-item-budget"> ￥{item.budget}</span>
                                                        <span className="list-item-tag-body1">{item.releaseType}</span>
                                                    </div>
                                                    <div className="list-item-bottom">
                                                        {item.browseNumber === null ? 0 : item.browseNumber}人浏览 | {item.participateNumber === null ? 0 : item.participateNumber}人参与</div>
                                                    <div className="list-item-bottom-body1">{item.periodOfValidity}截止</div>
                                                </div>
                                            }

                                        </div>
                                    })
                                }
                            </div>
                            <div className='tab-list-pag'>
                                <LyzPagination onChange={pageChange} total={total} pageSize={9}></LyzPagination>
                            </div>
                        </div>
                        <div className="box-content-right-body">
                            <div className="box-content-right-title">我要发布</div>
                            <div className="my-issue">
                                <div className="post-demand">
                                    <img src={imageListData.fb} alt="" />
                                    <div>发布需求</div>
                                </div>
                                <div className="release-resources">
                                    <img src={imageListData.zy} alt="" />
                                    <div>发布资源</div>
                                </div>
                            </div>
                            <div className="box-content-right-title" style={{ marginTop: '20px' }}>热门推荐</div>
                            <div style={{ marginTop: '20px' }}>
                                <LyzImage width="100%" url={active === '1' ? imageListData.ggzy1 : imageListData.ggzy1}></LyzImage>
                            </div>
                        </div>
                    </div>
                </> : <>
                    {
                        active === '1' ? <div className="box-content-body">
                            <div className="box-content-left-body">
                                <h2 className='tac font_30'>{showData?.releaseUnit}</h2>
                                <div style={{display:"flex",justifyContent:'space-between'}}>
                                    <div></div>
                                    <div> <span className="list-item-tag-body">{showData?.resourcesType}</span></div>
                                </div>
                                <p className='tar font_16' style={{ marginTop: '15px' }}>{showData?.releaseTime}</p>
                                <article style={{
                                    whiteSpace: 'pre-wrap',
                                    marginTop: '30px',
                                    fontSize: '16px',
                                    overflowY: 'auto'
                                }} dangerouslySetInnerHTML={{
                                    __html: showData?.content || ''
                                }}></article>
                                <div className="font_18">发布单位:{showData?.releaseUnit}</div>
                                <div className="font_18" style={{marginTop:'5px'}}>联系方式:{showData?.contactPhone}</div>
                                
                                <div style={{ padding: '40px 0' }} className={`flexr-jsb flex-aic`}>
                                    <span></span>
                                    <LyzButton onClick={tapBack} borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
                                    <span></span>
                                </div>
                            </div>
                            <div className="box-content-right-body">
                                {/* <div className="box-content-right-title">我要发布</div>
                                <div className="my-issue">
                                    <div className="post-demand">
                                        <img src={imageListData.fb} alt="" />
                                        <div>发布需求</div>
                                    </div>
                                    <div className="release-resources">
                                        <img src={imageListData.zy} alt="" />
                                        <div>发布资源</div>
                                    </div>
                                </div> */}
                                <div className="box-content-right-title">热门推荐</div>
                                <div style={{ marginTop: '20px' }}>
                                    <LyzImage width="100%" url={active === '1' ? imageListData.ggzy1 : imageListData.ggzy1}></LyzImage>
                                </div>
                            </div>
                        </div> : <>
                            <div className="details-info-top">
                                <LyzImage width="472px" height="336px" url={showData?.thumbnailList ? showData?.thumbnailList[0].fileUrl : imageListData.companyLogin}></LyzImage>
                                <div className="details-info-top-right">
                                    <div style={{ marginBottom: '10px' }}>
                                        <span className="font_30">{showData?.releaseUnit}</span>
                                        <span className="font_30 details-info-budget">￥{showData?.budget}</span>
                                    </div>
                                    <div className="details-info-content">
                                        {showData?.browseNumber === null ? 0 : showData?.browseNumber}人浏览 | {showData?.participateNumber === null ? 0 : showData?.participateNumber}人参与
                                    </div>
                                    <div className="details-info-content">{showData?.releaseTime} 发布</div>
                                    <div className="details-info-content">联系号码： {showData?.contactPhone}</div>
                                    <div className="details-info-content" style={{ color: '#E02020', marginBottom: '20px' }}>{showData?.periodOfValidity} 截止</div>
                                    <span className="details-info-tag-body">{showData?.releaseType}</span>
                                </div>
                            </div>
                            <div className="box-content-body">
                                <div className="box-content-left-body">
                                    <div className="font_30">需求描述</div>
                                    <article style={{
                                        whiteSpace: 'pre-wrap',
                                        marginTop: '30px',
                                        fontSize: '16px',
                                        overflowY: 'auto'
                                    }} dangerouslySetInnerHTML={{
                                        __html: showData?.content || ''
                                    }}></article>
                                    <div style={{ padding: '40px 0' }} className={`flexr-jsb flex-aic`}>
                                        <span></span>
                                        <LyzButton onClick={tapBack} borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
                                        <span></span>
                                    </div>
                                </div>
                                <div className="box-content-right-body">
                                    {/* <div className="box-content-right-title">我要发布</div>
                                    <div className="my-issue">
                                        <div className="post-demand">
                                            <img src={imageListData.fb} alt="" />
                                            <div>发布需求</div>
                                        </div>
                                        <div className="release-resources">
                                            <img src={imageListData.zy} alt="" />
                                            <div>发布资源</div>
                                        </div>
                                    </div> */}
                                    <div className="box-content-right-title">热门推荐</div>
                                    <div style={{ marginTop: '20px' }}>
                                        <LyzImage width="100%" url={active === '1' ? imageListData.ggzy1 : imageListData.ggzy1}></LyzImage>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </div>
    </>
}
export default Resources
