/**
 *@description: 行业竞赛
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { FC, useState } from 'react';
import Tab from '@/components/Tab';
import { getAwardItemList, getraceInfo, getraceList } from '@/api/membershipService';
import MyExcel from './module/Excel';
import React from 'react';
import LyzPagination from '@/components/LyzPagination/LyzPagination';
import QueryItem from '@/components/queryItem';
import { getPageWorkList, getWorkClassList, getWorkDetails } from '@/api/industrialService';
import List from '@/components/List';
import Card from '@/components/Card';
import LyzButton from '@/components/LyzButton/LyzButton';
import Tags from '@/components/Tags';

interface queryListItem {
    title: string;
    dataIndex: string;
    valueType: string;
    valueTypeList?: queryTypeListItem[];
}
interface queryTypeListItem {
    label: string;
    value: string;
}
const IndustryCompetition: FC = () => {
    const ChildRef = React.createRef<any>();
    const industryCompetitionTypeList = [
        {
            title:'优秀广告作品赛',
            type:'82ca0707-2fa0-4078-9190-0561ddedac93'
        },{
            title:'技能竞赛',
            type:'1d3de2cb-cd29-4154-9575-5a3b3c336429'
        },{
            title:'品牌服务效能赛',
            type:'e6b53c10-8b60-4d86-bf15-445e34a50626'
        },{
            title:'诚信单位竞赛',
            type:'6a64e2cd-854e-480c-954f-b656c30b56de'
        },{
            title:'作品展示',
            type:'5'
        },
    ]
    const industryCompetitionTagsList = [
        {
            type: '1',
            typeName:'进行中',
            typeColor:'#FA6400'
        },
        {
            type: '2',
            typeName:'评审中',
            typeColor:'#E02020'
        },
        {
            type: '3',
            typeName:'已结束',
            typeColor:'#666666'
        }
    ]
    const [queryData,setQueryData]= useState({
            current: '1',
            pageSize: '8',
            categoryId:'82ca0707-2fa0-4078-9190-0561ddedac93',
    })
    const [active,setActive] = useState('0')
    const [show,setShow] = useState(false)
    const [queryList, setQueryList] = useState<queryListItem[]>()
    const [workList, setWorkList] = useState<any[]>()
    const [workTotal, setWorkTotal] = useState(0)
    const [workdetailsData, setWorkDetailsData] = useState<any>()
    const year = new Date().getFullYear()
    const [queryWorkData, setQueryWorkData] = useState<any>({
        current: '1',
        pageSize: '8',
    })
    const getSelectValue = (item: { value: string }, e: { dataIndex: string | number }) => {
        if (item.value !== 'undefined') {
            queryWorkData[e.dataIndex] = item.value
        } else {
            delete queryWorkData[e.dataIndex]
        }
        getWorkList({})
        setQueryWorkData(queryWorkData)
    }
    const getWorkList = async (e: any) => {
        const res = await getPageWorkList({ ...queryWorkData, ...e })
        setWorkList(res.data.data.list)
        setWorkTotal(res.data.data.total)
    }
    const getWorkClassLists = async () => {
        const res = await getWorkClassList({ categoryType: '2' })
        setQueryList([
            {
                title: '类型',
                valueType: 'select',
                valueTypeList: [{
                    label: '全部',
                    value: 'undefined'
                },{
                    label: '优秀广告作品赛',
                    value: '82ca0707-2fa0-4078-9190-0561ddedac93'
                }, {
                    label: '技能竞赛',
                    value: '1d3de2cb-cd29-4154-9575-5a3b3c336429'
                }, {
                    label: '品牌服务效能赛',
                    value: 'e6b53c10-8b60-4d86-bf15-445e34a50626'
                }, {
                    label: '诚信单位竞赛',
                    value: '6a64e2cd-854e-480c-954f-b656c30b56de'
                }, {
                    label: '行业资讯',
                    value: '7736dbab-7fad-42b0-bd37-5a1a5bb98c65'
                }],
                dataIndex: 'worksLevel1'
            },{
                title: '奖项',
                valueType: 'select',
                valueTypeList: [{
                    label: '全部',
                    value: 'undefined'
                }, {
                    label: '特等奖',
                    value: '特等奖'
                }, {
                    label: '金奖',
                    value: '金奖'
                }, {
                    label: '银奖',
                    value: '银奖'
                }, {
                    label: '铜奖',
                    value: '铜奖'
                }, {
                    label: '优秀奖',
                    value: '优秀奖'
                },],
                dataIndex: 'worksAward'
            },
            {
                title: '类型',
                valueType: 'select',
                valueTypeList: [{
                    label: '全部',
                    value: 'undefined'
                }, ...res.data.data],
                dataIndex: 'worksLevel2'
            },
            {
                title: '年份',
                valueType: 'select',
                valueTypeList: [{
                    label: '全部',
                    value: 'undefined'
                }, {
                    label: year.toString(),
                    value: year.toString()
                }, {
                    label: (year - 1).toString(),
                    value: (year - 1).toString()
                }, {
                    label: (year - 2).toString(),
                    value: (year - 2).toString()
                }, {
                    label: (year - 3).toString(),
                    value: (year - 3).toString()
                }, {
                    label: (year - 4).toString(),
                    value: (year - 4).toString()
                }],
                dataIndex: 'worksAwardYear'
            },
        ])

    }
    const getWorkDetail = async (e: any) => {
        const res = await getWorkDetails(e)
        setWorkDetailsData(res.data.data)
        setShow(true)
    }
    const getList = async (e:any) => {
        if(e?.categoryId && e.categoryId === '5'){
            setActive('5')
            getWorkClassLists()
            getWorkList({})
            return {
                list: [],
                total:  0
            }
        }else{
            const requestData = {...queryData,...e}
            setQueryData(requestData)
            const res = await getraceList(requestData)
            return {
                list: res.data.data.list,
                total: res.data.data.total || 0
            }
        }
    }
    const getDetails = async (e:any) => { 
        const data = {
            raceId:e
        }
        const res = await getraceInfo(data)
        return res.data.data
    }
    const [queryExcelData,setQueryExcelData]= useState({
        current: '1',
        pageSize: '10',
    })
    const [list,setList] = useState()
    const getExcelDetails = async (e:any)=>{
        const data = {
            raceId:e
        }
        const requestData = {...queryExcelData,...data}
        setQueryExcelData(requestData)
        const res = await getAwardItemList(requestData)
        setList(res.data.data)
    }
    const getExcelDetailList = async (e:any)=>{
        const res = await getAwardItemList(e)
        setList(res.data.data)
    }
    const onBack = () =>{
        ChildRef.current.onBack()
    }
    return (
        <>  
            {
                active === '5' && !show ?  <>
                <div className="box-type-body" style={{marginTop:'9px'}}>
                            {
                                industryCompetitionTypeList.map(item => {
                                    return <div onClick={() => { setActive(item.type); }} className={`box-type-item-body ${active === item.type ? 'box-type-item-active-body' : undefined}`}>{item.title}</div>
                                })
                            }
                        </div>
                        <div className='query-body' style={{ marginTop: '20px' }}>
                            {
                                queryList?.map((item: any, index: number) => {
                                    return <div>
                                        <QueryItem data={item} setData={getSelectValue} className={queryList && index === queryList.length - 1 ? '' : 'query-list-item-active'} />
                                    </div>
                                })
                            }
                        </div>
                        <List className='grid-4'>
                            {
                                workList && workList.length > 0 && workList.map((item) => {
                                    return <Card
                                        toInfo={getWorkDetail}
                                        cardType={"8"} cardTitle={item.worksTitle}
                                        id={item.worksId}
                                        startTime={item.worksCreativeUnit}
                                        cardContent={item.worksCreator}
                                        fileList={item.photoFileList} />
                                })
                            }
                        </List>
                        <div className='tab-list-pag'>
                            <LyzPagination onChange={getWorkList} total={workTotal} pageSize={8}></LyzPagination>
                        </div>
                    </> : active === '5' && show ? <>
                    <div className="work-details-content-body">
                            <div className="work-details-content-title">{workdetailsData.worksTitle}——{workdetailsData.worksLevel2Name}</div>
                            <Tags color={'#E02020'}>{workdetailsData.worksAward}</Tags>
                        </div>
                        <div className="work-details-content-subtags">创作单位：{workdetailsData.worksCreativeUnit}</div>
                        <div className="work-details-content-subtags">作者：{workdetailsData.worksCreator}</div>
                        <div className="work-details-content-subtags">发布日期：{workdetailsData.worksWinningTime}</div>
                        <article style={{
                            whiteSpace: 'pre-wrap',
                            fontSize: '16px',
                            marginTop: '30PX',
                            overflow: 'auto',
                        }} dangerouslySetInnerHTML={{
                            __html: workdetailsData.worksContent
                        }}></article>
                        {
                            workdetailsData.videoFileList.length> 0 ? <div className="flexc-jcc" style={{marginTop:'20px'}}>
                                <video controls height={300}>
                                    <source src={ workdetailsData.videoFileList[0].fileUrl} type="video/mp4"/>
                                </video>
                                {/* <Player>
                                    <source src={workdetailsData.videoFileList[0].fileUrl} />
                                </Player> */}
                            </div> : null
                        }
                        <div style={{ padding: '40px 0' }} className={`flexr-jsb flex-aic`}>
                            <span></span>
                            <LyzButton onClick={() => { setShow(false); window.scrollTo(0, 0); }} borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
                            <span></span>
                        </div>
                    </> : <>
                    <Tab request={getList} 
                    onRef={ChildRef}
                    requestDetails={getDetails}  
                    pageSize={8}
                    haederProps={{typeList:industryCompetitionTypeList}} 
                    requestExcelDetails={getExcelDetails}
                    ExcelDetails={
                        <MyExcel onBack={onBack} 
                        data={list} 
                        queryExcelData={queryExcelData} 
                        setQueryExcelData={setQueryExcelData} 
                        pageChange={getExcelDetailList}></MyExcel>
                    }
                    detailsContentProps={{
                        showDatya:{
                            title:'raceTitle',
                            content:'raceContent',
                            startTime:'startTime',
                            endTime:'endTime',
                            fileList:'raceFileList',
                            id:'raceId'
                        },
                        contentButtonType:'1',
                        toPath:'/MembershiMpService'
                    }} 
                    queryProps={{queryTageName:'categoryId',active:'82ca0707-2fa0-4078-9190-0561ddedac93'}}
                    contentProps={
                        {
                            isTags:true,cardType:'5',
                            tagsTypeList:industryCompetitionTagsList,
                            showDatya:{
                                cardTitle:'raceTitle', 
                                cardStartTime:'startTime', 
                                cardEndTime:'endTime', 
                                cardId:'raceId',
                                cardType:'raceStatus',
                                cardFileList:'coverFileList'
                            },
                            calssName:'grid-4'
                        }
                    }></Tab>
                </>
            }
        </>
    );
}
export default IndustryCompetition