/**
 *@description: 账号信息
 *@author: 刘宇凡
 *@fileName: accountInformation.tsx 
 *@date: 2022-10-08 14:05:32
 *@email: 1982338226@qq.com
*/
import { FC, useEffect, useState } from "react"
import UploadImage from "@/components/Upload/uploadImage"
import { Cascader, Checkbox, Col, Form, Input, message, Row } from "antd";
import { getMyInfo, getTreeAreaVO } from "@/api/login";
import { updateInfo, updateUserLoginPassword, updateUserPhone } from "@/api/myInfo";
import { handleFile, handleFileList } from "@/utils/upload";
import { useNavigate } from "react-router-dom";
const AccountInformation: FC = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [passwordShow, setPasswordShow] = useState(false)
    const [phoneShow, setPhoneShow] = useState(false)
    const [options, setOption] = useState()
    const { TextArea } = Input;
    const [myInfoData, setMyInfoData] = useState<any>()
    const [cityData, setCityData] = useState<any>()
    const getTreeAreaVOs = async () => {
        const res = await getTreeAreaVO()
        setOption(res.data.data)
    }
    const onChange = (e: any) => {
        setCityData({
            provinceId: e[0],
            cityId: e[1],
            districtId: e[2]
        })
    }
    const onFinish = async () => {
        if (passwordShow) {
            form.validateFields().then(async validate => {
                if (validate.repetitionNewLoginPassword === validate.newLoginPassword) {
                    const data = {
                        loginPassword: validate.loginPassword,
                        newLoginPassword: validate.newLoginPassword
                    }
                    const res = await updateUserLoginPassword(data)
                    if (res.data.code === 200) {
                        message.success('修改成功')
                    }
                } else {
                    message.error('请输入的两次新密码保持一致')
                }
            })
        } else if (phoneShow) {
            form.validateFields().then(async validate => {
                const data = {
                    loginPassword: validate.loginPassword,
                    newUserPhone: validate.newUserPhone
                }
                const res = await updateUserPhone(data)
                if (res.data.code === 200) {
                    message.success('修改成功')
                }
            })
        } else {
            form.validateFields().then(async validate => {
                const data = {
                    headPortrait: handleFile(validate.headPortrait),
                    companyName: validate.companyName,
                    userPhone: validate.userPhone,
                    email: validate.email,
                    provinceId: cityData.provinceId,
                    cityId: cityData.cityId,
                    districtId: cityData.districtId,
                    // profession: validate.profession,
                    companyType: validate.companyType.join(','),
                    introduce: validate.introduce
                }
                const res = await updateInfo(data)
                if (res.data.code === 200) {
                    message.success('修改成功')
                    navigate('/myInfo')
                }
            })
        }

    }
    const getUserInfo = async () => {
        const res = await getMyInfo()
        const data = {
            companyName: res.data.data.companyName,
            userPhone: res.data.data.userPhone,
            email: res.data.data.email,
            cityList: res.data.data.districtId ? [res.data.data.provinceId, res.data.data.cityId, res.data.data.districtId] : [],
            // profession: res.data.data.profession,
            companyType: res.data.data.companyType.split(','),
            introduce: res.data.data.introduce,
            headPortrait: handleFileList(res.data.data.headPortrait)
        }
        setCityData({
            provinceId: res.data.data.provinceId,
            cityId: res.data.data.cityId,
            districtId: res.data.data.districtId
        })
        setMyInfoData(res.data.data)
        form.setFieldsValue(data)
    }
    useEffect(() => {
        getUserInfo()
        getTreeAreaVOs()
    }, [])
    return <>
        {
            passwordShow ? <div>
                <div className="account-information-title">修改密码</div>
                <Form form={form} size='large' style={{ marginTop: '20px' }} onFinish={onFinish}>
                    <Form.Item label='邮箱' name="email" labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label='原密码' name="loginPassword" rules={[{ required: true, message: '请输入原密码!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='新密码' name="newLoginPassword" rules={[{ required: true, message: '请输入新密码!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                        <Input />
                        <span className="account-information-item-conter">密码要求至少包含字母，符号或数字中的两项且长度超过六位。</span>
                    </Form.Item>
                    <Form.Item label='重复新密码' name="repetitionNewLoginPassword" rules={[{ required: true, message: '请输入重复新密码!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                        <Input />
                    </Form.Item>
                    <Row justify="center">
                        <Col>
                            <div className="Join-Us-submit-botton" style={{ marginRight: '30px' }} onClick={() => { setPasswordShow(false); window.scrollTo(700, 0);}}>返回</div>
                        </Col>
                        <Col>
                            <div className="Join-Us-submit-botton" style={{ background: '#3370FF', color: '#fff' }} onClick={onFinish}>提交</div>
                        </Col>
                    </Row>
                </Form>
            </div> : phoneShow ? <div>
                <div className="account-information-title">修改手机号</div>
                <Form form={form} size='large' style={{ marginTop: '20px' }} onFinish={onFinish}>
                    <Form.Item label='已绑定手机号' name="userPhone" labelCol={{ span: 4 }} wrapperCol={{ span: 12 }} colon={false}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label='原密码' name="loginPassword" rules={[{ required: true, message: '请输入原密码!' }]} labelCol={{ span: 4 }} wrapperCol={{ span: 12 }} colon={false}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='新手机号' name="newUserPhone" rules={[{ required: true, message: '请输入新手机号!' }]} labelCol={{ span: 4 }} wrapperCol={{ span: 12 }} colon={false}>
                        <Input />
                    </Form.Item>
                    <Row justify="center">
                        <Col>
                            <div className="Join-Us-submit-botton" style={{ marginRight: '30px' }} onClick={() => { setPhoneShow(false);window.scrollTo(700, 0); }}>返回</div>
                        </Col>
                        <Col>
                            <div className="Join-Us-submit-botton" style={{ background: '#3370FF', color: '#fff' }} onClick={onFinish}>提交</div>
                        </Col>
                    </Row>
                </Form>
            </div> :
                <div>
                    <div className="account-information-title">账号资料</div>
                    <div>
                        <Form form={form} size='large' style={{ marginTop: '20px' }} onFinish={onFinish}>
                            <Row justify="center">
                                <Form.Item name="headPortrait">
                                    <UploadImage></UploadImage>
                                </Form.Item>
                            </Row>
                            <Form.Item label='公司名称' name="companyName" labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item label='手机号' name="userPhone" labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                                <Input disabled />
                            </Form.Item>
                            <Form.Item label='邮箱' name="email" labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                                <Input />
                            </Form.Item>
                            <Form.Item label='所在城市' name="cityList" labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                                <Cascader options={options} onChange={onChange} />
                            </Form.Item>
                            {/* <Form.Item label='职业' name="profession" labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                                <Input />
                            </Form.Item> */}
                            <Form.Item label='公司类型' name="companyType" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} colon={false}>
                                <Checkbox.Group>
                                    <Checkbox value="品牌设计" style={{ lineHeight: '32px' }}>品牌设计</Checkbox>
                                    <Checkbox value="创意编导" style={{ lineHeight: '32px' }}>创意编导</Checkbox>
                                    <Checkbox value="平面" style={{ lineHeight: '32px' }}>平面</Checkbox>
                                    <Checkbox value="动画" style={{ lineHeight: '32px' }}>动画</Checkbox>
                                    <Checkbox value="导演" style={{ lineHeight: '32px' }}>导演</Checkbox>
                                    <Checkbox value="三维" style={{ lineHeight: '32px' }}>三维</Checkbox>
                                    <Checkbox value="美术指导" style={{ lineHeight: '32px' }}>美术指导</Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item label='介绍' name="introduce" labelCol={{ span: 3 }} wrapperCol={{ span: 20 }} colon={false}>
                                <TextArea />
                            </Form.Item>
                            <Row justify="center">
                                <Form.Item>
                                    <div className="Join-Us-submit-botton" style={{ background: '#3370FF', color: '#fff' }} onClick={onFinish}>提交</div>
                                </Form.Item>
                            </Row>
                        </Form>
                    </div>
                    <div className="account-information-title">账号安全</div>
                    <div style={{ paddingTop: '20px' }}>
                        <div className="account-information-item-body">
                            <div className="account-information-item-title">昵称</div>
                            <div style={{ marginLeft: '10px' }}>
                                <span className="account-information-item-conter">{myInfoData?.userName}</span>
                            </div>
                        </div>
                        <div className="account-information-item-body">
                            <div className="account-information-item-title">邮箱</div>
                            <div style={{ marginLeft: '10px' }}>
                                <span className="account-information-item-conter">{myInfoData?.email}</span>
                            </div>
                        </div>
                        <div className="account-information-item-body">
                            <div className="account-information-item-title">手机号</div>
                            <div style={{ marginLeft: '10px' }}>
                                <span className="account-information-item-conter">{myInfoData?.userPhone}</span>
                                <span className="account-information-item-button" onClick={() => { setPhoneShow(true);window.scrollTo(700, 0); }}>修改</span>
                            </div>
                        </div>
                        <div className="account-information-item-body">
                            <div className="account-information-item-title">密码</div>
                            <div style={{ marginLeft: '10px' }}>
                                <span className="account-information-item-conter">密码要求至少包含字母，符号或数字中的两项且长度超过六位。</span>
                                <span className="account-information-item-button" onClick={() => { setPasswordShow(true); window.scrollTo(700, 0); }} >修改</span>
                            </div>
                        </div>
                    </div>
                </div>
        }
    </>
}
export default AccountInformation