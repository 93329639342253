/**
 *@description: 登陆
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { getSendVerCode, login } from '@/api/login';
import { getCookie, removeCookie, setCookie } from '@/utils/cookie';
import { Button, Checkbox, Divider, Form, Input, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
interface RegisterProps {
    setLoginType: any;
}
let timer: string | number | NodeJS.Timeout | undefined
const LoginUser: FC<RegisterProps> = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [userPhone, setUserPhone] = useState('')
    const [time, setTime] = useState(60)//倒计时时间
    const [buttonIsDisabled, setButtonIsDisabled] = useState(true)
    const [btnContent, setBtnContent] = useState('获取验证码')
    const [isLoading, setIsLoading] = useState(false)
    const getPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (buttonIsDisabled) return
        const str = /^1[3|4|5|6|7|8|9]\d{9}$/
        setButtonIsDisabled(true)
        if (str.test(e.target.value)) {
            setUserPhone(e.target.value)
            setButtonIsDisabled(false)
        }
    }
    const getVerCode = async () => {
        const data = {
            userPhone: userPhone,
            serviceCodeEnum: 'USER_REGISTER'
        }
        const res = await getSendVerCode(data)
        if (res.data.code === 200) {
            message.success('发送成功');
            timer = setInterval(() => setTime(pre => pre - 1), 1000)
            setButtonIsDisabled(true)
        } else {
            message.error(res.data.msg);
        }
    }
    const onFinish = async (values: any) => {
        form.validateFields().then(async validate => {
            setIsLoading(true)
            const data = {
                loginPassword: validate.loginPassword,
                userPhone: validate.userPhone,
            }
            const res = await login(data)
            if(validate.remember){
                setCookie('userPhone', validate.userPhone,30);
                setCookie('loginPassword', validate.loginPassword,30);
            }else{
                removeCookie('userPhone');
                removeCookie('loginPassword');
            }
            setIsLoading(false)
            if (res.data.code === 200) {
                setCookie('token',res.data.data.token,30)
                navigate('/')
                message.success('登陆成功');
            } else {
                message.error(res.data.msg);
            }
        })
    };
    const checkRememberPassword  = () =>{
        if (getCookie('userPhone') !== '' && getCookie('loginPassword') !== '') {
            form.setFieldsValue({
                userPhone: getCookie('userPhone'),
                loginPassword: getCookie('loginPassword'),
                remember: true,
            });
        }
    }
    useEffect(() => {
        checkRememberPassword()
        clearInterval(timer)
        return () => clearInterval(timer)
    }, [])
    useEffect(() => {
        if (time > 0 && time < 60) {
            setBtnContent(`${time}s后重发`)
        } else {
            clearInterval(timer)
            setTime(60)
            setButtonIsDisabled(false)
            setBtnContent('获取验证码')
        }
    }, [time])
    return <>
        <Form form={form} className='login-form' size='large' onFinish={onFinish}>
            <Form.Item name="userPhone" rules={[{ required: true, message: '请输入会员注册手机号' }]} >
                <Input placeholder="请输入会员注册手机号" />
            </Form.Item>
            <Form.Item name="loginPassword" rules={[{ required: true, message: '请输入密码' }]}>
                <Input type="password" placeholder="请输入密码" />
            </Form.Item>
            {/* <Form.Item>
                <Row gutter={8}>
                    <Col span={14}>
                        <Form.Item name="password" rules={[{ required: true, message: '请输入验证码' }]} noStyle>
                            <Input type="password" placeholder="请输入验证码" />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Button>获取验证码</Button>
                    </Col>
                </Row>
            </Form.Item> */}
            <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>记住账号密码</Checkbox>
                </Form.Item>
                <Divider type="vertical" />
                <span className='forget-the-password' onClick={() => { props.setLoginType('3') }}>忘记密码？</span>
            </Form.Item>
            <Form.Item>
                <Button type="primary" loading={isLoading} block onClick={onFinish}>
                    登录
                </Button>
            </Form.Item>
        </Form>
        <div>
            <span>还没有账号？请点击</span>
            <span className='text-link' onClick={() => { props.setLoginType('2') }}> 这里 </span>
            <span>立即注册</span>
        </div>
    </>
}
export default LoginUser