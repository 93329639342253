import { CoursePageListRequestData, NewspropsData, qualifiedInfoRequestData, qualifiedListRequestData, RaceCertificateInfoRequestData, raceInfoRequestData, raceListRequestData } from "@/typeScripeMode";
import request from "@/utils/request";


// 专项活动分页列表
export function getSpecialActivityPageList(data:qualifiedListRequestData) {
    return request({
        url: `/homePage/specialActivityPageList`,
        method: 'get',
        params:data
    })
}

// 专项活动详情
export function getSpecialActivityInfo(data:NewspropsData) {
    return request({
        url: `/homePage/getSpecialActivityInfo`,
        method: 'get',
        params:data
    })
}

// 查询从业资质信息列
export function getQualifiedList(data:qualifiedListRequestData) {
    return request({
        url: '/website/qualifiedList',
        method: 'post',
        data
    })
}

// 查询从业资质详情
export function getQualifiedInfo(data:qualifiedInfoRequestData) {
    return request({
        url: `/website/qualifiedInfo`,
        method: 'get',
        params:data
    })
}

//从业资质证书查询
export function getRaceCertificateInfo(data:RaceCertificateInfoRequestData) {
    return request({
        url: '/website/raceCertificateInfo',
        method: 'post',
        data
    })
}

// 查询课程分类
export function getCourseClass() {
    return request({
        url: `/homePage/courseClass`,
        method: 'get'
    })
}

// 查询课程分页列表
export function getCoursePageList(data:CoursePageListRequestData) {
    return request({
        url: `/homePage/coursePageList`,
        method: 'get',
        params:data
    })
}

// 查询课程详情
export function getCourseInfo(data:NewspropsData) {
    return request({
        url: `/homePage/getCourseInfo`,
        method: 'get',
        params:data
    })
}

// 查询行业竞赛列表
export function getraceList(data:raceListRequestData) {
    return request({
        url: '/website/raceList',
        method: 'post',
        data
    })
}

// 查询行业竞赛详情
export function getraceInfo(data:raceInfoRequestData) {
    return request({
        url: '/website/raceInfo',
        method: 'get',
        params:data
    })
}

// 查询获奖名单列表
export function getAwardItemList(data:raceInfoRequestData) {
    return request({
        url: '/website/awardItemList',
        method: 'post',
        data
    })
}



