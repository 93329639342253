import { forwardRef, ReactNode, useEffect, useState} from 'react';
interface queryItemprops {
    children?: ReactNode;
    data:{
        title: string;
        dataIndex: string;
        valueType: string;
        valueTypeList?:ListItem[];
    }
    className?: string;
    setData?: any;
}
interface ListItem {
    label: string;
    value:string;
}
const QueryItem = forwardRef((props: queryItemprops, ref: any) => {
    const [height,setHeight] = useState<number | undefined>()
    const [isShowmore,setIsShowmore]  = useState(false)
    const [isSelect,setIsSelect] = useState(false)
    const [active,setActive] = useState(0)
    useEffect(()=>{
        if(height && height> 49){            
            setIsShowmore(true)
        }
    },[height])
    
    return (
        <>
            <div className={`query-list-item ${props.className}`}>
                <div className='query-list-item-title'>{props.data.title} :</div>
                <div className={isSelect ? 'query-list-item-type-body query-list-item-type-body-is-select' : 'query-list-item-type-body query-list-item-type-body-no-select'} ref={e=>setHeight(e?.scrollHeight)}>
                    {
                        props.data.valueTypeList?.map((item,index)=>{
                            return <div className={`query-list-item-type ${index === active ?'query-list-item-type-active' : null}`} onClick={()=>{setActive(index);props.setData(item,props.data)}}>
                                {item.label}
                            </div>
                        })
                    }
                </div>
                {
                    isShowmore ? 
                    <div className='query-list-item-more' onClick={()=>{setIsSelect(!isSelect)}}>
                        <div>更多</div>
                        <div className={isSelect ? 'query-list-item-more-top' : 'query-list-item-more-bottom'}></div>
                    </div> : null
                }
            </div>
        </>
    );
})
export default QueryItem