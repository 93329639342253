/**
 *@description: 会员服务
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-24 10:03:26
 *@email: 1982338226@qq.com
*/
import { useEffect, FC } from 'react';
import HeaderTabs from '@/components/HeaderTabs/HeaderTabs';
import imageListData from '@/assets/image';
import SpecialCampaign from './modules/specialCampaign';
import CertificationMark from './modules/certificationMark';
import LegalService from './modules/legalService';
import ProfessionalQualifications from './modules/professionalQualifications';
import CorporateDevelopment from './modules/corporateDevelopment';
import IndustryCompetition from './modules/industryCompetition';
import CreditRating from './modules/creditRating';
import Testing from './modules/testing';
import { useLocation } from "react-router-dom";
import { locatioData } from '@/typeScripeMode';
const MembershiMpService: FC = () => {
	const location = useLocation();    
    const {state} = location as locatioData
    const columns = [{
		title: '专项活动',
		icon: imageListData.zxhd,
		element:  <div className='pad-tb-20'>
            <SpecialCampaign />
        </div>
	},{
		title: '证明商标',
		icon: imageListData.zmsb,
		element:  <div className='pad-tb-20'>
            <CertificationMark />
        </div>
	},{
		title: '法律服务',
		icon: imageListData.flfw,
		element:  <div className='pad-tb-20'>
            <LegalService />
        </div>
	},{
		title: '从业资质',
		icon: imageListData.cyzz,
		element:  <div className='pad-tb-20'>
            <ProfessionalQualifications />
        </div>
	},{
		title: '企业发展',
		icon: imageListData.qyfz,
		element:  <div className='pad-tb-20'>
            <CorporateDevelopment />
        </div>
	},{
		title: '行业竞赛',
		icon: imageListData.hyjs,
		element:  <div className='pad-tb-20'>
            <IndustryCompetition/>
        </div>
	},{
		title: '信用等级',
		icon: imageListData.xydj,
		element:  <div className='pad-tb-20'>
            <CreditRating/>
        </div>
	},{
		title: '第三方监测',
		icon: imageListData.zlgl,
		element:  <div className='pad-tb-20'>
            <Testing/>
        </div>
	}]
	useEffect(() => {
	})
	return (
		<>
            <HeaderTabs columns={columns} breadcrumb={['会员服务',columns[state?.active || 0].title]} bgSrc={'https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image/huiyuanfuwu.png'} active={state?.active? state.active : 0}></HeaderTabs>
		</>
    );
}
export default MembershiMpService