import React, { Component } from "react";
import "./testingCommon.css";
import "./testingindex.css";

class Testing extends Component {
  state = {};
  setValue() {}
  render() {
    return (
      <div className="page flex-col">
        <div className="block_14">
          <div className="box_43 flex-col">
            <div className="text-group_8 flex-col">
              <span className="text_70">互联网+闲侠·户外广告检测第三方云平台</span>
              <div className="text-wrapper_23">
                <span className="text_71">被获列入</span>
                <span className="text_72">国家工商总局广告创新项目库</span>
                <span className="paragraph_3">
                  ；<br />获
                </span>
                <span className="text_73">财政部文化产业发展专项资金、江苏省、南京市财政专项</span>
                <span className="text_74">扶持资金</span>
              </div>
              <span className="text_75">联系电话：025-5688&nbsp;7601</span>
            </div>
            <div className="group_13 flex-row justify-between">
              <img
                className="image_4"
                src={
                  "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image1/testingImages/weitu111.png"
                }
              />
              <img
                className="image_5"
                src={
                  "https://jssggxh-prod1.oss-cn-hangzhou.aliyuncs.com/home/image1/testingImages/1c2f09b69c6b3df86c7da0cac35a4738@2x.png"
                }
              />
              <div className="block_11 flex-col" />
            </div>
            <span className="text_76">平台服务包括</span>
            <div className="section_5 flex-col" />
          </div>
          <div className="group_4 flex-col">
            <div className="text-wrapper_31 flex-row">
              <span className="text_77">合作案例</span>
            </div>
            <div className="box_44 flex-row">
              <div className="flex-row">
							<div className="text-wrapper_32 flex-col justify-between">
                <span className="text_78">Client品牌客户</span>
                <span className="text_79">Media媒体</span>
                <span className="text_80">Details详细信息</span>
              </div>
              <div className="block_15 flex-col justify-between">
                <span className="text_81">波司登</span>
                <span className="text_82">楼宇LED</span>
                <div className="text-group_9 flex-col justify-between">
                  <span className="text_83">涉及城市：50个</span>
                  <span className="text_84">频次：每周一次</span>
                  <span className="text_85">共涉及点位：80000个</span>
                </div>
              </div>
							</div>
              <div className="grid_2 flex-row">
                <div className="list-items_1-0 flex-col" />
                <div className="list-items_1-1 flex-col" />
                <div className="list-items_1-2 flex-col" />
                <div className="list-items_1-3 flex-col" />
              </div>
            </div>
            <div className="box_45 flex-row">
              <div className="flex-row">
								<div className="box_46 flex-col justify-between">
									<div className="box_39 flex-col" />
									<div className="box_40 flex-col" />
								</div>
								<div className="box_47 flex-col justify-between">
									<div className="group_9 flex-col" />
									<div className="group_10 flex-col" />
								</div>
							</div>
              <div className="flex-row">
							<div className="text-wrapper_33 flex-col justify-between">
                <span className="text_86">Client品牌客户</span>
                <span className="text_87">Media媒体</span>
                <span className="text_88">Details详细信息</span>
              </div>
              <div className="box_48 flex-col justify-between">
                <span className="text_89">每日优鲜</span>
                <span className="text_90">候车亭灯箱</span>
                <div className="text-group_10 flex-col justify-between">
                  <span className="text_91">检测城市：1个</span>
                  <span className="text_92">频次：每日一次</span>
                  <span className="text_93">共涉及点位：2510个</span>
                </div>
              </div>
							</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Testing;
