const HomeTitle = (props: { tips: string, title: string }) => {
	return (
		<>
			<div className='tac flexr-jsc flex-aic ' style={{
				height: '184px'
			}}>
				<div className='pos_r font_bold flex-fitem' style={{
					fontSize: '56px',
					color: '#E6E6E6'
				}}>
					<div>{props.tips}</div>
					<div className='pos_a font_28 color_3370ff font_500' style={{
						top: '39px',
						left: '50%',
						transform: 'translateX(-50%)'
					}}>{props.title}</div>
				</div>

			</div>
		</>);
}
export default HomeTitle