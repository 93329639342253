import { useEffect, FC } from 'react';
import HeaderTabs from '@/components/HeaderTabs/HeaderTabs';
import PageList from '@/components/PageList/PageList';
import hotIcon from '@/assets/icon/hotIcon.png'
import industryIcon from '@/assets/icon/industryIcon.png'
import warningIcon from '@/assets/icon/warningIcon.png'
import newIcon from '@/assets/icon/newIcon.png'
import noticeIcon from '@/assets/icon/noticeIcon.png'
import { getHotNewsList } from '@/api/home';
const HotPage: FC = () => {
	const getList = () => {
		getHotNewsList().then(res=>{
		})
	}
	const sss = (page: any): Promise<any> => {
		return new Promise((resolve, reject) => {
			resolve({
				data: [{ a: 1 }, { a: 2 }, { a: 3 }, { a: 4 }, { a: 5 }, { a: 6 }, { a: 7 }, { a: 8 }, { a: 9 }, { a: 10 }],
				success: true,
				total: 302
			})
		})
	}
	const columns = [{
		title: '热点资讯',
		icon: hotIcon,
		element: <PageList pageSize={10} request={sss}></PageList>
	}, {
		title: '产业新规',
		icon: industryIcon,
		element: <><div className='font_30' style={{
			height: '300px',
			backgroundColor: 'red'
		}}>
			sdasdasd</div></>
	}, {
		title: '要案警示',
		icon: warningIcon,

		element: <><div className='font_30' style={{
			height: '300px',
			backgroundColor: '#ffccff'
		}}>
			sdasdasd</div></>
	}, {
		title: '协会新闻',
		icon: newIcon,

		element: <><div className='font_30' style={{
			height: '300px',
			backgroundColor: 'pink'
		}}>
			sdasdasd</div></>
	}, {
		title: '通知公告',
		icon: noticeIcon,
		element: <><div className='font_30' style={{
			height: '300px',
			backgroundColor: '#3b3b3b'
		}}>
			sdasdasd</div></>
	}]
	useEffect(() => {
		getList()
	}, [])
	return (
		<>
			<div>
				<HeaderTabs columns={columns} breadcrumb={['热点资讯']} bgSrc='https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwallpaperm.cmcm.com%2F29323cd3b896aa5e76851be852ad4ce5.jpg&refer=http%3A%2F%2Fwallpaperm.cmcm.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1663900593&t=0d5618e3277ef1d6bff2c54376ff59b4'></HeaderTabs>
			</div>
		</>);
}
export default HotPage