/**
 *@description: 人力资源
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { FC, useEffect, useState } from 'react';
import Tab from '@/components/Tab';
import { getApplyJobInfo, getApplyJobInfoPageList, getCityList, getRecruitInfo, getRecruitInfoPageList } from '@/api/industrialService';
import RightList from './modules/rightList';

const HumanResource: FC = () => {
    const [active,setActive] = useState<string>('1')
    const HumanResourceTypeList = [
        {
            title: '找工作',
            type: '1',
        }, {
            title: '找人才',
            type: '2'
        }
    ]
    const [typeList,setTypeList] = useState<any>()
    const [typeJobsList,setTypeJobsList] = useState<any>()
    const getList = async (e:any) => {
        if((e.type !== undefined && e.type === '2') || (e.type === undefined && active === '2')){
            setActive('2')
            const requestData = {...queryData,...e}
            const res = await getApplyJobInfoPageList(requestData)
            return {
                list: res.data.data.list,
                total: res.data.data.total || 0 
            }
        }else{
            setActive('1')
            const requestData = {...queryData,...e}
            const res = await getRecruitInfoPageList(requestData)
            return {
                list: res.data.data.list,
                total: res.data.data.total || 0
            }
        }
        
    }
    const getDetails = async (e:any) => {
        if(active === '1'){
            const data = {
                id:e
            }
            const res = await getRecruitInfo(data)
            return res.data.data
        }else{
            const data = {
                id:e
            }
            const res = await getApplyJobInfo(data)
            return res.data.data
        }
    }
    const queryData={
        current: '1',
        pageSize: '10',
    }
    
    const getCityLists =  () => {
        getCityList({
            provinceId:'320000_jiangsusheng'
        }).then(res=>{
            const list = [
                {
                    title:'职位',
                    valueType:'input',
                    dataIndex:'queryText'
                },
                {
                    title:'工作地点',
                    valueType:'select',
                    valueTypeList:[{
                        label:'全国',
                        value:'undefined'
                    },...res.data.data],
                    dataIndex:'cityId'
                },{
                    title:'职位类型',
                    valueType:'select',
                    valueTypeList:[{
                        label:'全部',
                        value:'undefined'
                    },{
                        label:'全职',
                        value:'全职'
                    },{
                        label:'兼职',
                        value:'兼职'
                    },{
                        label:'实习',
                        value:'实习'
                    },{
                        label:'校园',
                        value:'校园'
                    }],
                    dataIndex:'workType'
                },{
                    title:'工作经验',
                    valueType:'select',
                    valueTypeList:[{
                        label:'全部',
                        value:'undefined'
                    },{
                        label:'应届生',
                        value:'应届生'
                    },{
                        label:'实习生',
                        value:'实习生'
                    },{
                        label:'1年以内',
                        value:'1年以内'
                    },{
                        label:'1-3年',
                        value:'1-3年'
                    },{
                        label:'3-5年',
                        value:'3-5年'
                    },{
                        label:'5-10年',
                        value:'5-10年'
                    },{
                        label:'10年以上',
                        value:'10年以上'
                    }],
                    dataIndex:'workYearLimit'
                },{
                    title:'学历要求',
                    valueType:'select',
                    valueTypeList:[{
                        label:'全部',
                        value:'undefined'
                    },{
                        label:'初中及以下',
                        value:'初中及以下'
                    },{
                        label:'高中',
                        value:'高中'
                    },{
                        label:'中专/中技',
                        value:'中专/中技'
                    },{
                        label:'大专',
                        value:'大专'
                    },{
                        label:'本科',
                        value:'本科'
                    },{
                        label:'硕士',
                        value:'硕士'
                    },{
                        label:'MBA/EMBA',
                        value:'MBA/EMBA'
                    },{
                        label:'博士',
                        value:'博士'
                    }],
                    dataIndex:'minDegree'
                },{
                    title:'薪资待遇',
                    valueType:'select',
                    valueTypeList:[{
                        label:'全部',
                        value:'undefined'
                    },{
                        label:'3K以下',
                        value:'3K以下'
                    },{
                        label:'3K-5K',
                        value:'3K-5K'
                    },{
                        label:'5K-10K',
                        value:'5K-10K'
                    },{
                        label:'10K-20K',
                        value:'10K-20K'
                    },{
                        label:'20K-40K',
                        value:'20K-40K'
                    },{
                        label:'40K-60K',
                        value:'40K-60K'
                    },{
                        label:'60K以上',
                        value:'60K以上'
                    }],
                    dataIndex:'salaryRange'
                }
            ]
            const jobsList = [
                {
                    title:'职位',
                    valueType:'input',
                    dataIndex:'queryText'
                },
                {
                    title:'工作地点',
                    valueType:'select',
                    valueTypeList:[{
                        label:'全国',
                        value:'undefined'
                    },...res.data.data],
                    dataIndex:'cityId'
                },{
                    title:'职位类型',
                    valueType:'select',
                    valueTypeList:[{
                        label:'全部',
                        value:'undefined'
                    },{
                        label:'全职',
                        value:'全职'
                    },{
                        label:'兼职',
                        value:'兼职'
                    },{
                        label:'实习',
                        value:'实习'
                    },{
                        label:'校园',
                        value:'校园'
                    }],
                    dataIndex:'workType'
                },{
                    title:'工作经验',
                    valueType:'select',
                    valueTypeList:[{
                        label:'全部',
                        value:'undefined'
                    },{
                        label:'应届生',
                        value:'应届生'
                    },{
                        label:'实习生',
                        value:'实习生'
                    },{
                        label:'1年以内',
                        value:'1年以内'
                    },{
                        label:'1-3年',
                        value:'1-3年'
                    },{
                        label:'3-5年',
                        value:'3-5年'
                    },{
                        label:'5-10年',
                        value:'5-10年'
                    },{
                        label:'10年以上',
                        value:'10年以上'
                    }],
                    dataIndex:'workYearLimit'
                },{
                    title:'学历要求',
                    valueType:'select',
                    valueTypeList:[{
                        label:'全部',
                        value:'undefined'
                    },{
                        label:'初中及以下',
                        value:'初中及以下'
                    },{
                        label:'高中',
                        value:'高中'
                    },{
                        label:'中专/中技',
                        value:'中专/中技'
                    },{
                        label:'大专',
                        value:'大专'
                    },{
                        label:'本科',
                        value:'本科'
                    },{
                        label:'硕士',
                        value:'硕士'
                    },{
                        label:'MBA/EMBA',
                        value:'MBA/EMBA'
                    },{
                        label:'博士',
                        value:'博士'
                    }],
                    dataIndex:'minDegree'
                },{
                    title:'薪资待遇',
                    valueType:'select',
                    valueTypeList:[{
                        label:'全部',
                        value:'undefined'
                    },{
                        label:'3K以下',
                        value:'3K以下'
                    },{
                        label:'3K-5K',
                        value:'3K-5K'
                    },{
                        label:'5K-10K',
                        value:'5K-10K'
                    },{
                        label:'10K-20K',
                        value:'10K-20K'
                    },{
                        label:'20K-40K',
                        value:'20K-40K'
                    },{
                        label:'40K-60K',
                        value:'40K-60K'
                    },{
                        label:'60K以上',
                        value:'60K以上'
                    }],
                    dataIndex:'salaryRange'
                }
            ]
            setTypeList(list)
            setTypeJobsList(jobsList)
        })
    }
    useEffect(()=>{
        getCityLists()
    },[])
    return (
        <>  
            <Tab request={getList} 
            requestDetails={getDetails} 
            queryProps={{queryData:queryData,active:'1',isShow:true,queryList:active === '1' ? typeList : typeJobsList,queryTageName:'type'}} 
            haederProps={{typeList:HumanResourceTypeList}}
            detailsContentProps={{
                showDatya:{
                    title: '',
                    gender:'gender',
                    content:'content',
                    positionType: active === '1' ? 'positionType':'userName',
                    minDegree: active === '1' ? 'minDegree' : 'education',
                    workYearLimit:active === '1' ? 'workYearLimit' : 'experience',
                    salaryRange:'salaryRange',
                    detailedAddress:'detailedAddress',
                    workWelfare: active === '1' ? 'workWelfare' : 'education',
                    graduateSchool: 'graduateSchool',
                    professional: 'professional',
                    politicalLandscape: 'politicalLandscape',
                    educationExperience: 'educationExperience',
                    selfAssessment: 'selfAssessment',
                    workExperience: 'workExperience',
                    releaseUnit:'releaseUnit'
                },
                detailType:active ==='2'?'4':'3'
            }} 
            contentProps={{
                isShowImage: true,
                showDatya:{
                    cardTitle: active ==='2' ? 'title' : 'positionType',
                    cardCityName:'cityName' ,
                    cardTime:'createTime', 
                    cardId:'id',
                    cardFileList:'companyLogo',
                    cardReleaseUnit:active ==='1' ? 'releaseUnit' : 'userName',
                    cardSalaryRange:'salaryRange',
                    cardGender:'gender',
                    cardExperience:'experience',
                    cardEducation:'education'
                },
                cardType: active === '1' ? '6' : '7',
                listType:'1',
                listRightElement: active === '1' ? <RightList type={'1'}/> : <RightList type={'2'}/>
            }}></Tab>
        </>
    );
}
export default HumanResource