import { AssociationOfHomeRequestData, memberListRequestData, teamComListRequestData } from "@/typeScripeMode";
import request from "@/utils/request";

// 单篇文字信息(协会简介、组织架构、工作机构)
export function getNewsInfo(data:AssociationOfHomeRequestData) {
    return request({
        url: '/website/getNewsInfo',
        method: 'post',
        data
    })
}


// 管理团队类型
export function getTeamTypeList() {
    return request({
        url: '/website/teamTypeList',
        method: 'GET'
    })
}

// 管理团队公司信息
export function getTeamComList(data:teamComListRequestData) {
    return request({
        url: '/website/teamComList',
        method: 'GET',
        params:data
    })
}

// 管理团队个人详细信息
export function getTeamTypeInfo(data:string) {
    return request({
        url: `/website/teamTypeInfo/${data}`,
        method: 'GET',
    })
}

// 会员之窗
export function getMemberUnitList(data:memberListRequestData) {
    return request({
        url: `/website/memberUnitList`,
        method: 'GET',
        params:data
    })
}

// 会员之窗详细信息
export function getMemberUnitInfo(data:string) {
    return request({
        url: `/website/memberUnitInfo/${data}`,
        method: 'GET',
    })
}


