import { useEffect, forwardRef, useState, ChangeEvent, useCallback, useImperativeHandle } from 'react';
import List from '../List/index'
import Card from '../Card/index'
import './Tab.css'
import LyzPagination from '@/components/LyzPagination/LyzPagination';
import PageInfo from '@/components/PageList/PageInfo';
import QueryItem from '../queryItem';
import { useLocation, useNavigate } from 'react-router-dom';
import ItemInfo from '../ItemInfo';
import RecruitmentHandsInfo from '../RecruitmentHandsInfo';
import JobsInfo from '../JobsInfo';
import imageListData from '@/assets/image';
interface Tabprops {
    tabTo?: any;
    haederProps?: {
        typeList?: ListItem[];
    }
    queryProps?: {
        queryTageName?: string;
        isShow?: boolean;
        queryList?: queryListItem[]
        queryData?: any
        active?: string;
    }
    contentProps?: {
        isTags?: boolean;
        tagsTypeList?: tagsListItem[];
        cardType?: string;
        cardDetailsToPath?: string;
        isShowImage?: boolean;
        isShowContent?: boolean;
        listType?: string;
        calssName?: any;
        showDatya?: {
            cardContent?: string;
            cardTitle?: string;
            cardFileList?: string;
            cardTime?: string;
            cardId?: string;
            cardType?: string;
            cardStartTime?: string;
            cardEndTime?: string;
            lecturerName?: string;
            paymentAmount?: string;
            applyNumber?: string;
            cardCityName?: string;
            cardSalaryRange?: string;
            cardReleaseUnit?: string;
            cardGender?: string;
            cardExperience?: string;
            cardEducation?: string;
        }
        listRightElement?: any;
    }
    detailsContentProps?: {
        isDetail?: boolean;
        detailId?: string;
        showDatya?: {
            content?: string;
            title?: string;
            startTime?: string;
            endTime?: string;
            lecturer?: string;
            time?: string;
            contact?: string;
            contactPhone?: string;
            fileList?: any;
            courseMaterials?: string;
            id?: string;
            workYearLimit?: string;
            positionType?: string;
            minDegree?: string;
            releaseUnit?: string;
            workWelfare?: string;
            salaryRange?: string;
            detailedAddress?: string;
            gender?: string;
            graduateSchool?: string;
            professional?: string;
            politicalLandscape?: string;
            educationExperience?: string;
            selfAssessment?: string;
            workExperience?: string;
        }
        toPath?: string;
        detailType?: string;
        contentButtonType?: string;
    }
    footerProps?: {
        pageSize?: string;
    }
    request?: any;
    requestDetails?: any;
    requestExcelDetails?: any;
    ExcelDetails?: any;
    pageSize?: number;
    onRef?: any;
}
interface queryListItem {
    title: string;
    dataIndex: string;
    valueType: string;
    valueTypeList?: queryTypeListItem[];
}
interface queryTypeListItem {
    label: string;
    value: string;
}
interface ListItem {
    title: string;
    type: string;
    element?: any;
}
interface tagsListItem {
    type: string;
    typeName: string;
    typeColor: string;
}
const Tab = forwardRef((props: Tabprops, ref: any) => {
    const [list, setList] = useState([])
    const [total, setTotal] = useState<number>(20)
    let navigate = useNavigate();
    const location = useLocation()
    const [showInfo, setShowInfo] = useState<string>(props?.detailsContentProps?.detailId ? '1' : '2')
    const [active, setActive] = useState(props.queryProps?.active || '1')
    const [data, setData] = useState<any>({})
    const [dataDetails, setDataDetails] = useState<any>({})
    const [element, setElement] = useState()
    const getList = useCallback(async (e: any) => {
        const data = await props.request(e)
        setList(data.list)
        setTotal(data.total)
    }, [props])
    const clickActive = (item: ListItem) => {
        setActive(item.type)
        setElement(item?.element)
        setData({})
        const data: any = {}
        if (props.queryProps?.queryTageName) {
            data[props.queryProps?.queryTageName] = item.type
            getList(data)
        }
    }
    const pageChange = (page: any) => {
        getList({...page,newsCategory:active == '33' ? active : props.queryProps?.queryData.newsCategory})
    }
    const toInfo = useCallback(async (e: any) => {
        const data = await props.requestDetails(e)
        if(props.tabTo) {
            props.tabTo(data,(isTo: any,dataTemp:any) => {
                if(isTo) {
                    setDataDetails(dataTemp || data)
                    setShowInfo('1')
                    window.scrollTo(700, 0);
                } else {
                    setDataDetails({})
                    setShowInfo('1')
                    window.scrollTo(700, 0);
                }
                
            })
        } else {
        setDataDetails(data)
        setShowInfo('1')
        window.scrollTo(700, 0);
        }
        
    }, [props])
    const getInputValue = (e: ChangeEvent<HTMLInputElement>, item: queryListItem) => {
        data[item.dataIndex] = e.target.value
        setData(data)
    }
    const getQueryList = () => {
        if (props.queryProps?.queryTageName) {
            data[props.queryProps?.queryTageName] = active
            getList(data)
        }
    }
    const getSelectValue = (item: { value: any; }, e: { dataIndex: string | number; }) => {
        if (item.value !== 'undefined') {
            data[e.dataIndex] = item.value
        } else {
            delete data[e.dataIndex]
        }
        getList(data)
    }
    const goBack = () => {
        navigate(props.detailsContentProps?.toPath || location.pathname)
        setShowInfo('2')
        window.scrollTo(0, 0);
    }
    const goNext = async (e: any) => {
        props.requestExcelDetails(e)
        setShowInfo('3')
    }
    const goDetails = () => {
        setShowInfo('1')
    }
    useImperativeHandle(props.onRef, () => {
        return {
            onBack: goDetails
        }
    })
    useEffect(() => {
        getList(props.queryProps?.queryData)
        if (showInfo === '1') toInfo(props.detailsContentProps?.detailId)
    }, [])
    return (
        <>
            <div style={{ display: showInfo === '1' || showInfo === '3' ? 'none' : 'block' }}>
                {
                    props.haederProps?.typeList ? <div className='page_body'>
                        <ul className='tab-type-body'>
                            {
                                props.haederProps?.typeList.map((item) => {
                                    return <li className={`tab-type-item ${active === item.type ? 'tab-type-item-active' : null}`} onClick={() => { clickActive(item) }}>{item.title}</li>
                                })
                            }
                        </ul>
                    </div> : null
                }
                {
                    props.queryProps?.isShow ? <div className='query-body'>
                        {
                            props.queryProps?.queryList?.map((item, index) => {
                                if (item.valueType === 'select') {
                                    return <div>
                                        <QueryItem data={item} setData={getSelectValue} className={props.queryProps?.queryList && index === props.queryProps?.queryList.length - 1 ? '' : 'query-list-item-active'} />
                                    </div>
                                } else {
                                    return <div className='query-input-body'>
                                        <div></div>
                                        <div className='query-input'>
                                            <input className='query-input-text' type="text" onChange={e => getInputValue(e, item)} />
                                            <div className='query-input-button' onClick={getQueryList}>搜 索</div>
                                        </div>
                                    </div>
                                }
                            })
                        }
                    </div> : null
                }
                {
                    element ? element :
                        <>
                            {
                                props?.contentProps?.listType === '1' ?
                                    <div className='list-body-1'>
                                        <div className='list-left-body'>
                                            <List className={props.contentProps?.calssName} cardType={props.contentProps?.cardType}>
                                                {
                                                    list && list.length > 0 && list.map((item) => {
                                                        return <Card cardTitle={props.contentProps?.showDatya?.cardTitle ? item[props.contentProps.showDatya.cardTitle] : undefined}
                                                            cardType={props.contentProps?.cardType}
                                                            cardContent={props.contentProps?.showDatya?.cardContent? item[props.contentProps.showDatya.cardContent] : '...'}
                                                            cardGender={props.contentProps?.showDatya?.cardGender ? item[props.contentProps.showDatya.cardGender] : undefined}
                                                            cardCityName={props.contentProps?.showDatya?.cardCityName ? item[props.contentProps.showDatya.cardCityName] : undefined}
                                                            cardExperience={props.contentProps?.showDatya?.cardExperience ? item[props.contentProps.showDatya.cardExperience] : undefined}
                                                            cardEducation={props.contentProps?.showDatya?.cardEducation ? item[props.contentProps.showDatya.cardEducation] : undefined}
                                                            lecturerName={props.contentProps?.showDatya?.lecturerName ? item[props.contentProps.showDatya.lecturerName] : undefined}
                                                            cardSalaryRange={props.contentProps?.showDatya?.cardSalaryRange ? item[props.contentProps.showDatya.cardSalaryRange] : undefined}
                                                            cardReleaseUnit={props.contentProps?.showDatya?.cardReleaseUnit ? item[props.contentProps.showDatya.cardReleaseUnit] : undefined}
                                                            paymentAmount={props.contentProps?.showDatya?.paymentAmount ? item[props.contentProps.showDatya.paymentAmount] : undefined}
                                                            applyNumber={props.contentProps?.showDatya?.applyNumber ? item[props.contentProps.showDatya.applyNumber] : undefined}
                                                            fileList={props.contentProps?.showDatya?.cardFileList ? item[props.contentProps.showDatya.cardFileList] : undefined}
                                                            id={props.contentProps?.showDatya?.cardId ? item[props.contentProps.showDatya.cardId] : undefined}
                                                            isTags={props.contentProps?.isTags}
                                                            time={props.contentProps?.showDatya?.cardTime ? item[props.contentProps.showDatya.cardTime] : undefined}
                                                            startTime={props.contentProps?.showDatya?.cardStartTime ? item[props.contentProps.showDatya.cardStartTime] : undefined}
                                                            endTime={props.contentProps?.showDatya?.cardEndTime ? item[props.contentProps.showDatya.cardEndTime] : undefined}
                                                            isShowImage={props.contentProps?.isShowImage}
                                                            isShowContent={props.contentProps?.isShowContent}
                                                            toInfo={toInfo}
                                                            tagItem={props.contentProps?.tagsTypeList?.filter(e => (props.contentProps?.showDatya?.cardType ? item[props.contentProps.showDatya.cardType] : undefined) == e.type)[0]}></Card>;
                                                    })
                                                }
                                            </List>
                                            <div className='tab-list-pag'>
                                                <LyzPagination onChange={pageChange} total={total} pageSize={props.pageSize || 10}></LyzPagination>
                                            </div>
                                        </div>
                                        <div className='list-right-body'>
                                            {props.contentProps.listRightElement}
                                        </div>
                                    </div> :
                                    <>
                                        <div style={{ background: props.contentProps?.cardType === '4' ? `url(${imageListData.background1})` : '#fff',backgroundSize:'100%'}}>
                                            <List className={props.contentProps?.calssName} cardType={props.contentProps?.cardType}>
                                                {
                                                    list && list.length > 0 && list.map((item) => {
                                                        return <Card cardTitle={props.contentProps?.showDatya?.cardTitle ? item[props.contentProps.showDatya.cardTitle] : undefined}
                                                            cardType={props.contentProps?.cardType}
                                                            cardContent={props.contentProps?.showDatya?.cardContent? item[props.contentProps.showDatya.cardContent] : '...'}
                                                            lecturerName={props.contentProps?.showDatya?.lecturerName ? item[props.contentProps.showDatya.lecturerName] : undefined}
                                                            paymentAmount={props.contentProps?.showDatya?.paymentAmount ? item[props.contentProps.showDatya.paymentAmount] : undefined}
                                                            applyNumber={props.contentProps?.showDatya?.applyNumber ? item[props.contentProps.showDatya.applyNumber] : undefined}
                                                            fileList={props.contentProps?.showDatya?.cardFileList ? item[props.contentProps.showDatya.cardFileList] : undefined}
                                                            id={props.contentProps?.showDatya?.cardId ? item[props.contentProps.showDatya.cardId] : undefined}
                                                            isTags={props.contentProps?.isTags}
                                                            time={props.contentProps?.showDatya?.cardTime ? item[props.contentProps.showDatya.cardTime] : undefined}
                                                            startTime={props.contentProps?.showDatya?.cardStartTime ? item[props.contentProps.showDatya.cardStartTime] : undefined}
                                                            endTime={props.contentProps?.showDatya?.cardEndTime ? item[props.contentProps.showDatya.cardEndTime] : undefined}
                                                            isShowImage={props.contentProps?.isShowImage}
                                                            isShowContent={props.contentProps?.isShowContent}
                                                            toInfo={toInfo}
                                                            tagItem={props.contentProps?.tagsTypeList?.filter(e => (props.contentProps?.showDatya?.cardType ? item[props.contentProps.showDatya.cardType] : undefined) == e.type)[0]}></Card>;
                                                    })
                                                }
                                            </List>
                                        </div>
                                        <div className='tab-list-pag'>
                                            <LyzPagination onChange={pageChange} total={total} pageSize={props.pageSize || 10}></LyzPagination>
                                        </div>
                                    </>
                            }
                        </>
                }
            </div>
            {
                showInfo === '1' && props.detailsContentProps?.detailType === '4' ? <>
                    <JobsInfo onBack={() => goBack()} data={dataDetails} showDatya={props.detailsContentProps?.showDatya}></JobsInfo>
                </> :
                    showInfo === '1' && props.detailsContentProps?.detailType === '3' ? <>
                        <RecruitmentHandsInfo onBack={() => goBack()} data={dataDetails} showDatya={props.detailsContentProps?.showDatya}></RecruitmentHandsInfo>
                    </> :
                        showInfo === '1' && props.detailsContentProps?.detailType === '2' ? <>
                            <ItemInfo onBack={() => goBack()} data={dataDetails} showDatya={props.detailsContentProps?.showDatya}></ItemInfo>
                        </> : showInfo === '1' ? <>
                            <PageInfo onBack={() => goBack()} goNext={goNext} data={dataDetails} showDatya={props.detailsContentProps?.showDatya} contentButtonType={props.detailsContentProps?.contentButtonType}></PageInfo>
                        </> : <></>
            }
            {
                showInfo === '3' ? <>{
                    props.ExcelDetails
                }</> : null
            }
        </>
    );
})
export default Tab