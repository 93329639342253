import { FileNameToKeyVo } from "@/typeScripeMode";
import request from "@/utils/hasRequest";

// 前端上传OSS凭证
export function getOSSWebCredentials() {
    return request({
        url: `/homePageAuth/getOSSWebCredentials`,
        method: 'get'
    })
}

export function getFileKeys(params: FileNameToKeyVo) {
    return request({
        url: `/homePageAuth/buildUploadFileName`,
        method: 'post',
        data:params
    })
}

// 入会申请
export function joinMemberApply(data:any) {
    return request({
        url: `/homePageAuth/joinMemberApply`,
        method: 'post',
        data
    })
}

