/**
 *@description: 人才信息发布
 *@author: 刘宇凡
 *@fileName: personnelnformation.tsx 
 *@date: 2022-10-09 14:59:25
 *@email: 1982338226@qq.com
*/

import { getApplyJobInfo } from "@/api/industrialService";
import { getTreeAreaVO } from "@/api/login";
import { saveApplyJob } from "@/api/myInfo";
import { Radio, Form, Input, message, Row, Select, Cascader, DatePicker } from "antd"
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { FC, useEffect, useImperativeHandle, useState } from "react"

const PersonneInformation: FC<any> = (props) => {
    const [form] = Form.useForm();
    const { Option } = Select;
    const [options, setOption] = useState()
    const [cityData, setCityData] = useState<any>()
    const [detailsData, setDetailsData] = useState<any>()
    useImperativeHandle(props.onRef, () => {
        return {
            func: getDetails,
        };
    });
    const getTreeAreaVOs = async () => {
        const res = await getTreeAreaVO()
        setOption(res.data.data)
    }
    const onChange = (e: any) => {
        setCityData({
            provinceId: e[0],
            cityId: e[1],
            districtId: e[2]
        })
    }
    const disposeTime = (data: moment.MomentInput, type: string | undefined) => {
        return moment(data).format(type)
    }
    const getDetails = async (e: any) => {
        const res = await getApplyJobInfo({ id: e.id })
        const data = {
            title: res.data.data.title,
            userName: res.data.data.userName,
            gender: res.data.data.gender,
            graduateSchool: res.data.data.graduateSchool,
            education: res.data.data.education,
            dateOfBirth: moment(res.data.data.dateOfBirth, 'YYYY-MM-DD'),
            professional: res.data.data.professional,
            educationalSystem: res.data.data.educationalSystem,
            salaryRange: res.data.data.salaryRange,
            politicalLandscape: res.data.data.politicalLandscape,
            contactPhone: res.data.data.contactPhone,
            workExperience: res.data.data.workExperience,
            educationExperience: res.data.data.educationExperience,
            selfAssessment: res.data.data.selfAssessment,
            cityList: [res.data.data.provinceId, res.data.data.cityId, res.data.data.districtId],
            workType: res.data.data.workType
        }
        setDetailsData(res.data.data)
        setCityData({
            provinceId: res.data.data.provinceId,
            cityId: res.data.data.cityId,
            districtId: res.data.data.districtId
        })
        form.setFieldsValue(data);
    }
    const onFinish = () => {
        form.validateFields().then(async validate => {
            const data = {
                title: validate.title,
                userName: validate.userName,
                gender: validate.gender,
                graduateSchool: validate.graduateSchool,
                education: validate.education,
                dateOfBirth: disposeTime(form.getFieldValue('dateOfBirth'), 'YYYY-MM-DD'),
                professional: validate.professional,
                educationalSystem: validate.educationalSystem,
                salaryRange: validate.salaryRange,
                politicalLandscape: validate.politicalLandscape,
                contactPhone: validate.contactPhone,
                workExperience: validate.workExperience,
                educationExperience: validate.educationExperience,
                selfAssessment: validate.selfAssessment,
                provinceId: cityData.provinceId,
                cityId: cityData.cityId,
                districtId: cityData.districtId,
                workType: validate.workType,
                id: detailsData && detailsData?.id ? detailsData.id : undefined,
                updateVersion: detailsData && detailsData?.updateVersion ? detailsData.updateVersion : undefined,
            }
            const res = await saveApplyJob(data)
            if (res.data.code === 200) {
                message.success('提交成功')
                form.resetFields()
                if(detailsData?.id){
                    props.setActive(3)
                }
            }
        })
    }
    useEffect(() => {
        getTreeAreaVOs()
    }, [])
    return <>
        <div className="account-information-title">人才信息</div>
        <Form form={form} size='large' style={{ marginTop: '20px' }} onFinish={onFinish}>
            <Form.Item label='标题' name="title" rules={[{ required: true, message: '请输入标题!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='姓名' name="userName" rules={[{ required: true, message: '请输入姓名!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='性别' name="gender" labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Radio.Group>
                    <Radio value="男" style={{ lineHeight: '32px' }}>男</Radio>
                    <Radio value="女" style={{ lineHeight: '32px' }}>女</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label='出生日期' name="dateOfBirth" rules={[{ required: true, message: '请选择出生日期!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <DatePicker />
            </Form.Item>
            <Form.Item label='工作类型' name="workType" rules={[{ required: true, message: '请选择工作类型!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Radio.Group>
                    <Radio value="全职" style={{ lineHeight: '32px' }}>全职</Radio>
                    <Radio value="兼职" style={{ lineHeight: '32px' }}>兼职</Radio>
                    <Radio value="实习" style={{ lineHeight: '32px' }}>实习</Radio>
                    <Radio value="校园" style={{ lineHeight: '32px' }}>校园</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label='最高学历' name="education" rules={[{ required: true, message: '请选择最高学历!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Select>
                    <Option value="博士">博士</Option>
                    <Option value="MBA/EMBA">MBA/EMBA</Option>
                    <Option value="硕士">硕士</Option>
                    <Option value="本科">本科</Option>
                    <Option value="大专">大专</Option>
                    <Option value="中专/中技">中专/中技</Option>
                    <Option value="高中">高中</Option>
                    <Option value="初中及以下">初中及以下</Option>
                </Select>
            </Form.Item>
            <Form.Item label='毕业院校' name="graduateSchool" rules={[{ required: true, message: '请输入毕业院校!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='专业' name="professional" rules={[{ required: true, message: '请输入专业!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='学制' name="educationalSystem" rules={[{ required: true, message: '请输入学制!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='政治面貌' name="politicalLandscape" rules={[{ required: true, message: '请输入政治面貌!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='联系电话' name="contactPhone" rules={[{ required: true, message: '请输入联系电话!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='工作地点' name="cityList" rules={[{ required: true, message: '请选择工作地点!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Cascader options={options} onChange={onChange} />
            </Form.Item>
            <Form.Item label='工作经验' name="workExperience" rules={[{ required: true, message: '请选择工作经验!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Select>
                    <Option value="应届生">应届生</Option>
                    <Option value="实习生">实习生</Option>
                    <Option value="1年以内">1年以内</Option>
                    <Option value="1-3年">1-3年</Option>
                    <Option value="3-5年">3-5年</Option>
                    <Option value="5-10年">5-10年</Option>
                    <Option value="10年以上">10年以上</Option>
                </Select>
            </Form.Item>
            <Form.Item label='期望薪资' name="salaryRange" rules={[{ required: true, message: '请选择期望薪资!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Select>
                    <Option value="3K以下">3K以下</Option>
                    <Option value="3K-5K">3K-5K</Option>
                    <Option value="5K-10K">5K-10K</Option>
                    <Option value="10K-20K">10K-20K</Option>
                    <Option value="20K-40K">20K-40K</Option>
                    <Option value="40K-60K">40K-60K</Option>
                    <Option value="60K以上">60K以上</Option>
                </Select>
            </Form.Item>
            <Form.Item label='工作经历' name="selfAssessment" labelCol={{ span: 3 }} wrapperCol={{ span: 20 }} colon={false}>
                <TextArea />
            </Form.Item>
            <Form.Item label='教育经历' name="educationExperience" labelCol={{ span: 3 }} wrapperCol={{ span: 20 }} colon={false}>
                <TextArea />
            </Form.Item>
            <Row justify="center">
                <Form.Item>
                    <div className="Join-Us-submit-botton" style={{ background: '#3370FF', color: '#fff' }} onClick={onFinish}>提交</div>
                </Form.Item>
            </Row>
        </Form>
    </>
}
export default PersonneInformation