/**
 *@description: 发布招聘信息
 *@author: 刘宇凡
 *@fileName: postJob.tsx 
 *@date: 2022-10-09 11:29:48
 *@email: 1982338226@qq.com
*/
import { getRecruitInfo } from "@/api/industrialService";
import { getTreeAreaVO } from "@/api/login";
import { saveRecruit } from "@/api/myInfo";
import { Radio, Form, Input, message, Row, Select, Cascader, Checkbox } from "antd"
import TextArea from "antd/lib/input/TextArea";
import { FC, useEffect, useImperativeHandle, useState } from "react"

const PostJob: FC<any> = (props) => {
    const [form] = Form.useForm();
    const { Option } = Select;
    const [options, setOption] = useState()
    const [cityData, setCityData] = useState<any>()
    const getTreeAreaVOs = async () => {
        const res = await getTreeAreaVO()
        setOption(res.data.data)
    }
    const onChange = (e: any) => {
        setCityData({
            provinceId: e[0],
            cityId: e[1],
            districtId: e[2]
        })
    }
    const [detailsData, setDetailsData] = useState<any>()
    useImperativeHandle(props.onRef, () => {
        return {
            func: getDetails,
        };
    });
    const getDetails = async (e: any) => {
        const res = await getRecruitInfo({ id: e.id })
        const data = {
            jobTitle: res.data.data.jobTitle,
            workType: res.data.data.workType,
            cityList:[res.data.data.provinceId,res.data.data.cityId,res.data.data.districtId],
            detailedAddress: res.data.data.detailedAddress,
            salaryRange: res.data.data.salaryRange,
            minDegree: res.data.data.minDegree,
            workYearLimit: res.data.data.workYearLimit,
            positionType: res.data.data.positionType,
            workWelfare: res.data.data.workWelfare.split(','),
            releaseUnit: res.data.data.releaseUnit,
            recruitNumber: res.data.data.recruitNumber,
            inductionDepartment: res.data.data.inductionDepartment,
            content: res.data.data.content,
            contactPhone: res.data.data.contactPhone
        }
        setDetailsData(res.data.data)
        setCityData({
            provinceId: res.data.data.provinceId,
            cityId: res.data.data.cityId,
            districtId: res.data.data.districtId
        })
        form.setFieldsValue(data);
    }
    const onFinish = () => {
        form.validateFields().then(async validate => {
            const data = {
                jobTitle: validate.jobTitle,
                workType: validate.workType,
                provinceId: cityData.provinceId,
                cityId: cityData.cityId,
                districtId: cityData.districtId,
                detailedAddress: validate.detailedAddress,
                salaryRange: validate.salaryRange,
                minDegree: validate.minDegree,
                workYearLimit: validate.workYearLimit,
                positionType: validate.positionType,
                workWelfare: validate.workWelfare.join(','),
                releaseUnit: validate.releaseUnit,
                recruitNumber: validate.recruitNumber,
                inductionDepartment: validate.inductionDepartment,
                content: validate.content,
                contactPhone: validate.contactPhone,
                id: detailsData && detailsData?.id ? detailsData.id : undefined,
                updateVersion: detailsData && detailsData?.updateVersion ? detailsData.updateVersion : undefined,
            }
            const res = await saveRecruit(data)
            if (res.data.code === 200) {
                message.success('提交成功')
                form.resetFields()
                if(detailsData?.id){
                    props.setActive(2)
                }
            }
        })
    }
    useEffect(() => {
        getTreeAreaVOs()
    }, [])
    return <>
        <div className="account-information-title">发布需求</div>
        <Form form={form} size='large' style={{ marginTop: '20px' }} onFinish={onFinish}>
            <Form.Item label='职位名称' name="jobTitle" rules={[{ required: true, message: '请输入职位名称!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='职位类型' name="positionType" rules={[{ required: true, message: '请输入职位类型!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='工作类型' name="workType" rules={[{ required: true, message: '请选择工作类型!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Radio.Group>
                    <Radio value="全职" style={{ lineHeight: '32px' }}>全职</Radio>
                    <Radio value="兼职" style={{ lineHeight: '32px' }}>兼职</Radio>
                    <Radio value="实习" style={{ lineHeight: '32px' }}>实习</Radio>
                    <Radio value="校园" style={{ lineHeight: '32px' }}>校园</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label='工作城市' name="cityList" rules={[{ required: true, message: '请选择工作城市!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Cascader options={options} onChange={onChange} />
            </Form.Item>
            <Form.Item label='薪资范围' name="salaryRange" rules={[{ required: true, message: '请选择薪资范围!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Select>
                    <Option value="3K以下">3K以下</Option>
                    <Option value="3K-5K">3K-5K</Option>
                    <Option value="5K-10K">5K-10K</Option>
                    <Option value="10K-20K">10K-20K</Option>
                    <Option value="20K-40K">20K-40K</Option>
                    <Option value="40K-60K">40K-60K</Option>
                    <Option value="60K以上">60K以上</Option>
                </Select>
            </Form.Item>
            <Form.Item label='最低学历' name="minDegree" rules={[{ required: true, message: '请选择最低学历!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Select>
                    <Option value="博士">博士</Option>
                    <Option value="MBA/EMBA">MBA/EMBA</Option>
                    <Option value="硕士">硕士</Option>
                    <Option value="本科">本科</Option>
                    <Option value="大专">大专</Option>
                    <Option value="中专/中技">中专/中技</Option>
                    <Option value="高中">高中</Option>
                    <Option value="初中及以下">初中及以下</Option>
                </Select>
            </Form.Item>
            <Form.Item label='工作年限' name="workYearLimit" rules={[{ required: true, message: '请选择工作年限!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Select>
                    <Option value="应届生">应届生</Option>
                    <Option value="实习生">实习生</Option>
                    <Option value="1年以内">1年以内</Option>
                    <Option value="1-3年">1-3年</Option>
                    <Option value="3-5年">3-5年</Option>
                    <Option value="5-10年">5-10年</Option>
                    <Option value="10年以上">10年以上</Option>
                </Select>
            </Form.Item>
            <Form.Item label='需求描述' name="content" labelCol={{ span: 3 }} wrapperCol={{ span: 20 }} colon={false}>
                <TextArea />
            </Form.Item>
            <Form.Item label='工作福利' name="workWelfare" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} colon={false}>
                <Checkbox.Group>
                    <Checkbox value="五险一金" style={{ lineHeight: '32px' }}>五险一金</Checkbox>
                    <Checkbox value="年终奖" style={{ lineHeight: '32px' }}>年终奖</Checkbox>
                    <Checkbox value="交通补助" style={{ lineHeight: '32px' }}>交通补助</Checkbox>
                    <Checkbox value="年底双薪" style={{ lineHeight: '32px' }}>年底双薪</Checkbox>
                    <Checkbox value="绩效奖金" style={{ lineHeight: '32px' }}>绩效奖金</Checkbox>
                    <Checkbox value="股票期权" style={{ lineHeight: '32px' }}>股票期权</Checkbox>
                    <Checkbox value="加班补贴" style={{ lineHeight: '32px' }}>加班补贴</Checkbox>
                    <Checkbox value="全勤奖励" style={{ lineHeight: '32px' }}>全勤奖励</Checkbox>
                </Checkbox.Group>
            </Form.Item>
            <Form.Item label='招聘人数' name="recruitNumber" labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='入职部门' name="inductionDepartment" labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='公司地址' name="detailedAddress" labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='联系电话' name="contactPhone" labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Row justify="center">
                <Form.Item>
                    <div className="Join-Us-submit-botton" style={{ background: '#3370FF', color: '#fff' }} onClick={onFinish}>提交</div>
                </Form.Item>
            </Row>
        </Form>
    </>
}
export default PostJob