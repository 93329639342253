import { useEffect, FC } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
const Layout: FC = () => {
	useEffect(() => {
	}, [])
	return (
		<>
			<Header />
			{/* <div style={{
				'backgroundColor': 'pink',
				"width": "1260px",
				"minHeight": '100vh',
				"margin": '0 auto'
			}}> */}
				<Outlet />
			{/* </div> */}
			<Footer />
		</>);
}
export default Layout