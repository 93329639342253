import { needInfoRequestData, NewspropsData, raceInfoRequestData, recruitInfoRequestData, resourcesInfoRequestData, WorkRequestData } from "@/typeScripeMode";
import request from "@/utils/request";


// 招聘分页列表
export function getRecruitInfoPageList(data: recruitInfoRequestData) {
    return request({
        url: `/homePage/recruitInfoPageList`,
        method: 'get',
        params:data
    })
}

// 获取市列表
export function getCityList(data: {
    provinceId?:string
}) {
    return request({
        url: `/common/treeAreaCityVO`,
        method: 'get',
        params:data
    })
}

// 求职分页列表
export function getApplyJobInfoPageList(data: recruitInfoRequestData) {
    return request({
        url: `/homePage/applyJobInfoPageList`,
        method: 'get',
        params:data
    })
}


// 招聘详情
export function getRecruitInfo(data: NewspropsData) {
    return request({
        url: `/homePage/getRecruitInfo`,
        method: 'get',
        params:data
    })
}

// 求职详情
export function getApplyJobInfo(data: NewspropsData) {
    return request({
        url: `/homePage/getApplyJobInfo`,
        method: 'get',
        params:data
    })
}

// 广告需求分页列表
export function getNeedInfoPageList(data: needInfoRequestData) {
    return request({
        url: `/homePage/needInfoPageList`,
        method: 'get',
        params:data
    })
}

// 广告资源分页列表
export function getResourcesInfoPageList(data: resourcesInfoRequestData) {
    return request({
        url: `/homePage/resourcesInfoPageList`,
        method: 'get',
        params:data
    })
}

// 广告需求详情
export function getNeedInfo(data: NewspropsData) {
    return request({
        url: `/homePage/getNeedInfo`,
        method: 'get',
        params:data
    })
}

// 广告资源详情
export function getResourcesInfo(data: NewspropsData) {
    return request({
        url: `/homePage/getResourcesInfo`,
        method: 'get',
        params:data
    })
}


// 产业园区分页列表
export function getIndustrialParkPageList(data: NewspropsData) {
    return request({
        url: `/homePage/industrialParkPageList`,
        method: 'get',
        params:data
    })
}

// 产业园区分页列表
export function getIndustrialParkInfo(data: NewspropsData) {
    return request({
        url: `/homePage/getIndustrialParkInfo`,
        method: 'get',
        params:data
    })
}

// 查询所有获奖名单列表（根据行业竞赛栏目）
export function getAwardList(data: raceInfoRequestData) {
    return request({
        url: '/website/awardItemListByType',
        method: 'post',
        data
    })
}

// 作品分类
export function getWorkClassList(data: raceInfoRequestData) {
    return request({
        url: '/website/getWorkClassList',
        method: 'post',
        params:data
    })
}

// 作品展示列表
export function getPageWorkList(data: WorkRequestData) {
    return request({
        url: `/website/pageWorkList`,
        method: 'get',
        params:data
    })
}

// 作品详情
export function getWorkDetails(id:string) {
    return request({
        url: `/website/getWork/${id}`,
        method: 'get'
    })
}

// 获取文件转图片的图片列表
export function getImgList(data:{relationId:string}) {
    return request({
        url: `/homePage/conversionImg/getImgList`,
        method: 'get',
        params:data
    })
}

// 查询启用状态下的资源配置信息
export function getResourceConfigList() {
    return request({
        url: '/website/getResourceConfigList',
        method: 'get'
    })
}
