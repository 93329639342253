/**
 *@description: 联系我们
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-09-24 10:57:07
 *@email: 1982338226@qq.com
*/
import { getInfo } from "@/api/home"
import imageListData from "@/assets/image"
import LyzImage from "@/components/LyzImage/LyzImage"
import { FC, useEffect, useState } from "react"
import './contactUs.css'
const ContactUs: FC = () => {
    const [data,setData] = useState<any>()
    const getData = async () => {
        const res = await getInfo()
        setData(res.data.data)
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        <>
            <LyzImage url={imageListData.dangdejianshe}></LyzImage>
            <div style={{padding:'30px 0 100px 0'}} className={'page_body'}>
                <div className="contact-us-title">联系我们</div>
                <div className="contact-us-item">
                    <div className="contact-us-item-title">联系人：</div>
                    <div className="contact-us-item-content">{data?.contactName}</div>
                </div>
                <div className="contact-us-item">
                    <div className="contact-us-item-title">联系电话：</div>
                    <div className="contact-us-item-content">{data?.contactPhone}</div>
                </div>
                <div className="contact-us-item">
                    <div className="contact-us-item-title">电子邮箱：</div>
                    <div className="contact-us-item-content">{data?.contactEmail}</div>
                </div>
                <div className="contact-us-item">
                    <div className="contact-us-item-title">微信客服：</div>
                    <div className="contact-us-item-content">{data?.contactCustomer}</div>
                </div>
                <div className="contact-us-item">
                    <div className="contact-us-item-title">地址：</div>
                    <div className="contact-us-item-content">{data?.contactAddr}</div>
                </div>
                <div className="contact-us-item">
                    <div className="contact-us-item-title">公众号二维码：</div>
                    <div className="contact-us-item-img">
                        <LyzImage width="165px" height="165px" url={data?.fileList && data?.fileList.length > 0 ? data?.fileList[0].fileUrl : ''}></LyzImage>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactUs