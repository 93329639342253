import { useEffect } from 'react';
import memberbg from '@/assets/home/memberbg.jpg'
import styles from '../css/MemberCard.module.css'
import hotIcon from '@/assets/home/hotIcon.png'
import creditIcon from '@/assets/home/creditIcon.png'
import qualityIcon from '@/assets/home/qualityIcon.png'
import tradeIcon from '@/assets/home/tradeIcon.png'
import trademarkIcon from '@/assets/home/trademarkIcon.png'
import lawIcon from '@/assets/home/lawIcon.png'
import enterpriseIcon from '@/assets/home/enterpriseIcon.png'
import industryIcon from '@/assets/home/industryIcon.png'
import activityIcon from '@/assets/home/activityIcon.png'
import { useNavigate } from 'react-router-dom';
const cardList = [{
	icon: hotIcon,
	title: '行业资讯·乔见',
	toPath:'/industrialService',
	active: 3
}, {
	icon: creditIcon,
	title: '信用等级评价',
	toPath:'/MembershiMpService',
	active: 6
}, {
	icon: qualityIcon,
	title: '第三方监测·闲侠',
	toPath:'/MembershiMpService',
	active: 7
},{
	icon: tradeIcon,
	title: '从业资质',
	toPath:'/MembershiMpService',
	active: 3
},{
	icon: trademarkIcon,
	title: '证明商标',
	toPath:'/MembershiMpService',
	active: 1
},{
	icon: lawIcon,
	title: '法律服务',
	toPath:'/MembershiMpService',
	active: 2
},{
	icon: enterpriseIcon,
	title: '企业发展',
	toPath:'/MembershiMpService',
	active: 4
},{
	icon: industryIcon,
	title: '行业竞赛',
	toPath:'/MembershiMpService',
	active: 5
},{
	icon: activityIcon,
	title: '专项活动',
	toPath:'/MembershiMpService',
	active: 0
}]

const MemberCardItem = (props: { cardData?: any }) => {
	let navigate = useNavigate();
	const goDetails = (e:any) => {
		if(e.toPath){
			navigate(e.toPath, {state: { active: e.active} })
			window.scrollTo(0, 0);
		} 
	}
	return (
		<>
			<div className={`${styles.item} pos_r color_fff pointer`} style={{
				display: 'inline-block',
				width: '403px',
				height: '184px',
				background: 'rgba(255, 255, 255, 0.9)',
				margin: '0 25px 25px 0'
			}}
			onClick={()=>{goDetails(props.cardData)}}>
				<div className={`transition_all font_22 color_333 ${styles.titleBox}`}>{props.cardData.title}</div>
				<img src={props.cardData.icon} className={`${styles.iconBox} transition_all`} alt="" />
			</div>
		</>
	)
}
const MemberCard = () => {

	useEffect(() => {
	}, [])
	return (
		<>
			<div style={{
				height: '681px',
				backgroundImage: `url(${memberbg})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: '100% 681px',
				backgroundPosition: 'center'
			}}>
				<div className='page_body flexr-jfe flex-aic' style={{
					height: '100%',
					paddingTop: '10px'
				}}>
					<div>
						{
							cardList.map((value: any, index: number) => {
								return <MemberCardItem key={index} cardData={value}></MemberCardItem>
							})
						}
					</div>
				</div>
			</div>
		</>);
}
export default MemberCard