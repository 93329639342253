import { forwardRef} from 'react';
import './Tags.css'
interface Tagsprops {
	color?: string;
	children?: string;
	className?: string;
}
const Tags = forwardRef((props: Tagsprops, ref: any) => {
	return (
		<>
            <span style={{background: props.color ? props.color : '', color: props.color ? '#fff' : '', border: props.color ? props.color : ''}} className={`tag-body  ${props.className}`}>
				{props.children || '默认文字'}
			</span>
		</>
    );
})
export default Tags