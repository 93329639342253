/**
 *@description: 党的建设
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-29 15:51:37
 *@email: 1982338226@qq.com
*/
import imageListData from "@/assets/image";
import HeaderTabs from "@/components/HeaderTabs/HeaderTabs";
import { locatioData } from "@/typeScripeMode";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import AssoNews from "./modules/assoNews";
import HotConsulting from "./modules/hotConsulting";
import NewRules from "./modules/newRules";
import Notice from "./modules/notice";
import SeriousWarning from "./modules/seriousWarning";
function getQueryVariable(variable: any)
{
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return(undefined);
}
const PartyBuilding: FC = () => {
    const location = useLocation();
    const {search} = location as locatioData
	const arr = search.split('&')
	const id = getQueryVariable('id')
	const itemActive = arr && arr.length > 1 ? arr[1].substr(7) : undefined
    const columns = [{
		title: '热点资讯',
		icon: imageListData.rdzx,
		element:  <div className='pad-tb-20'>
            <HotConsulting id={id}/>
        </div>
	},{
		title: '产业新规',
		icon: imageListData.cyxg,
		element:  <div className='pad-tb-20'>
            <NewRules id={id}/>
        </div>
	},{
		title: '要案警示',
		icon: imageListData.yajs,
		element:  <div className='pad-tb-20'>
            <SeriousWarning id={id}/>
        </div>
	},{
		title: '协会新闻',
		icon: imageListData.xhxw,
		element:  <div className='pad-tb-20'>
            <AssoNews id={id}/>
        </div>
	},{
		title: '通知公告',
		icon: imageListData.tzgg,
		element:  <div className='pad-tb-20'>
            <Notice id={id}/>
        </div>
	}]
	const breadcrumbList = [itemActive ? columns[Number(itemActive)].title : '热点资讯']
	return (
		<>
            <HeaderTabs columns={columns} breadcrumb={breadcrumbList} bgSrc={imageListData.dangdejianshe} active={itemActive ? itemActive : undefined}></HeaderTabs>
		</>
    );
}
export default PartyBuilding