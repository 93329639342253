/**
 *@description: 发布需求
 *@author: 刘宇凡
 *@fileName: postDemand.tsx 
 *@date: 2022-10-09 10:41:32
 *@email: 1982338226@qq.com
*/
import { getNeedInfo } from "@/api/industrialService";
import { saveNeed } from "@/api/myInfo";
import UploadImage from "@/components/Upload/uploadImage";
import { handleFile, handleFileList } from "@/utils/upload";
import { DatePicker, Form, Input, message, Row, Select } from "antd"
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { FC, useImperativeHandle, useState } from "react"

const PostDemand: FC<any> = (props) => {
    const [form] = Form.useForm();
    const { Option } = Select;
    const [detailsData, setDetailsData] = useState<any>()
    useImperativeHandle(props.onRef, () => {
        return {
            func: getDetails,
        };
    });
    const disposeTime = (data: moment.MomentInput, type: string | undefined) => {
        return moment(data).format(type)
    }
    const getDetails = async (e: any) => {
        const res = await getNeedInfo({ id: e.id })
        const data = {
            thumbnailList: handleFileList(res.data.data.thumbnailList),
            needType: res.data.data.needType,
            releaseUnit: res.data.data.releaseUnit,
            contactPhone: res.data.data.contactPhone,
            title: res.data.data.title,
            keywords: res.data.data.keywords,
            periodOfValidity: moment(res.data.data.periodOfValidity, 'YYYY-MM-DD'),
            releaseType: res.data.data.releaseType,
            content: res.data.data.content
        }
        setDetailsData(res.data.data)
        form.setFieldsValue(data);
    }
    const onFinish = () => {
        form.validateFields().then(async validate => {
            const data = {
                thumbnailList: handleFile(validate.thumbnailList),
                needType: validate.needType,
                releaseUnit: validate.releaseUnit,
                contactPhone: validate.contactPhone,
                title: validate.title,
                keywords: validate.keywords,
                periodOfValidity: disposeTime(form.getFieldValue('periodOfValidity'), 'YYYY-MM-DD'),
                releaseType: validate.releaseType,
                content: validate.content,
                id: detailsData && detailsData?.id ? detailsData.id : undefined,
                updateVersion: detailsData && detailsData?.updateVersion ? detailsData.updateVersion : undefined,
            }
            const res = await saveNeed(data)
            if (res.data.code === 200) {
                message.success('提交成功')
                form.resetFields()
                if(detailsData?.id){
                    props.setActive(1)
                }
            }
        })
    }
    return <>
        <div className="account-information-title">发布需求</div>
        <Form form={form} size='large' style={{ marginTop: '20px' }} onFinish={onFinish}>
            <Form.Item label='资源类型' name="needType" rules={[{ required: true, message: '请选择资源类型!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Select>
                    <Option value="户外">户外</Option>
                    <Option value="电视">电视</Option>
                    <Option value="广播">广播</Option>
                    <Option value="报纸">报纸</Option>
                    <Option value="杂志">杂志</Option>
                    <Option value="LED">LED</Option>
                    <Option value="新媒体">新媒体</Option>
                    <Option value="网络广告">网络广告</Option>
                    <Option value="自媒体">自媒体</Option>
                    <Option value="策划">策划</Option>
                    <Option value="制作">制作</Option>
                    <Option value="设备">设备</Option>
                    <Option value="材料">材料</Option>
                </Select>
            </Form.Item>
            <Form.Item label='发布单位' name="releaseUnit" rules={[{ required: true, message: '请输入发布单位!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='发布类型' name="releaseType" rules={[{ required: true, message: '请选择发布类型!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Select>
                    <Option value="招标">招标</Option>
                </Select>
            </Form.Item>
            <Form.Item label='联系电话' name="contactPhone" rules={[{ required: true, message: '请输入联系电话!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='主题' name="title" rules={[{ required: true, message: '请输入主题!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='关键词' name="keywords" labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <Input />
            </Form.Item>
            <Form.Item label='有效期' name="periodOfValidity" rules={[{ required: true, message: '请选择有效期!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} colon={false}>
                <DatePicker />
            </Form.Item>
            <Form.Item label='需求描述' name="content" labelCol={{ span: 3 }} wrapperCol={{ span: 20 }} colon={false}>
                <TextArea />
            </Form.Item>
            <Form.Item label='封面图片' name="thumbnailList" rules={[{ required: true, message: '请上传封面图片!' }]} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }} colon={false}>
                <UploadImage></UploadImage>
            </Form.Item>
            <Row justify="center">
                <Form.Item>
                    <div className="Join-Us-submit-botton" style={{ background: '#3370FF', color: '#fff' }} onClick={onFinish}>提交</div>
                </Form.Item>
            </Row>
        </Form>
    </>
}
export default PostDemand