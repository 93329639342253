/**
 *@description: 产业新规
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { FC, useEffect, useState } from 'react';
import { NewspropsData } from '@/typeScripeMode';
import { getNewsInfo } from '@/api/associationOfHome';

const NewRules: FC<NewspropsData> = (props) => {
    const [data,setData] = useState({
        newsContent: ''
    })
    const getData = () => {
        const data = {
            parentId: '11',
            newsCategory:'13'
        }
        getNewsInfo(data).then(res=>{
            setData(res.data.data)
        })
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        <>
            {/* <div className='association-charter-title'>协会简介</div> */}
            <div className='association-charter-content' dangerouslySetInnerHTML={{
					__html:data['newsContent']
				}}></div>
        </>
    )
}
export default NewRules