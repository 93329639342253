
import request from "@/utils/hasRequest";

//修改信息
export function updateInfo(params:any) {
    return request({
        url: `/homePageAuth/updateInfo`,
        method: 'post',
        data:params
    })
}

// 修改密码
export function updateUserLoginPassword(params:any) {
    return request({
        url: `/homePageAuth/updateUserLoginPassword`,
        method: 'post',
        data:params
    })
}

// 修改密码
export function updateUserPhone(params:any) {
    return request({
        url: `/homePageAuth/updateUserPhone`,
        method: 'post',
        data:params
    })
}

// 保存或更新资源
export function saveResources(params:any) {
    return request({
        url: `/homePageAuth/saveResources`,
        method: 'post',
        data:params
    })
}

// 保存或更新需求
export function saveNeed(params:any) {
    return request({
        url: `/homePageAuth/saveNeed`,
        method: 'post',
        data:params
    })
}

// 保存或更新招聘
export function saveRecruit(params:any) {
    return request({
        url: `/homePageAuth/saveRecruit`,
        method: 'post',
        data:params
    })
}

// 保存或更新人才
export function saveApplyJob(params:any) {
    return request({
        url: `/homePageAuth/saveApplyJob`,
        method: 'post',
        data:params
    })
}


// 资源分页查询
export function resourcesInfoPageList(params:any) {
    return request({
        url: `/homePageAuth/resourcesInfoPageList`,
        method: 'get',
        params
    })
}

// 需求分页查询
export function needInfoPageList(params:any) {
    return request({
        url: `/homePageAuth/needInfoPageList`,
        method: 'get',
        params
    })
}

// 招聘分页查询
export function recruitInfoPageList(params:any) {
    return request({
        url: `/homePageAuth/recruitInfoPageList`,
        method: 'get',
        params
    })
}

// 人才分页查询
export function applyJobInfoPageList(params:any) {
    return request({
        url: `/homePageAuth/applyJobInfoPageList`,
        method: 'get',
        params
    })
}


// 通用删除数据
export function deleteData(params:any) {
    return request({
        url: `/homePageAuth/deleteData`,
        method: 'post',
        data:params
    })
}

// 通用切换发布状态
export function switchReleaseStatus(params:any) {
    return request({
        url: `/homePageAuth/switchReleaseStatus`,
        method: 'post',
        data:params
    })
}


