import { useEffect, useState } from 'react';
import LyzImage from '@/components/LyzImage/LyzImage';
import homeIcon from '@/assets/icon/homeicon.png'
import rightIcon from '@/assets/icon/rightBtn.png'
import { useNavigate } from 'react-router-dom';

import './headerTabs.css'
function getQueryVariable(variable: any)
{
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return(false);
}
const HeaderTabs = (props: { bgSrc: string, breadcrumb: Array<string>, active?: string; columns: Array<{ title: string, element: any, icon: any; }> }) => {
	const [activeIndex, setActiveIndex] = useState(0)
	let navigate = useNavigate();
	useEffect(() => {
		setActiveIndex(Number(props.active) || 0)
	}, [props.active])
	useEffect(() => {
	const num =	Number(getQueryVariable('active'))
		if(num) {
			setActiveIndex(num)
			if (props.breadcrumb.length >= 1) {
				props.breadcrumb[props.breadcrumb.length - 1] = props.columns[num].title
			}
		}
	}, [])
	const putTab = (value: any, index: number) => {
		if(getQueryVariable('id')) {
			window.location.href = window.location.origin + `/News?active=${index}`
		}
		setActiveIndex(index)
		if (props.breadcrumb.length >= 1) {
			props.breadcrumb[props.breadcrumb.length - 1] = value.title
		}
	}
	return (
		<>
			<div style={{
				marginBottom: '-71px'
			}}>
				<LyzImage height='438px' url={props.bgSrc}></LyzImage>
			</div>
			<div className='page_body pos_r bgc_fff boxs_bb' style={{
				// height: '230px',
				zIndex: '1',
				overflow: 'hidden',
				paddingBottom: '20px',
				boxShadow: '0px 1px 5px 0px rgba(177,177,177,0.5)'
			}}>
				<div className='flex'>
					{
						props.columns.map((value, index) => {
							return <div key={index} className='header-tab-top-body'>
								<div className='header-tab-item-body'>
									<div className='header-tab-item-number'>0{index + 1}</div>
									<div onClick={() => putTab(value, index)} className='header-tab-item-title' style={{color: index === activeIndex ? '#3370FF' : ''}}>
										<img style={{
											width: '26px',
											height: '26px',
											marginRight: '5px',
											backgroundColor: index === activeIndex ? '#3370FF' : '#333333'
										}} src={value.icon} alt=''></img>
										<div className='text-warp font_18' style={{ lineHeight: '26px' }}>{value.title}</div>
									</div>
								</div>
								{
									props.columns.length - 1 === index ? <></> : <i className='lineBox'></i>
								}
							</div>
						})
					}
				</div>
				<div className='flex flex-aic' style={{
					marginTop: '10px',
					paddingLeft: '25px'
				}}>
					<LyzImage url={homeIcon} width="20px" height='20px'></LyzImage>
					<span className='color_333 font_18' style={{
						marginLeft: '7px'
					}}>首页</span>
					{props.breadcrumb.map((value, index) => {
						return <span key={index} className={`flex flex-aic ${index === props.breadcrumb.length - 1 ? 'color_3370ff' : 'color_333'}`} style={{
							marginLeft: '11px'
						}}><LyzImage fit='contain' height='15px' width='9px' url={rightIcon}></LyzImage><span className='font_18' style={{
							marginLeft: '11px'
						}}>{value}</span></span>
					})}
				</div>
			</div>
			<div className='page_body'>
				{
					props.columns.map((value, index) => {
						if (activeIndex === index) {
							return <div key={index} className={index === activeIndex ? 'active' : ''}>
								{
									value.element
								}
							</div>
						} else {
							return null
						}
					})
				}
			</div>
		</>);
}
export default HeaderTabs