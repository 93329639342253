/**
 *@description: 产业智库
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-09-22 19:32:28
 *@email: 1982338226@qq.com
*/

import { getNewsDetails, getNewsList } from "@/api/news"
import Tab from "@/components/Tab"
import { FC } from "react"

const IndustryThink: FC = () => {
    const IndustryThinkTypeList = [
        {
            title: '研究院介绍',
            type: '28',
        }, {
            title: '课题/成功',
            type: '29'
        }, {
            title: '研究员介绍',
            type: '30'
        }
    ]
    const queryData={
        current: '1',
        pageSize: '10',
        parentId:'27',
        status:'1',
        newsCategory: '28'
    }
    const getList = async (e:any) => {
        const requestData = {...queryData,...e}
        const res = await getNewsList(requestData)
        return {
            list: res.data.data.list,
            total: res.data.data.total || 0
        }
    }
    const getDetails = async (e:any) => {
        const res = await getNewsDetails(e)
        return res.data.data
    }
    return (
        <>  
            <Tab request={getList} 
            requestDetails={getDetails} 
            haederProps={{typeList:IndustryThinkTypeList,}}
            queryProps={{queryData:queryData,queryTageName:'newsCategory',active:'28'}} 
            detailsContentProps={{showDatya:{title:'newsTitle',content:'newsContent',time:'updateTime'},toPath:'/industrialService'}} 
            contentProps={{calssName:'grid-4',isShowContent:true,isShowImage: true,showDatya:{cardTitle:'newsTitle',cardContent:'newsRemark', cardId:'newsId',cardFileList:'fileList'},cardType: '10'}}></Tab>
        </>
    );
}
export default IndustryThink