import { QueryNewsData } from "@/typeScripeMode";
import request from "@/utils/request";

// 新闻列表
export function getNewsList(data:QueryNewsData) {
    return request({
        url: '/website/newsList',
        method: 'get',
        params:data
    })
}

// 新闻详情
export function getNewsDetails(id:string) {
    return request({
        url: `/website/getNewsDetails/${id}`,
        method: 'get'
    })
}