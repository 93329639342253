/**
 *@description: 专项活动
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { FC } from 'react';
import Tab from '@/components/Tab';
import { getSpecialActivityInfo, getSpecialActivityPageList } from '@/api/membershipService';

const SpecialCampaign: FC = () => {
    const specialActivitiesTagsList = [
        {
            type: '0',
            typeName:'未开始',
            typeColor:'#FA6400'
        },
        {
            type: '1',
            typeName:'进行中',
            typeColor:'#E02020'
        },
        {
            type: '2',
            typeName:'已结束',
            typeColor:'#666666'
        }
    ]
    const queryData={
        current: '1',
        pageSize: '10',
    }
    const getList = async (e:any) => {
        const requestData = {...queryData,...e}
        const res = await getSpecialActivityPageList(requestData)
        return {
            list: res.data.data.list,
            total: res.data.data.total || 0
        }
    }
    const getDetails = async (e:any) => {
        const data = {
            id:e
        }
        const res = await getSpecialActivityInfo(data)
        return res.data.data
    }
    return (
        <>  
        <Tab request={getList} 
            requestDetails={getDetails} 
            queryProps={{queryData:queryData}} 
            detailsContentProps={{showDatya:{title:'activityName',content:'content',time:'activityStartTime'},toPath:'/MembershiMpService'}} 
            contentProps={{
                isTags:true,
                cardType:'5',
                tagsTypeList:specialActivitiesTagsList,
                showDatya:{
                    cardTitle:'activityName', 
                    cardStartTime:'activityStartTime', 
                    cardEndTime: 'activityEndTime',
                    cardId:'id',
                    cardFileList:'coverList',
                    cardType:'activityStatus'
                },
                calssName:'grid-4'
            }}
            footerProps={{pageSize:'8'}}></Tab>
        </>
    );
}
export default SpecialCampaign