import { ReactElement, useEffect } from 'react';
import LyzImage from '../LyzImage/LyzImage';
interface IProps {
	btnText:string,
	src:string,
	title: string,
	content: string,
	time: string,
	data:any,
	onClick?: any
}
const PageItem = (props: IProps): ReactElement => {
	useEffect(() => {
	}, [])
	const tapCard = (): void => {
		props.onClick && props.onClick(props.data)
	}
	return (
		<>
			<div onClick={tapCard} className='flex flex-aic pointer' style={{
				paddingTop: '70px',
			}}>
				<LyzImage width='305px' height='222px' url={props.src}></LyzImage>
				<div className='flex-fitem flexc-jsb' style={{
					marginLeft: '58px',
					height: '222px',
					marginRight: '85px'
				}}>
					<div className='font_21 font_bold color_333 text_nowrap' style={{
						marginBottom: '13px',
						maxWidth: '560px'
					}}>{props.title}</div>
					<div className='flex-fitem'>
						<article className='font_16 color_666 text_nowrap_5'>{props.content}</article>
					</div>
					<span className='font_16 color_999'>发布时间：{props.time}</span>
				</div>
				<i style={{
					height: '69px',
					borderRight: '1px solid #979797'
				}}></i>
				<div className='tar font_24 font_bold color_3c7fff' style={{
					marginLeft: '84px',
					minWidth: '160px'
				}}>{props.btnText}</div>
			</div>
		</>);
}
export default PageItem