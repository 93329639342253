import { forwardRef} from 'react';

import  "./List.css";
interface Listprops {
    children?: any;
    cardType?: string
    style?: any;
    className?: any;
}
const List = forwardRef((props: Listprops, ref: any) => {
    return (
        <>  
            <div style={props.style} className={`${(props.cardType === '3' || props.cardType === '6' || props.cardType === '7') ?  'list-body1' : props.cardType === '4' ? 'list-body2' : 'list-body'} ${props.className}`}>
                {props.children}
            </div>
        </>
    );
})
export default List