/**
 *@description: 证书查询
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { getRaceCertificateInfo } from '@/api/membershipService';
import LyzButton from '@/components/LyzButton/LyzButton';
import LyzImage from '@/components/LyzImage/LyzImage';
import { Button, Form, Input, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import './Inquiry.css';
const Inquiry: FC = () => {
    const [form] = Form.useForm();
    const { Option } = Select;
    const [certificateType, setCertificateType] = useState<string | undefined>('1');  
    const [show, setShow] = useState(false)
    const [imageUrl, setImageUrl] = useState()
    const onFinish = async (values: any) => {
        form.validateFields().then(async validate => {
            getRaceCertificateInfo(validate).then(res => {
                setShow(true)
                setImageUrl(res.data.data)
            })
        })
    }
    const resetForm = () => {
        form.resetFields()
        setCertificateType('');
    }  
    return (
        <>
            {
                !show ? <div className='Inquiry-body'>
                    <div className='inquiry-title'>成绩/证书查询</div>
                    <div className='inquiry-content'>
                        <Form form={form} size='large' onFinish={onFinish} labelCol={{ span: 8 }}>
                            <Form.Item label='姓名/企业名称' name="psnName" rules={[{ required: true, message: '请输入您的姓名/企业名称' }]} >
                                <Input placeholder="请输入您的姓名/企业名称" />
                            </Form.Item>
                            <Form.Item label="证书类型" name="certificateType" rules={[{ required: true, message: '请选择证书类型' }]}>
                                <Select onChange={(val) => {
                                    console.log(val)
                                    setCertificateType(val);  
                                }} placeholder="请选择证书类型" allowClear>
                                    <Option value="1">广告审查员</Option>
                                    <Option value="2">信用等级</Option>
                                </Select>
                            </Form.Item>
                            {/* <Form.Item label='身份证号' name="certificateCode" rules={[{ required: true, message: '请输入您的身份证号' }]}>
                                <Input placeholder="请输入您的身份证号" />
                            </Form.Item> */}
                            {
                                certificateType === '2' ? <Form.Item label='证书编码' name="certificateCode" rules={[{ required:true, message: '请输入您的证书编码' }]}>
                                <Input placeholder="请输入您的证书编码" />
                            </Form.Item> : ''
                            }
                            
                            <div className='inquiry-button'>
                                <div></div>
                                <div>
                                    <Button type="primary" style={{ marginRight: '30px' }} onClick={() => { resetForm() }}>重填</Button>
                                    <Button type="primary" onClick={onFinish}>查询</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div> : <div style={{ marginTop: '20px' }}>
                    <LyzImage url={imageUrl ? imageUrl : ''}></LyzImage>
                    <div style={{ padding: '40px 0' }} className={`flexr-jsb flex-aic`}>
                        <span></span>
                        <LyzButton onClick={() => { setShow(false) }} borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
                        <span></span>
                    </div>
                </div>
            }
        </>
    );
}
export default Inquiry