import imageListData from '@/assets/image';
import { useEffect, ReactElement } from 'react';
import LyzButton from '../LyzButton/LyzButton';
import LyzImage from '../LyzImage/LyzImage';
import './RecruitmentHandsInfo.css'
const RecruitmentHandsInfo = (props: {
	onBack?: any; 
	data?: any;
	showDatya?: {
		content?: string;
		title?: string;
		id?:string;
		startTime?: string; 
        endTime?: string;
		time?: string;
		fileList?:any;
        minDegree?: string;
        positionType?: string;
        workYearLimit?: string;
        releaseUnit?:string;
        workWelfare?: string;
        salaryRange?: string;
        detailedAddress?: string;
	}}): ReactElement => {
	useEffect(() => {
	}, [])
	const tapBack = () => {
		props.onBack && props.onBack()
	}
	return (
		<>
			<div style={{paddingTop: '40px'}}>
                <div className='recruitment-hands-info-top-body'>
                    <div className='info-top-top-body'>
                        <div className='info-top-top-title'>{props?.showDatya?.positionType ? props.data[props?.showDatya?.positionType] : undefined}</div>
                        <div className='info-top-top-pay'>{props?.showDatya?.salaryRange ? props.data[props?.showDatya?.salaryRange] : undefined}</div>
                    </div>
                    <div className='info-top-botton'>
                        <div className='info-top-botton-left'>
                            {props?.showDatya?.detailedAddress ? props.data[props?.showDatya?.detailedAddress] : undefined}
                            ·{props?.showDatya?.workYearLimit ? props.data[props?.showDatya?.workYearLimit] : undefined}
                            ·{props?.showDatya?.minDegree ? props.data[props?.showDatya?.minDegree] : undefined}
                        </div>
                        <div className='info-top-botton-right'>
                            {
                                props.showDatya?.workWelfare ? 
                                props.data[props?.showDatya?.workWelfare].split(',').length>0 && props.data[props?.showDatya?.workWelfare].split(',').map((item: any )=>{
                                        return  <div className='info-top-tag'>{item}</div>
                                    })
                                : undefined
                            }
                        </div>
                    </div>
                </div>
                <div className='hiring-body'>
                    <img src={props?.showDatya?.fileList ? props.data[props?.showDatya?.fileList][0].fileUrl : imageListData.companyLogin} alt="" />
                    <div>招聘人·{props?.showDatya?.releaseUnit ? props.data[props?.showDatya?.releaseUnit] : null}</div>
                </div>
                <div className='recruitment-hands-info-content-body'>
                    <div className='recruitment-hands-info-content-left-body'>
                        <div className='info-content-left-title'>职位描述</div>
                        <article style={{
                            whiteSpace: 'pre-wrap',
                            marginTop: '30px',
                            fontSize:'16px',
                            overflowY:'auto'
                        }} dangerouslySetInnerHTML={{
                            __html:props.showDatya?.content ? props.data[props.showDatya.content] : undefined
                        }}></article>
                    </div>
                    <div className='recruitment-hands-info-content-right-body'>
                        <div className='info-content-right-title'>公司基本信息</div>
                        <div className='company-info-body'>
                            <img src={props?.showDatya?.fileList ? props.data[props?.showDatya?.fileList][0].fileUrl : imageListData.companyLogin} alt="" />
                            <div>{props?.showDatya?.releaseUnit ? props.data[props?.showDatya?.releaseUnit] : null}</div>
                        </div>
                        <div className='info-content-right-title'>其他职位</div>
                        <div style={{marginTop:'20px'}}>
                            <LyzImage width={'100%'} url={imageListData.zpxx1}></LyzImage>
                        </div>
                    </div>
                </div>
                <div style={{padding: '70px 0'}} className='flexr-jsb flex-aic'>
					<span></span>
					<LyzButton onClick={tapBack} borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
					<span></span>
				</div>
			</div>
		</>);
}
export default RecruitmentHandsInfo