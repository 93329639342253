import { useNavigate, useRoutes } from "react-router-dom"
import Login from '@/pages/login/index'
import Layout from '@/layout/index'
import Home from '@/pages/home/index'
import HotPage from '@/pages/hotPage/index'
import { useEffect } from "react"
import MembershiMpService from "@/pages/membershipService"
import News from "@/pages/news"
import PartyBuilding from "@/pages/partyBuilding"
import AssociationOfHome from "@/pages/associationOfHome"
import IndustrialService from "@/pages/industrialService"
import JoinUs from "@/pages/joinUs"
import ContactUs from "@/pages/contactUs"
import MyInfo from "@/pages/myInfo"
const Router = () => {
	const Redirect = (props: any) => {
		let navigate = useNavigate();
		useEffect(() => {
			navigate(props.to);
      window.scrollTo(0, 0);
		});
		return <></>;
	}
  return useRoutes([
    {
      path: '/',
      element: <Redirect to="/index" />
    },
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/index',
          element: <Home />
        },{
          path: '/hotPage',
          element: <HotPage />
        },
        {
          path: '/MembershiMpService',
          element: <MembershiMpService />
        },
        {
          path: '/news',
          element: <News />
        },
        {
          path: '/partyBuilding',
          element: <PartyBuilding />
        },
        {
          path:'/associationOfHome',
          element:<AssociationOfHome />
        },
        {
          path:'/industrialService',
          element:<IndustrialService />
        },
        {
          path:'/joinUs',
          element:<JoinUs />
        },
        {
          path:'/contactUs',
          element:<ContactUs />
        },
        {
          path:'/myInfo',
          element:<MyInfo />
        },
      ]
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '*',
      element: <Login />
    },
  ])
}
export default Router;
