import { useEffect, useState } from 'react';
import LyzButton from '../LyzButton/LyzButton';
const LyzPagination = (props: { total: number, pageSize: number,onChange?: any }) => {
	const [inputValue, setInputValue] = useState(1)
	const [inputTemp, setInputTemp] = useState(1)
	useEffect(() => {
	})
	const inputChange = (event?: any) => {
		if (event.target.value > Math.ceil(props.total / props.pageSize)) {
			setInputTemp(Math.ceil(props.total / props.pageSize))
		} else if (event.target.value <= 0) {
			setInputTemp(1)
		} else {
			setInputTemp(Number(event.target.value))
		}
	}
	const tapBtn = (value: number) => {
		setInputValue(value)
		props.onChange && props.onChange({pageSize:props.pageSize,current:value})
	}
	const ForPage = () => {
		let pageTemp = []
		if (inputValue < 4) {
			for (let index = 0; index < Math.ceil(props.total / props.pageSize); index++) {
				if (index + 1 <= 7) {
					pageTemp.push(<LyzButton onClick={() => { tapBtn(index + 1) }} key={index} color={index + 1 === inputValue ? '#3C7FFF' : '#999999'} borderColor={index + 1 === inputValue ? '#3C7FFF' : '#DDDDDD'} width='39px' text={index + 1}></LyzButton>)
				}
			}
		} else if (Math.ceil(props.total / props.pageSize) - 4 <= inputValue) {
			for (let index = 0; index < Math.ceil(props.total / props.pageSize); index++) {
				if (index + 1 >= Math.ceil(props.total / props.pageSize) - 6) {
					pageTemp.push(<LyzButton onClick={() => { tapBtn(index + 1) }} key={index} color={index + 1 === inputValue ? '#3C7FFF' : '#999999'} borderColor={index + 1 === inputValue ? '#3C7FFF' : '#DDDDDD'} width='39px' text={index + 1}></LyzButton>)
				}
			}
		} else {
			for (let index = 0; index < Math.ceil(props.total / props.pageSize); index++) {
				if (index + 1 >= inputValue - 3 && index + 1 <= inputValue + 3) {
					pageTemp.push(<LyzButton onClick={() => { tapBtn(index + 1) }} key={index} color={index + 1 === inputValue ? '#3C7FFF' : '#999999'} borderColor={index + 1 === inputValue ? '#3C7FFF' : '#DDDDDD'} width='39px' text={index + 1}></LyzButton>)
				}
			}
		}
		return <>{pageTemp}</>
	}
	return (
		<>
			<div className='flexr-jfe flex-aic color_999'>
				<LyzButton disabled={inputValue <= 1} onClick={() => { tapBtn(inputValue - 1) }} borderColor="#DDDDDD" text="上一页"></LyzButton>
				<ForPage></ForPage>
				<LyzButton disabled={inputValue >= Math.ceil(props.total / props.pageSize)} onClick={() => { tapBtn(inputValue + 1) }} borderColor="#DDDDDD" text="下一页"></LyzButton>
				<span style={{
					margin: '0 25px 0 16px'
				}}>共{Math.ceil(props.total / props.pageSize)}页</span>
				<span>到第</span>
				<input onChange={inputChange} defaultValue={inputValue} className='tac color_3c7fff font_18' style={{
					width: '39px',
					height: '39px',
					border: '1px solid #3370FF',
					marginLeft: '9px'
				}} type="number" />
				<span style={{
					margin: '0 9px'
				}}>页</span>
				<LyzButton onClick={() => tapBtn(inputTemp)} borderColor="#3370FF" color="#3370FF" text="确定"></LyzButton>
			</div>
		</>);
}
export default LyzPagination