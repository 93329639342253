/**
 *@description: 找回密码
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { getSendVerCode, resetUserLoginPassword } from '@/api/login';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
interface RegisterProps {
    setLoginType: any;
}
let timer: string | number | NodeJS.Timeout | undefined
const RetrievePassword: FC<RegisterProps> = (props) => {
    const [form] = Form.useForm();
	const [userPhone,setUserPhone] = useState('')
	const [time,setTime]=useState(60)//倒计时时间
	const [buttonIsDisabled,setButtonIsDisabled] = useState(true)
	const [btnContent, setBtnContent] = useState('获取验证码')
    const [isLoading,setIsLoading] = useState(false)
    const getPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(timer) return 
		const str = /^1[3|4|5|6|7|8|9]\d{9}$/
		setButtonIsDisabled(true)
		if(str.test(e.target.value)){
			setUserPhone(e.target.value)
			setButtonIsDisabled(false)
		}
	}
	const getVerCode = async () =>{
		const data = {
			userPhone: userPhone,
			serviceCodeEnum: 'USER_UPDATE_PASSWORD'
		}
		const res = await getSendVerCode(data)
        if(res.data.code === 200){
            message.success('发送成功');
            timer = setInterval(() => setTime(pre =>pre-1), 1000)
            setButtonIsDisabled(true)
        }else{
            message.error(res.data.msg);
        }
	}
    const onFinish = async (values: any) => {
        form.validateFields().then(async validate => {
            setIsLoading(true)
            const data = validate   
            const res = await resetUserLoginPassword(data)
            setIsLoading(false)
            if(res.data.code === 200){
                message.success('重置成功');
            }else{
                message.error(res.data.msg);
            }
        })
	};
    useEffect(() => {
        clearInterval(timer)
        return () => clearInterval(timer)
    }, [])
    useEffect(() => {
		if (time > 0 && time < 60) {
			setBtnContent(`${time}s后重发`)
		} else {
            clearInterval(timer)
			setTime(60)
            setButtonIsDisabled(false)
            setBtnContent('获取验证码')
		}
	}, [time])
    return <>
        <Form form={form} className='login-form' size='large' onFinish={onFinish}>
            <Form.Item name="userPhone" rules={[{ required: true,pattern: new RegExp(/^1[3|4|5|6|7|8|9]\d{9}$/, 'g'), message: '请输入您的手机号' }]}>
                <Input placeholder="请输入您的手机号" onChange={(e) => { getPhone(e) }} />
            </Form.Item>
            <Form.Item>
                <Row gutter={8}>
                    <Col span={14}>
                    <Form.Item name="verCode" rules={[{ required: true, message: '请输入验证码' }]} noStyle>
                        <Input placeholder="请输入验证码" />
                    </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Button onClick={() => { getVerCode() }} block type="primary" disabled={buttonIsDisabled}>{btnContent}</Button>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item name="loginPassword" rules={[{ required: true, message: '新密码（10-15位数字和大小写字母组合）' }]}>
                <Input type="password" placeholder="新密码（10-15位数字和大小写字母组合）" />
            </Form.Item>
            <Form.Item name="newLoginPassword" rules={[{ required: true, message: '再次输入密码' }]}>
                <Input type="password" placeholder="再次输入密码" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" loading={isLoading} block onClick={onFinish}>
                    确认重置
                </Button>
            </Form.Item>
            <Form.Item>
                <Button type="link" onClick={() => { props.setLoginType('1') }}>
                    返回登录
                </Button>
            </Form.Item>
        </Form>
    </>
}
export default RetrievePassword