import { useEffect, ReactElement, useState } from 'react';
import LyzButton from '../LyzButton/LyzButton';
import LyzImage from '../LyzImage/LyzImage';
import './ItemInfo.css'
const ItemInfo = (props: {
	onBack?: any; 
	data?: any;
	showDatya?: {
		content?: string;
		title?: string;
        startTime?: string; 
        lecturer?: string;
		time?: string;
        contact?: string;
        contactPhone?:string;
        fileList?: any;
        courseMaterials?:string;
	}}): ReactElement => {
    const list = [
        {
            title:'课程简介',
            type:'1'
        },{
            title:'资料',
            type:'2'
        },
    ]
    const [active,setActive] = useState('1')
	useEffect(() => {
	}, [])
	const tapBack = () => {
		props.onBack && props.onBack()
	}
	return (
		<>
			<div style={{paddingTop: '40px'}}>
                <LyzImage height='428px' url={props?.showDatya?.fileList ? props.data[props?.showDatya?.fileList][0].fileUrl : ''}></LyzImage>
				<div className='item-info-title'>{props.showDatya?.title ? props.data[props.showDatya.title] : undefined}</div>
                <div className='item-info-content'>
                    <div className='item-info-content-item'>开课时间: {props.showDatya?.startTime ? props.data[props.showDatya.startTime] : undefined}</div>
                    <div className='item-info-content-item'>讲师: {props.showDatya?.lecturer ? props.data[props.showDatya.lecturer] : undefined}</div>
                    <div className='item-info-content-item'>时长: {props.showDatya?.time ? props.data[props.showDatya.time] : undefined}小时</div>
                    <div className='item-info-content-item'>培训联系人: {props.showDatya?.contact ? props.data[props.showDatya.contact] : undefined}</div>
                    <div className='item-info-content-item'>培训联系方式: {props.showDatya?.contactPhone ? props.data[props.showDatya.contactPhone] : undefined}</div>
                </div>
                <div className='item-info-type-body'>
                    {
                        list.map((item)=>{
                            return <div onClick={()=>{setActive(item.type)}} className={`item-info-type ${active === item.type ? 'item-type-active' : ''}`}>
                                {item.title}
                            </div>
                        })
                    }
                </div>
                {
                    active === '1' ? <>
                        <div>{props.showDatya?.content ? props.data[props.showDatya.content] : undefined}</div>
                    </> : <>
                        <div>{props.showDatya?.courseMaterials ? props.data[props.showDatya.courseMaterials] : undefined}</div>
                    </>
                }
				<div style={{padding: '70px 0'}} className='flexr-jsb flex-aic'>
					<span></span>
					<LyzButton onClick={tapBack} borderColor="#3C7FFF" color="#3C7FFF" text="返回"></LyzButton>
					<span></span>
				</div>
			</div>
		</>);
}
export default ItemInfo