/**
 *@description: 法律服务
 *@author: 刘宇凡
 *@fileName: index.tsx 
 *@date: 2022-08-26 17:06:38
 *@email: 1982338226@qq.com
*/
import { FC } from 'react';
import Tab from '@/components/Tab';
import { getNewsDetails, getNewsList } from '@/api/news';

const LegalService: FC = () => {
    const legalServiceTypeList = [
        {
            title:'法律咨询',
            type:'22'
        },{
            title:'维权支持',
            type:'23'
        },{
            title:'法务公关',
            type:'24'
        },{
            title:'法律法规库',
            type:'25'
        }
    ]
    const queryData= {
        current: '1',
        pageSize: '10',
        parentId:'21',
        status:'1',
        newsCategory: '22'
    }
    const getList = async (e:any) => {
        const requestData = {...queryData,...e}
        const res = await getNewsList(requestData)
        return {
            list: res.data.data.list,
            total: res.data.data.total || 0
        }
    }
    const getDetails = async (e:any) => {
        const res = await getNewsDetails(e)
        return res.data.data
    }
    return ( 
        <>  
            <Tab request={getList} 
            requestDetails={getDetails} 
            queryProps={{queryData:queryData,queryTageName:'newsCategory',active:'22'}} 
            haederProps={{typeList:legalServiceTypeList}}
            detailsContentProps={{showDatya:{title:'newsTitle',content:'newsContent',time:'updateTime'},toPath:'/MembershiMpService'}} 
            contentProps={{isShowContent:true,isShowImage: true,showDatya:{cardTitle:'newsTitle', cardTime:'updateTime',cardContent:'newsRemark', cardId:'newsId',cardFileList:'fileList'},cardType:'3'}}></Tab>
        </>
    );
}
export default LegalService