export const handleFileList = (sysFileSaveVoList: [] | undefined) => {
    if (sysFileSaveVoList && sysFileSaveVoList.length > 0) {
        return sysFileSaveVoList.map((item: any) => {
            return {
                uid: item.fileId,
                name: item.originalFileName,
                fileName: item.fileKey,
                status: 'done',
                url: item.fileUrl,
                size: item.fileSize,
            }
        })
    } else {
        return []
    }
}
//表单提交的文件转成后台文件类型
export const handleFile = (fileList: [] | undefined) => {
    const sysFileSaveVoList: object[] = []
    if (fileList && fileList.length > 0) {
        fileList.map((file: any) => {
            sysFileSaveVoList.push({
                fileKey: file.fileName,
                originalFileName: file.name,
                fileSize: file.size,
            })
        })
    }
    return sysFileSaveVoList
}